import { z } from 'zod';

const totalPriceBreakdownSchema = z.object({
  taxAmountCents: z.number().optional(),
  priceCents: z.number(),
});

export const nashQuoteResponseSchema = z.object({
  id: z.string(),
  type: z.string(),
  providerId: z.string(),
  providerName: z.string(),
  providerLogo: z.string().nullable(),
  providerIsInternal: z.boolean(),
  createdTime: z.string(),
  expireTime: z.string(),
  currency: z.string(),
  pickupWindow: z.string().nullable(),
  dropoffEta: z.string().nullable(),
  tags: z.string().nullable(),
  priceCents: z.number(),
  taxAmountCents: z.number().nullable(),
  nashFeeCents: z.number().nullable(),
  tollFeeCents: z.number().nullable(),
  totalPriceBreakdown: totalPriceBreakdownSchema,
  totalPriceCents: z.number().nullable(),
});

export const nashGetQuotesResponseSchema = z.object({
  id: z.string(),
  externalId: z.string().nullable(),
  quotes: z.array(nashQuoteResponseSchema),
  portalUrl: z.string(),
  publicTrackingUrl: z.string(),
});
export const nashGetRefreshQuotesResponseSchema = z.object({
  quotes: z.array(nashQuoteResponseSchema),
});

export const nashRefreshQuotesResponseSchema =
  nashGetRefreshQuotesResponseSchema;

export const nashSelectQuoteResponseSchema = z.object({
  job: z.object({
    id: z.string(),
    createdAt: z.string(),
    portalUrl: z.string(),
    jobConfigurations: z.array(
      z.object({
        publicTrackingUrl: z.string(),
      }),
    ),
  }),
});

export const NashDeliveryModeSchema = z.enum(['scheduled', 'now']);

const itemDimensionsSchema = z.object({
  depth: z.number().nullable(),
  height: z.number().nullable(),
  width: z.number().nullable(),
});

const itemSchema = z.object({
  description: z.string().nullable().optional(),
  count: z.number(),
  dimensions: itemDimensionsSchema,
  weight: z.number(),
});

export const nashGetQuotesRequestBodySchema = z.object({
  pickupPhoneNumber: z.string(),
  pickupBusinessName: z.string().nullable(),
  dropoffPhoneNumber: z.string(),
  dropoffBusinessName: z.string().nullable(),
  deliveryMode: NashDeliveryModeSchema,
  externalId: z.string(),
  pickupStartTime: z.string().optional(),
  pickupEndTime: z.string().optional(),
  dropoffStartTime: z.string().optional(),
  dropoffEndTime: z.string(),
  description: z.string().nullable().optional(),
  currency: z.literal('GBP'),
  valueCents: z.string().nullable(),
  requirements: z.array(z.string()),
  referenceId: z.string().nullable().optional(),
  pickupAddressFormattedStreet: z.string(),
  pickupAddressCity: z.string(),
  pickupAddressZip: z.string(),
  pickupAddressCountry: z.string(),
  pickupLat: z.number().optional(),
  pickupLng: z.number().optional(),
  dropoffAddressFormattedStreet: z.string(),
  dropoffAddressCity: z.string(),
  dropoffAddressZip: z.string(),
  dropoffAddressCountry: z.string(),
  dropoffLat: z.number().optional(),
  dropoffLng: z.number().optional(),
  items: z.array(itemSchema),
});

export const NashUpdateOrderRequestBodySchema = z
  .object({
    externalId: z.string().nullable(),
    pickupAddress: z.string().nullable(),
    pickupPlaceId: z.string().nullable(),
    pickupPhoneNumber: z.string(),
    pickupBusinessName: z.string().nullable(),
    pickupFirstName: z.string().nullable(),
    pickupLastName: z.string().nullable(),
    pickupInstructions: z.string().nullable(),
    pickupStoreLocationId: z.string().nullable(),
    pickupExternalStoreLocationId: z.string().nullable(),
    pickupBarcodes: z.array(z.string()).nullable(),
    pickupEmail: z.string().nullable(),
    dropoffAddress: z.string().nullable(),
    dropoffPlaceId: z.string().nullable(),
    dropoffPhoneNumber: z.string().nullable(),
    dropoffBusinessName: z.string().nullable(),
    dropoffFirstName: z.string().nullable(),
    dropoffLastName: z.string().nullable(),
    dropoffInstructions: z.string().nullable(),
    dropoffBarcodes: z.array(z.string()).nullable(),
    dropoffEmail: z.string().nullable(),
    deliveryMode: NashDeliveryModeSchema.nullable(),
    pickupStartTime: z.string().optional(),
    pickupEndTime: z.string().optional(),
    dropoffStartTime: z.string().optional(),
    dropoffEndTime: z.string().optional(),
    description: z.string().nullable(),
    itemsCount: z.number().nullable(),
    currency: z.literal('GBP'),
    valueCents: z.string().nullable(),
    tipAmountCents: z.string().nullable(),
    requirements: z.array(z.string()),
    minimumVehicleSize: z.string().nullable(),
    dispatchStrategyId: z.string().nullable(),
    tags: z.array(z.string()).nullable(),
    referenceId: z.string().nullable(),
    pickupAddressNumber: z.string().nullable(), // The number on a house.
    pickupAddressSecondarynumber: z.string().nullable(), // Secondary number on an address e.g. an apartment building.
    pickupAddressFormattedStreet: z.string().nullable(),
    pickupAddressCity: z.string().nullable(),
    pickupAddressCounty: z.string().nullable(),
    pickupAddressState: z.string().nullable(),
    pickupAddressZip: z.string().nullable(),
    pickupAddressCountry: z.string().nullable(),
    pickupLat: z.number().nullable(),
    pickupLng: z.number().nullable(),
    dropoffAddressNumber: z.string().nullable(), // The number on a house.
    dropoffAddressSecondarynumber: z.string().nullable(), // Secondary number on an address e.g. an apartment building.
    dropoffAddressFormattedStreet: z.string().nullable(),
    dropoffAddressCounty: z.string().nullable(),
    dropoffAddressState: z.string().nullable(),
    dropoffAddressCity: z.string().nullable(),
    dropoffAddressZip: z.string(),
    dropoffAddressCountry: z.string(),
    dropoffLat: z.number().nullable(),
    dropoffLng: z.number().nullable(),
    weight: z.number().nullable(),
    height: z.number().nullable(),
    depth: z.number().nullable(),
    width: z.number().nullable(),
    requestedDeliveryWindow: z
      .object({
        deliveryWindowId: z.string(),
        date: z.string(),
        shifts: z.array(z.string()),
        capacity: z.number().nullable(),
      })
      .nullable(),
    items: z.array(itemSchema),
  })
  .partial();

export const nashRefreshQuotesRequestBodySchema = z.object({
  orderId: z.string(),
});

export const nashSelectQuoteRequestBodySchema = z.object({
  quoteId: z.string(),
});

export const nashErrorDataSchema = z.object({
  error: z.object({
    code: z.string(),
    message: z.string(),
  }),
  response_status: z.string(),
  RequestID: z.string(),
});

export const NashUpdateJobRequestBodySchema = z
  .object({
    pickupPhoneNumber: z.string().nullable(),
    pickupBusinessName: z.string().nullable(),
    pickupFirstName: z.string().nullable(),
    pickupLastName: z.string().nullable(),
    pickupInstructions: z.string().nullable(),
    pickupStoreLocationId: z.string().nullable(),
    pickupBarcodes: z.array(z.string()).nullable(),
    dropoffPhoneNumber: z.string().nullable(),
    dropoffFirstName: z.string().nullable(),
    dropoffLastName: z.string().nullable(),
    dropoffBusinessName: z.string().nullable(),
    dropoffInstructions: z.string().nullable(),
    dropoffBarcodes: z.array(z.string()).nullable(),
    packageDescription: z.string().nullable(),
    itemsCount: z.number().min(1),
    currency: z.string(),
    packageValue: z.number().nullable(),
    tipAmountCents: z.number().nullable(),
    packageRequirements: z.array(z.string()).nullable(),
    jobMetadata: z.record(z.string(), z.string()).nullable(),
    referenceId: z.string().nullable(),

    // can't update these fields during dispatch
    packagePickupStartTime: z.string().nullable(),
    packagePickupEndTime: z.string().nullable(),
    packageDropoffStartTime: z.string().nullable(),
    packageDropoffEndTime: z.string().nullable(),
    pickupAddress: z.string().nullable(),
    dropoffAddress: z.string().nullable(),
  })
  .partial();

export type NashQuote = z.infer<typeof nashQuoteResponseSchema>;

export type NashGetQuotesResponse = z.infer<typeof nashGetQuotesResponseSchema>;

export type NashRefreshQuotesResponse = z.infer<
  typeof nashRefreshQuotesResponseSchema
>;

export type NashDeliveryModeType = z.infer<typeof NashDeliveryModeSchema>;

export type NashExternalQuoteRequestBody = z.infer<
  typeof nashGetQuotesRequestBodySchema
>;

export type NashUpdateOrderRequestBody = z.infer<
  typeof NashUpdateOrderRequestBodySchema
>;

export type NashUpdateJobRequestBody = z.infer<
  typeof NashUpdateJobRequestBodySchema
>;

export type NashRefreshQuotesRequestBody = z.infer<
  typeof nashRefreshQuotesRequestBodySchema
>;

export type NashSelectQuoteRequestBody = z.infer<
  typeof nashSelectQuoteRequestBodySchema
>;

export const NashGetJobTaskSchema = z.object({
  id: z.string(),
  delivery: z.object({
    id: z.string(),
    type: z.string(),
    status: z.string(),
  }),
});

export const NashGetJobResponseSchema = z.object({
  id: z.string(),
  type: z.string(),
  jobConfigurations: z.array(
    z.object({
      tasks: z.array(NashGetJobTaskSchema),
    }),
  ),
});

export type NashGetJobResponse = z.infer<typeof NashGetJobResponseSchema>;
