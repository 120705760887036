import { BsEyeFill } from 'react-icons/bs';
import { Button, HStack, Icon, Text } from '@chakra-ui/react';
import { BsEyeSlashFill } from 'react-icons/bs';

export const ShowCompletedButton: React.FC<{
  handleToggleShowCompleted: () => void;
  showCompleted: boolean;
}> = ({ handleToggleShowCompleted, showCompleted }) => {
  return (
    <Button
      onClick={handleToggleShowCompleted}
      size="sm"
      borderColor={'white'}
      borderWidth={0.5}
    >
      <HStack spacing={2}>
        <Icon as={showCompleted ? BsEyeSlashFill : BsEyeFill} />
        <Text>{showCompleted ? 'Hide Completed' : 'Show Completed'}</Text>
      </HStack>
    </Button>
  );
};
