import {
  DEFAULT_REGION_ID,
  DeliveryVehicleId,
  GigJobCurrency,
  HydratedDelivery,
} from '@tradeaze-packages/schemas';
import {
  useCreateGigJob,
  useGetDeliveryOptions,
} from '@tradeaze/frontend/hooks';
import { handleError, initialStartDate } from '@tradeaze/frontend/utils';
import { useState, useMemo } from 'react';
import { useAssignDeliveryContext } from '../AssignDeliveryContext';
import { vehicleSortOrder } from '../../../order';

type JobBoardHookProps = {
  paymentAmount: number;
  paymentCurrency: GigJobCurrency;
  deliveryToAssign: HydratedDelivery;
};

const date = initialStartDate();

const generateWhatsAppMessage = ({
  delivery,
  paymentAmount,
}: {
  delivery: HydratedDelivery;
  paymentAmount: number;
}) => {
  if (!delivery) return '';

  const uniquePickups = [delivery.pickup.postCode];
  const uniqueDropoffs = [delivery.dropOff.postCode];

  const journey = `${uniquePickups.join(' · ')} -> ${uniqueDropoffs.join(
    ' · ',
  )}`;

  return `${journey}
£${paymentAmount}

On the job board in the Tradeaze Driver app`;
};

export const useSendToJobBoard = ({
  paymentAmount,
  paymentCurrency,
  deliveryToAssign,
}: JobBoardHookProps) => {
  const assignDeliveryContext = useAssignDeliveryContext();
  const deliveryOptionsQuery = useGetDeliveryOptions({
    date,
    regionId: DEFAULT_REGION_ID,
  });
  const deliveryVehicles = deliveryOptionsQuery.data?.deliveryVehicles ?? [];
  const deliveryVehiclesSorted = deliveryVehicles.sort((a, b) => {
    return (
      vehicleSortOrder.indexOf(a.deliveryVehicleId) -
      vehicleSortOrder.indexOf(b.deliveryVehicleId)
    );
  });
  const [showVehicleSelection, setShowVehicleSelection] = useState(false);
  const [successModal, setShowSuccessModal] = useState(false);
  const [selectedVehicles, setSelectedVehicles] = useState<
    DeliveryVehicleId[] | []
  >([]);
  const onVehicleSelection = (deliveryVehicleId: DeliveryVehicleId) => {
    setSelectedVehicles((prevSelectedVehicles: DeliveryVehicleId[]) => {
      if (prevSelectedVehicles.includes(deliveryVehicleId)) {
        return prevSelectedVehicles.filter((id) => id !== deliveryVehicleId);
      } else {
        return [...prevSelectedVehicles, deliveryVehicleId];
      }
    });
  };
  const createGigJob = useCreateGigJob({
    onSuccess: (_) => {
      setShowVehicleSelection(false);
      setShowSuccessModal(true);
    },
    onError: (error) => {
      handleError(error, {
        showNotification: true,
        notificationMessage: 'Error creating gig job',
        sendToSentry: true,
      });
    },
  });
  const onSendToJobBoard = async () => {
    await createGigJob.mutateAsync({
      body: {
        deliveryIds: [deliveryToAssign.deliveryId],
        paymentAmount,
        paymentCurrency,
        deliveryVehicleIds: selectedVehicles,
      },
    });
  };
  const handleGoToHomepage = () => {
    assignDeliveryContext.handleCancelAssign();
  };

  const whatsAppMessage = useMemo(() => 
    generateWhatsAppMessage({
      delivery: deliveryToAssign,
      paymentAmount,
    }),
    [deliveryToAssign, paymentAmount]
  );

  const isLoading = createGigJob.isLoading;

  return {
    deliveryVehicles,
    deliveryVehiclesSorted,
    showVehicleSelection,
    selectedVehicles,
    successModal,
    isLoading,
    whatsAppMessage,
    setShowVehicleSelection,
    setSelectedVehicles,
    onVehicleSelection,
    onSendToJobBoard,
    setShowSuccessModal,
    handleGoToHomepage,
  };
};