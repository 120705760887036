import { z } from 'zod';
import { RiderBankDetailsSchema } from '../../../models';

export const CreateRiderBankDetailsBodySchema = RiderBankDetailsSchema.omit({
  id: true,
  riderId: true,
  createdAt: true,
  updatedAt: true,
});

export const CreateRiderBankDetailsResponseSchema = RiderBankDetailsSchema.pick({
  riderId: true,
  createdAt: true,
  updatedAt: true,
});

export type CreateRiderBankDetailsBody = z.infer<typeof CreateRiderBankDetailsBodySchema>;
export type CreateRiderBankDetailsResponse = z.infer<typeof CreateRiderBankDetailsResponseSchema>;
