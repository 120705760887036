import { Center, SimpleGrid, Spinner } from '@chakra-ui/react';
import { Company } from '@tradeaze-packages/schemas';
import { Waypoint } from 'react-waypoint';
import MerchantCustomerCard from './MerchantCustomerCard';

interface MerchantCustomerPreviewGridProps {
  isFetchingNextPage: boolean;
  onWaypointEnter: () => void;
  hasNextPage: boolean | undefined;
  merchantCustomers: Company[];
  columns?: number[];
  merchantId: string;
}

export const MerchantCustomerPreviewGrid = ({
  isFetchingNextPage,
  onWaypointEnter,
  hasNextPage,
  merchantCustomers,
  columns,
  merchantId,
}: MerchantCustomerPreviewGridProps) => {
  return (
    <>
      <SimpleGrid
        spacing={6}
        columns={columns}
        data-cy="merchant-customer-grid"
      >
        {merchantCustomers.map((merchantCustomer) => (
          <MerchantCustomerCard
            key={merchantCustomer.companyId}
            customer={merchantCustomer}
            merchantId={merchantId}
          />
        ))}
      </SimpleGrid>
      {isFetchingNextPage && (
        <Center h="15vh">
          <Spinner />
        </Center>
      )}
      {hasNextPage && onWaypointEnter && <Waypoint onEnter={onWaypointEnter} />}
    </>
  );
};
