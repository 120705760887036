import { Box, ChakraComponent, Heading } from '@chakra-ui/react';
import React from 'react';

export const FormSection: React.FC<
  {
    children: React.ReactNode;
    name: string;
    icon?: React.ReactNode;
  } & React.ComponentProps<ChakraComponent<'div'>>
> = ({ children, name, icon, ...props }) => (
  <Box
    {...props}
    borderWidth={0.5}
    p={[5, 7]}
    pt={[9, 9]}
    borderRadius={20}
    borderColor={'rgba(0,0,0,0.3)'}
    position={'relative'}
  >
    <Heading
      bg={'white'}
      px={6}
      position={'absolute'}
      top={-4}
      left={-1}
      size="md"
      display={'flex'}
      data-cy={`${name.toLowerCase().replace(/\s+/g, '-')}-heading`}
    >
      {name}
      {icon && <Box ml={2}>{icon}</Box>}
    </Heading>
    {children}
  </Box>
);
