import * as z from 'zod';
import { ClockInWorkTypeSchema, DeliveryVehicleIdSchema } from '../../../models';

export const ClockInRiderBodySchema = z.object({
  fleetVehicleId: z.string().optional(),
  workType: ClockInWorkTypeSchema.optional().default('SHIFT'),
  /**
   * todo - determine regNumber and deliveryVehicleId from fetching the fleetVehicle and remove from body
   */
  regNumber: z.string(),
  deliveryVehicle: DeliveryVehicleIdSchema.optional(),
});

export const ClockInRiderResponseSchema = z.object({
  clockedInAt: z.string(),
  clockInId: z.string(),
});

export type ClockInRiderBody = z.infer<typeof ClockInRiderBodySchema>;
export type ClockInRiderResponse = z.infer<typeof ClockInRiderResponseSchema>;
