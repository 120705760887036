import { HStack, Icon, Text } from '@chakra-ui/react';
import { BsDot } from "react-icons/bs";
import { FaLongArrowAltRight } from 'react-icons/fa';

export const DeliveryPostcodes: React.FC<{
  pickups?: { postCode: string }[];
  dropOffs?: { postCode: string }[];
}> = ({ pickups, dropOffs }) => {
  // Filter out duplicates by creating Sets of postcodes
  const uniquePickups = pickups ? [...new Set(pickups.map(p => p.postCode))] : [];
  const uniqueDropOffs = dropOffs ? [...new Set(dropOffs.map(d => d.postCode))] : [];

  return (
    <HStack flexWrap="wrap">
      {uniquePickups.map((postCode, index: number) => (
        <>
          {index > 0 ? <BsDot /> : null}
          <Text
            fontWeight="500"
            noOfLines={1}
            data-cy={`pickup-postcode-${index + 1}`}
          >
            {postCode?.toLocaleUpperCase()}
          </Text>
        </>
      ))}
      {uniquePickups.length > 0 && uniqueDropOffs.length > 0 && (
        <Icon as={FaLongArrowAltRight} color="blackAlpha.500" />
      )}
      {uniqueDropOffs.map((postCode, index: number) => (
        <>
          {index > 0 ? <BsDot /> : null}
          <Text
            fontWeight="500"
            noOfLines={1}
            data-cy={`dropoff-postcode-${index + 1}`}
          >
            {postCode?.toLocaleUpperCase()}
          </Text>
        </>
      ))}
    </HStack>
  );
};
