import {
  useMutation
} from '@tanstack/react-query';
import { createMerchantCompany } from '@tradeaze-packages/api-client';
import { CustomUseMutationOptions } from '../shared';
import { useInvalidateQueries } from '../utility';
import { getCompaniesQueryKey } from './useGetCompaniesByMerchant';

export const useCreateMerchantCompany = (
  options?: CustomUseMutationOptions<typeof createMerchantCompany>,
) => {
  const invalidateQueries = useInvalidateQueries();

  return useMutation({
    mutationFn: createMerchantCompany,
    ...options,
    onSuccess: (data, variables, context) => {
      invalidateQueries([getCompaniesQueryKey(variables.merchantId)]);
      options?.onSuccess?.(data, variables, context);
    },
  });
};
