import { useInfiniteQuery } from '@tanstack/react-query';
import { Company } from '@tradeaze-packages/schemas';
import { API } from 'aws-amplify';

interface CompaniesResponse {
  companies: Company[];
  nextPageIndex: number | null;
}

export const getCompaniesQueryKey = (merchantId: string | undefined | null) =>
  ['getCompaniesByMerchant', merchantId];

export const useGetCompaniesByMerchant = (
  merchantId: string | undefined | null
) =>
  useInfiniteQuery<CompaniesResponse>(
    getCompaniesQueryKey(merchantId),
    async ({ pageParam }) => {
      const apiName = 'MerchantApi',
        path = '/getCompaniesByMerchantId/' + merchantId,
        options = {
          queryStringParameters: {
            startIndex: pageParam?.startIndex,
            limit: Infinity,
          },
        };
      return await API.get(apiName, path, options);
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage.nextPageIndex) {
          return undefined;
        }
        return { startIndex: lastPage.nextPageIndex };
      },
      retry: false,
    }
  );
