import { z } from 'zod';
import { RiderDocumentSchema } from '../../../models';

export const CompleteRiderDocumentUploadBodySchema = z.object({
  documentId: z.string(),
  documentKey: z.string(),
});

export const CompleteRiderDocumentUploadResponseSchema = z.object({
  document: RiderDocumentSchema,
  url: z.string().optional(),
});

export type CompleteRiderDocumentUploadBody = z.infer<
  typeof CompleteRiderDocumentUploadBodySchema
>;

export type CompleteRiderDocumentUploadResponse = z.infer<
  typeof CompleteRiderDocumentUploadResponseSchema
>;
