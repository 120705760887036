import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Switch,
  Text,
} from '@chakra-ui/react';
import { HiMiniUserCircle } from 'react-icons/hi2';
import React from 'react';
import { useSiteContacts } from './useSiteContacts';
import { SelectDropdown } from '../../../shared';
import { ContactCard } from './ContactCard';

type SiteContactsProps = {
  companyId?: string | null;
};

export const SiteContacts = ({ companyId }: SiteContactsProps) => {
  const {
    form,
    savedContacts,
    savedContactPlaceholder,
    isLoadingSavedContacts,
    getIsContactSelected,
    getIsDropdownDisabled,
    handleDropdownSelect,
    contacts,
    handleRemoveContactCard,
    handleAddContactCard,
    handleUpdateContact,
    handleGroupTracking,
  } = useSiteContacts({ companyId });

  const {
    formState: { errors },
  } = form;

  return (
    <Box>
      {companyId ? (
        <>
          <Text fontWeight={'bold'} mb={2}>
            Saved Contacts
          </Text>
          <SelectDropdown
            placeholder={savedContactPlaceholder}
            results={savedContacts?.map((siteContact) => ({
              label: `${siteContact.contactName} - ${siteContact.contactNumber}`,
              value: siteContact,
              isSelected: getIsContactSelected(siteContact),
            }))}
            onSelect={handleDropdownSelect}
            isLoading={isLoadingSavedContacts}
            isDropDownDisabled={getIsDropdownDisabled()}
            isMultiSelect
            icon={<Box as={HiMiniUserCircle} marginRight={2} />}
          />
          <Divider my={6} opacity={0.3} />
        </>
      ) : null}
      {contacts.map((field, index) => {
        const contactErrors = errors?.contacts?.[index];

        const hasEmptyField =
          field.contactName === '' || field.contactNumber === '';

        const isInvalid = !!contactErrors || hasEmptyField;

        const isNotLastContact = index !== contacts.length - 1;

        const isSavedContact = Boolean(
          savedContacts?.find(
            (savedContact) =>
              savedContact.contactName === field.contactName &&
              savedContact.contactNumber === field.contactNumber,
          ),
        );

        return (
          <ContactCard
            key={index}
            contact={field}
            index={index}
            contactAccessor={`contacts.${index}`}
            isInvalid={isInvalid}
            handleRemoveCard={handleRemoveContactCard}
            handleUpdateContact={handleUpdateContact}
            collapseCard={(isNotLastContact && !isInvalid) || isSavedContact}
          />
        );
      })}
      {contacts.length === 5 ? (
        <Text color={'blackAlpha.500'}>
          You have reached the maximum number of contacts allowed
        </Text>
      ) : (
        <Button
          variant="outline"
          mt={6}
          width="100%"
          onClick={handleAddContactCard}
          isDisabled={contacts.length === 5}
          data-cy={'add-contact'}
        >
          Add more contacts
        </Button>
      )}
      {contacts.length > 1 ? (
        <FormControl display="flex" alignItems="center" mt={6}>
          <FormLabel htmlFor="tracking-link" mb="0">
            Send tracking SMS to all contacts?
          </FormLabel>
          <Switch
            id="tracking-link"
            size="md"
            isChecked={contacts.every((contact) => contact.trackingEnabled)}
            onChange={handleGroupTracking}
            data-cy={'send-tracking-sms-all'}
          />
        </FormControl>
      ) : null}
    </Box>
  );
};
