import { z } from 'zod';
import { RiderDocumentTypeIdSchema } from '../../../models';

// Define allowed file extensions
export const DocumentFileExtensionSchema = z.enum(['pdf', 'png', 'jpg', 'jpeg']);

export const GetRiderDocumentUploadUrlParamsSchema = z.object({
  riderId: z.string(),
  documentTypeId: RiderDocumentTypeIdSchema,
  fileExtension: DocumentFileExtensionSchema,
});

export const GetRiderDocumentUploadUrlResponseSchema = z.object({
  documentId: z.string(),
  documentTypeId: RiderDocumentTypeIdSchema,
  documentKey: z.string(),
  uploadUrl: z.string(),
});

export type DocumentFileExtension = z.infer<typeof DocumentFileExtensionSchema>;

export type GetRiderDocumentUploadUrlResponse = z.infer<
  typeof GetRiderDocumentUploadUrlResponseSchema
>;
