import { z } from 'zod';
import { RiderAccountSchema } from '../../../models';

export const RejectRiderRequestSchema = z.object({
  rejectedReason: z.string().optional(),
});

export const RejectRiderResponseSchema = z.object({
  message: z.string(),
  rider: RiderAccountSchema,
});

export type RejectRiderRequest = z.infer<
  typeof RejectRiderRequestSchema
>;

export type RejectRiderResponse = z.infer<
  typeof RejectRiderResponseSchema
>;
