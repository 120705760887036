import {
  Avatar,
  HStack,
  Icon,
  SimpleGrid,
  Text,
  Link,
  Divider,
  Box,
} from '@chakra-ui/react';
import { HydratedOrder } from '@tradeaze-packages/schemas';
import React from 'react';
import { IoCar } from 'react-icons/io5';
import { FaUserCircle } from 'react-icons/fa';
import { Attribute, TextValue } from '../../shared';
import { OrderSection } from '../order-section';
import { sectionColumns } from './common';
import { TrackingLink } from './TrackingLink';
import { ExternalJobStatusTag } from './ExternalJobStatusTag';
import { ExternalJobDeliveryLabel } from './ExternalJobDeliveryLabel';

export const ExternalJobDetails: React.FunctionComponent<{
  order: HydratedOrder;
  isAdmin?: boolean;
}> = ({ order, isAdmin }) => {
  const deliveriesWithExternalJob = order.deliveries.filter((d) => Boolean(d.externalJob));

  if (!deliveriesWithExternalJob.length) {
    return null;
  }
  return (
    <OrderSection name="External Job Details" icon={<IoCar />}>
      {deliveriesWithExternalJob.map((delivery, i) => (
        <Box key={delivery.deliveryId}>
          {i > 0 ? <Divider mt={3} mb={5} /> : null}
          <ExternalJobDeliveryLabel delivery={delivery} index={i} />
          <SimpleGrid columns={sectionColumns} spacing={4}>
            <Attribute name="Courier">
              {delivery?.externalCourier ? (
                  <HStack flexDirection={'column'} align={'flex-start'}>
                    <HStack>
                      <Avatar
                        size={'sm'}
                        name={`${delivery?.externalCourier?.firstName} ${delivery?.externalCourier?.lastName}`}
                      />
                      <TextValue text={delivery?.externalCourier?.firstName} />
                    </HStack>
                    <HStack>
                      <Text>{delivery?.externalCourier?.contactNumber}</Text>
                    </HStack>
                </HStack>
              ) : (
                <HStack alignItems={'center'}>
                  <HStack>
                    <Icon
                      as={FaUserCircle}
                      fontSize={24}
                      color={'blackAlpha.500'}
                    ></Icon>
                    <Text>Unassigned</Text>
                  </HStack>
                </HStack>
              )}
            </Attribute>
            <Attribute name="Fleet">
              <TextValue
                text={delivery?.externalJob?.providerName || 'Tradeaze'}
              />
            </Attribute>
            <Attribute name="External Status">
              <ExternalJobStatusTag status={delivery?.externalJob?.status} />
            </Attribute>
            {delivery?.externalJob?.externalUrl ? (
              <Attribute name="External URL">
                <Link
                  color={'blackAlpha.700'}
                  fontSize={'sm'}
                  href={delivery?.externalJob?.externalUrl}
                  noOfLines={1}
                >
                  {delivery?.externalJob?.externalUrl}
                </Link>
              </Attribute>
            ) : null}
            <Attribute name="Tracking URL">
              <TrackingLink
                deliveryId={delivery.deliveryId}
                isAdmin={isAdmin}
              />
            </Attribute>
          </SimpleGrid>
        </Box>
      ))}
    </OrderSection>
  );
};
