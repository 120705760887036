import {
  CreateOrder,
  CreateOrderMultiDrop,
  CreateOrderMultiDropSchema,
  ExistingOrder,
  MerchantAccount,
} from '@tradeaze-packages/schemas';
import { PostHog } from 'posthog-js';
import { AddMarkerFn, RemoveMarkerFn } from '../../map';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import {
  isBookingInformationSectionComplete,
  isDropOffSectionComplete,
  isInvoicingSectionComplete,
  isItemDetailsSectionComplete,
  isPickupSectionComplete,
  isVehicleDetailsSectionComplete,
} from '../utils';
import { useOrderSubForms } from './useOrderSubForms';
import { useEffect, useState } from 'react';
import { useMultiDropFormSubmission } from './useMultiDropFormSubmission';
import { useDeliveryPrice } from './useDeliveryPrice';

type UseMultiDropForm = {
  merchantAccount?: MerchantAccount;
  isAdmin?: boolean;
  posthog?: PostHog;
  onSubmit: (order: CreateOrder) => void;
  setShowMainBackButton?: (value: boolean) => void;
  addPickupMarker: AddMarkerFn;
  removePickupMarker: RemoveMarkerFn;
  addDeliveryMarker: AddMarkerFn;
  removeDeliveryMarker: RemoveMarkerFn;
  existingOrder?: ExistingOrder;
};

export const useMultiDropForm = ({
  merchantAccount,
  isAdmin,
  posthog,
  onSubmit,
  setShowMainBackButton,
  addPickupMarker,
  removePickupMarker,
  addDeliveryMarker,
  removeDeliveryMarker,
  existingOrder,
}: UseMultiDropForm) => {
  const form = useForm<CreateOrder>({
    defaultValues: {
      type: 'MULTI_DROP',
      invoicingName:
        existingOrder?.invoicingName ??
        merchantAccount?.invoicingName ??
        merchantAccount?.merchantName ??
        '',
      merchantId: merchantAccount?.merchantId,
      dropOffs:
        (existingOrder?.dropoffDetails as CreateOrderMultiDrop['dropOffs']) ??
        [],
      deliveryVehicle: existingOrder?.deliveryVehicle ?? 'BIKE',
      deliveryOption: existingOrder?.deliveryOption ?? undefined,
      bookedBy: existingOrder?.bookedBy ?? undefined,
      merchantOrderReference: existingOrder?.orderReference ?? undefined,
    },
    resolver: zodResolver(CreateOrderMultiDropSchema),
    mode: 'all',
  });

  const multiDropDetails = form.watch() as CreateOrderMultiDrop;

  const [showDropOffSection, setShowDropOffSection] = useState(false);
  const [selectedDropOffIndex, setSelectedDropOffIndex] = useState<
    number | null
  >(null);
  const [existingDropOffs, setExistingDropOffs] = useState(0);

  const {
    pickupForm,
    pickupDetails,
    dropOffForm,
    dropoffDetails,
    itemForm,
    itemDetails,
    resetDropOffForm,
    resetItemForm,
  } = useOrderSubForms({
    merchantAccount,
    existingStopData: existingOrder
      ? {
          pickupDetails: existingOrder.pickupDetails,
          dropOffDetails: existingOrder.dropoffDetails,
        }
      : undefined,
    orderType: 'MULTI_DROP',
  });

  const isInvoicingComplete = isInvoicingSectionComplete(form);
  const isPickupComplete = isPickupSectionComplete(pickupDetails, pickupForm);
  const isDropOffComplete = isDropOffSectionComplete(
    dropoffDetails,
    dropOffForm,
  );
  const isItemDetailsComplete = isItemDetailsSectionComplete(
    itemDetails,
    itemForm,
  );
  const isVehicleDetailsComplete = isVehicleDetailsSectionComplete(
    multiDropDetails,
    form,
  );

  const isBookingInformationComplete = isBookingInformationSectionComplete(
    form,
    merchantAccount?.referencePrefix,
  );

  const isDisableDropOffCollectionReference = Boolean(
    pickupDetails.collectionReference,
  );

  const handleAddDeliveryButton = () => {
    resetForms();
    setExistingDropOffs(multiDropDetails.dropOffs?.length ?? 0);
    setShowDropOffSection(true);
    setShowMainBackButton?.(false);
  };

  const handleCreateBackButton = () => {
    removeDeliveryMarker(
      ((multiDropDetails.dropOffs?.length ?? 0) + 2).toString(),
    );
    setShowMainBackButton?.(true);
    setShowDropOffSection(false);
  };

  const handleEditBackButton = () => {
    const existingPosition =
      selectedDropOffIndex !== null
        ? multiDropDetails.dropOffs[selectedDropOffIndex].position
        : null;
    if (existingPosition && selectedDropOffIndex !== null) {
      addDeliveryMarker({
        id: (selectedDropOffIndex + 2).toString(),
        position: existingPosition,
        stopSequence: selectedDropOffIndex + 2,
      });
    }
    resetForms();
    setSelectedDropOffIndex(null);
    setShowMainBackButton?.(true);
    setShowDropOffSection(false);
  };

  const resetForms = () => {
    resetDropOffForm();
    resetItemForm();
  };

  const { handleAddDelivery, handleUpdateDelivery, handleSubmit } =
    useMultiDropFormSubmission({
      dropOffForm,
      form,
      pickupForm,
      itemForm,
      resetForms,
      setShowDropOffSection,
      setShowMainBackButton,
      selectedDropOffIndex,
      setSelectedDropOffIndex,
      onSubmit,
      multiDropDetails,
    });

  const handleOrderCardSelect = (index: number) => {
    const dropOff = form.getValues('dropOffs')[index];
    if (dropOff) {
      dropOffForm.reset(dropOff);
      itemForm.reset(dropOff.deliveryItems[0]);
      setSelectedDropOffIndex(index);
      setShowMainBackButton?.(false);
      setShowDropOffSection(true);
    }
  };

  const handleRemoveOrderCard = (index: number, stopSequence: number) => {
    const updatedDropOffs = multiDropDetails.dropOffs?.filter(
      (_, i) => i !== index,
    );
    form.setValue('dropOffs', updatedDropOffs);
    removeDeliveryMarker(stopSequence.toString());
  };

  const hidePriorityDelivery =
    merchantAccount?.featureFlags?.hidePriorityDelivery || false;

  const allStops: { postCode: string; type: 'PICK_UP' | 'DROP_OFF' }[] = [
    {
      postCode: pickupDetails.postCode,
      type: 'PICK_UP' as const,
    },
    ...(multiDropDetails.dropOffs?.map((dropOff) => ({
      postCode: dropOff.postCode,
      type: 'DROP_OFF' as const,
    })) || []),
  ].filter((stop) => stop.postCode);

  const anyStopHasHeavySideItems =
    multiDropDetails.dropOffs?.some(
      (dropOff) => dropOff.deliveryItems[0]?.heavySideItems,
    ) ?? false;

  const {
    isDeliveryPriceLoading,
    deliveryPriceErrorMessage,
    thresholdDistances,
  } = useDeliveryPrice({
    merchantAccount,
    createOrderDetails: multiDropDetails,
    allStops,
    isAdmin,
    posthog,
    form,
    pickupForm,
    dropOffForm,
    heavySideItems: anyStopHasHeavySideItems,
    isSamePricingConfig: false,
    existingOrder: Boolean(existingOrder),
    isMultiCollection: true,
  });

  const pickupReference = pickupForm.watch('collectionReference');
  const formattedPickupAddress =
    pickupDetails.addressLine1 && pickupDetails.postCode
      ? `${pickupDetails.addressLine1}, ${pickupDetails.postCode}`
      : 'No pickup address set';

  useEffect(() => {
    if (existingOrder?.dropoffDetails) {
      existingOrder.dropoffDetails.forEach((dropOff, index) => {
        addDeliveryMarker({
          id: (index + 2).toString(),
          position: dropOff.position ?? { latitude: 0, longitude: 0 },
        });
      });
    }
  }, [addDeliveryMarker, existingOrder?.dropoffDetails]);

  useEffect(() => {
    if (pickupReference) {
      form.setValue(
        'dropOffs',
        multiDropDetails.dropOffs.map((dropOff) => ({
          ...dropOff,
          collectionReference: pickupReference,
        })),
      );
    }
  }, [pickupReference]);

  /*
    This useEffect manages the scroll to the drop off section 
    when add drop form is visible and to the add drop button 
    when a drop is added/updated
  */
  useEffect(() => {
    if (!showDropOffSection) {
      const section = document.getElementById('add-drop-section');
      if (section) {
        const windowHeight = window.innerHeight;
        const elementHeight = section.getBoundingClientRect().height;
        const elementTop = section.getBoundingClientRect().top + window.scrollY;
        const offset = (windowHeight - elementHeight) / 2;

        window.scrollTo({
          top: elementTop - offset,
          behavior: 'instant',
        });
      }
    } else {
      setTimeout(() => {
        const section = document.getElementById('drop-back-button');
        if (section) {
          section.scrollIntoView({ behavior: 'auto', block: 'start' });
        }
      }, 0);
    }
  }, [showDropOffSection]);

  return {
    form,
    pickupForm,
    isInvoicingComplete,
    isPickupComplete,
    showDropOffSection,
    handleAddDeliveryButton,
    handleCreateBackButton,
    isDropOffComplete,
    dropOffForm,
    isItemDetailsComplete,
    itemForm,
    handleAddDelivery,
    multiDropDetails,
    selectedDropOffIndex,
    handleEditBackButton,
    handleUpdateDelivery,
    handleOrderCardSelect,
    handleRemoveOrderCard,
    existingDropOffs,
    hidePriorityDelivery,
    isDeliveryPriceLoading,
    deliveryPriceErrorMessage,
    thresholdDistances,
    itemDetails,
    isVehicleDetailsComplete,
    isBookingInformationComplete,
    handleSubmit,
    isDisableDropOffCollectionReference,
    pickupReference,
    formattedPickupAddress,
  };
};
