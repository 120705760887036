import { useQuery } from '@tanstack/react-query';
import { GetRidersQuery } from '@tradeaze-packages/schemas';
import { fetchRiderAccounts } from '@tradeaze-packages/api-client';
import { CustomUseQueryOptions } from '../shared';

export const getRidersQueryKey = (filters?: GetRidersQuery) => {
  const key: (string | object)[] = ['getRiders']
  if (filters) {
    key.push(filters);
  }
  return key;
};

const fiveMinutes = 5 * 60 * 1000;

export const useGetRiders = (
  filters: GetRidersQuery,
  options?: CustomUseQueryOptions<typeof fetchRiderAccounts>
) => {
  return useQuery({
    queryKey: getRidersQueryKey(filters),
    queryFn: async () => fetchRiderAccounts(filters),
    staleTime: fiveMinutes,
    refetchOnWindowFocus: false,
    ...options,
  });
};
