import {
  OpenAPIRegistry,
  extendZodWithOpenApi,
} from '@asteasolutions/zod-to-openapi';
import {
  CreateWebhookConfigRequestSchema,
  CreateWebhookConfigResponseSchema,
  DeleteWebhookConfigResponseSchema,
  GetWebhooksConfigResponseSchema,
  PublicDeleteDeliveriesResponseSchema,
  PublicGetDeliveryResponseSchema,
  PublicPatchDeliveryRequestSchema,
  PublicPatchDeliveryResponseSchema,
  PublicPostDeliveryRequestSchema,
  PublicPostDeliveryResponseSchema,
  PublicPostQuoteRequestSchema,
  PublicPostQuoteResponseSchema,
  UpdateWebhookConfigRequestSchema,
  UpdateWebhookConfigResponseSchema,
} from '../../public-access-service';
import { z } from 'zod';
import {
  DropoffArrivedPostBodySchema,
  DropoffCompletePostBodySchema,
  DropoffEnroutePostBodySchema,
  OrderCancelledPostBodySchema,
  OrderConfirmedWebhookPostBodySchema,
  OrderCreatedWebhookPostBodySchema,
  OrderRejectedPostBodySchema,
  OrderUpdatedPostBodySchema,
  PickupArrivedPostBodySchema,
  PickupCompletePostBodySchema,
  PickupEnroutePostBodySchema,
  WebhookEventType,
  WebhookEventTypeSchema,
} from '../../../models';
import { AuthHeaderSchema } from '../../shared';
import { commonErrorResponses } from './common';

extendZodWithOpenApi(z);

const registry = new OpenAPIRegistry();

const basePathV1 = '/v1';

/**
 * GET /deliveries/{id}
 */
registry.registerPath({
  operationId: 'Get Delivery by ID',
  path: `${basePathV1}/deliveries/:id`,
  description:
    'Returns a delivery with the assigned courier and their location.',
  method: 'get',
  request: {
    headers: AuthHeaderSchema,
  },
  responses: {
    200: {
      description: 'Successfully retrieved delivery.',
      content: {
        'application/json': {
          schema: PublicGetDeliveryResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /deliveries
 */
registry.registerPath({
  operationId: 'Create Delivery',
  path: `${basePathV1}/deliveries`,
  method: 'post',
  description: 'Creates a delivery.',
  request: {
    body: {
      content: {
        'application/json': {
          schema: PublicPostDeliveryRequestSchema,
        },
      },
    },
    headers: AuthHeaderSchema,
  },
  responses: {
    200: {
      description: 'Successfully created delivery.',
      content: {
        'application/json': {
          schema: PublicPostDeliveryResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * PATCH /deliveries/{id}
 */
registry.registerPath({
  operationId: 'Update Delivery',
  path: `${basePathV1}/deliveries/:id`,
  method: 'patch',
  description: 'Updates a delivery.',
  request: {
    body: {
      description:
        'The delivery attributes to update. NOTE: You cannot change any attributes that influence the price of the delivery.',
      content: {
        'application/json': {
          schema: PublicPatchDeliveryRequestSchema,
        },
      },
    },
    headers: AuthHeaderSchema,
  },
  responses: {
    200: {
      description: 'Successfully updated delivery.',
      content: {
        'application/json': {
          schema: PublicPatchDeliveryResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * DELETE /deliveries/{id}
 */
registry.registerPath({
  operationId: 'Cancel Delivery',
  path: `${basePathV1}/deliveries/:id`,
  method: 'delete',
  description: 'Cancels a delivery.',
  request: {
    headers: AuthHeaderSchema,
  },
  responses: {
    200: {
      description: 'Successfully cancelled delivery.',
      content: {
        'application/json': {
          schema: PublicDeleteDeliveriesResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /deliveries/quote
 */
registry.registerPath({
  operationId: 'Get Delivery Quote',
  path: `${basePathV1}/deliveries/quote`,
  method: 'post',
  description: 'Returns a quote for all delivery options.',
  request: {
    body: {
      content: {
        'application/json': {
          schema: PublicPostQuoteRequestSchema,
        },
      },
    },
    headers: AuthHeaderSchema,
  },
  responses: {
    200: {
      description:
        'Sucessfully retrieved delivery options and vehicle information.',
      content: {
        'application/json': {
          schema: PublicPostQuoteResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /webhooks
 */
registry.registerPath({
  operationId: 'Get Webhooks',
  path: `${basePathV1}/webhooks`,
  method: 'get',
  description:
    'Returns a list of webhook configurations for the current account.',
  request: {
    headers: AuthHeaderSchema,
  },
  responses: {
    200: {
      description: 'Successfully retrieved webhook configurations.',
      content: {
        'application/json': {
          schema: GetWebhooksConfigResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /webhooks
 */
registry.registerPath({
  operationId: 'Create Webhook',
  path: `${basePathV1}/webhooks`,
  method: 'post',
  description: 'Creates a webhook configuration.',
  request: {
    body: {
      content: {
        'application/json': {
          schema: CreateWebhookConfigRequestSchema,
        },
      },
    },
    headers: AuthHeaderSchema,
  },
  responses: {
    200: {
      description: 'Successfully created webhook configuration.',
      content: {
        'application/json': {
          schema: CreateWebhookConfigResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * PATCH /webhooks/{eventId}
 */
registry.registerPath({
  operationId: 'Update Webhook',
  path: `${basePathV1}/webhooks/:eventId`,
  method: 'patch',
  description: 'Updates a webhook configuration.',
  request: {
    body: {
      content: {
        'application/json': {
          schema: UpdateWebhookConfigRequestSchema,
        },
      },
    },
    headers: AuthHeaderSchema,
  },
  responses: {
    200: {
      description: 'Successfully updated webhook configuration.',
      content: {
        'application/json': {
          schema: UpdateWebhookConfigResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * DELETE /webhooks/{eventId}
 */
registry.registerPath({
  operationId: 'Delete Webhook',
  path: `${basePathV1}/webhooks/:eventId`,
  method: 'delete',
  description: 'Deletes a webhook configuration.',
  request: {
    headers: AuthHeaderSchema,
  },
  responses: {
    200: {
      description: 'Successfully deleted webhook configuration.',
      content: {
        'application/json': {
          schema: DeleteWebhookConfigResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

(
  [
    {
      id: WebhookEventTypeSchema.Values.ORDER_CREATED,
      description: 'Fires when a delivery is created.',
      schema: OrderCreatedWebhookPostBodySchema,
    },
    {
      id: WebhookEventTypeSchema.Values.ORDER_CONFIRMED,
      description: 'Fires when a delivery is confirmed.',
      schema: OrderConfirmedWebhookPostBodySchema,
    },
    {
      id: WebhookEventTypeSchema.Values.ORDER_CANCELLED,
      description: 'Fires when a delivery is cancelled.',
      schema: OrderCancelledPostBodySchema,
    },
    {
      id: WebhookEventTypeSchema.Values.ORDER_UPDATED,
      description: 'Fires when a delivery is updated.',
      schema: OrderUpdatedPostBodySchema,
    },
    {
      id: WebhookEventTypeSchema.Values.ORDER_REJECTED,
      description: 'Fires when a delivery is rejected.',
      schema: OrderRejectedPostBodySchema,
    },
    {
      id: WebhookEventTypeSchema.Values.PICKUP_ENROUTE,
      description: 'Fires when a courier is enroute to the pickup location.',
      schema: PickupEnroutePostBodySchema,
    },
    {
      id: WebhookEventTypeSchema.Values.PICKUP_ARRIVED,
      description: 'Fires when a courier has arrived at the pickup location.',
      schema: PickupArrivedPostBodySchema,
    },
    {
      id: WebhookEventTypeSchema.Values.PICKUP_COMPLETE,
      description: 'Fires when a courier has completed the pickup.',
      schema: PickupCompletePostBodySchema,
    },
    {
      id: WebhookEventTypeSchema.Values.DROPOFF_ENROUTE,
      description: 'Fires when a courier is enroute to the dropoff location.',
      schema: DropoffEnroutePostBodySchema,
    },
    {
      id: WebhookEventTypeSchema.Values.DROPOFF_ARRIVED,
      description: 'Fires when a courier has arrived at the dropoff location.',
      schema: DropoffArrivedPostBodySchema,
    },
    {
      id: WebhookEventTypeSchema.Values.DROPOFF_COMPLETE,
      description: 'Fires when a courier has completed the dropoff.',
      schema: DropoffCompletePostBodySchema,
    },
  ] satisfies {
    id: WebhookEventType;
    description: string;
    schema?: z.ZodSchema<any>;
  }[]
).forEach(({ id, description, schema }) => {
  registry.registerWebhook({
    operationId: id,
    path: id,
    description,
    method: 'post',
    responses: {
      200: {
        description: 'Posts data to the subscribed webhook url.',
        content: {
          'application/json': {
            schema: schema,
          },
        },
      },
    },
  });
});

export const publicApiRegistry = registry;
