import {
  OpenAPIRegistry,
  extendZodWithOpenApi,
} from '@asteasolutions/zod-to-openapi';
import {
  CancelOrderResponseSchema,
  CompleteOrderResponseSchema,
  ConfirmOrderResponseSchema,
  CreateOrderBodySchema,
  CreateOrderResponseSchema,
  DeleteOrderResponseSchema,
  DownloadAdminOrdersCsvQuerySchema,
  DownloadAdminOrdersCsvResponseSchema,
  DownloadMerchantOrdersCsvQuerySchema,
  DownloadMerchantOrdersCsvResponseSchema,
  GetAdminOrdersQuerySchema,
  GetAdminOrdersResponseSchema,
  GetOrderByIdQuerySchema,
  GetOrderByIdResponseSchema,
  GetOrdersByEndUserIdQuerySchema,
  GetOrdersByEndUserIdResponseSchema,
  GetOrdersByMerchantIdQuerySchema,
  GetOrdersByMerchantIdResponseSchema,
  GetOrdersByRiderIdQuerySchema,
  GetOrdersByRiderIdResponseSchema,
  GetRegionsResponseSchema,
  ManVanRequestResponseSchema,
  ManVanRequestBodySchema,
  RejectOrderResponseSchema,
  UpdateOrderBodySchema,
  UpdateOrderResponseSchema,
  HiabRequestResponseSchema,
  HiabRequestBodySchema,
} from '../../order-service';
import { z } from 'zod';
import { commonErrorResponses } from './common';

extendZodWithOpenApi(z);

const registry = new OpenAPIRegistry();

const basePath = '/order';

/**
 * GET /cancelOrder/{orderId}
 */
registry.registerPath({
  operationId: 'cancelOrder',
  path: `${basePath}/cancelOrder/{orderId}`,
  description: 'Cancel an order.',
  method: 'post',
  request: {
    params: z.object({
      orderId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Order cancelled successfully.',
      content: {
        'application/json': {
          schema: CancelOrderResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /completeOrder/{orderId}
 */
registry.registerPath({
  operationId: 'completeOrder',
  path: `${basePath}/completeOrder/{orderId}`,
  description: 'Complete an order.',
  method: 'get',
  request: {
    params: z.object({
      orderId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Order completed successfully.',
      content: {
        'application/json': {
          schema: CompleteOrderResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /confirmOrder/{orderId}
 */
registry.registerPath({
  operationId: 'confirmOrder',
  path: `${basePath}/confirmOrder/{orderId}`,
  description: 'Confirm an order.',
  method: 'get',
  request: {
    params: z.object({
      orderId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Order confirmed successfully.',
      content: {
        'application/json': {
          schema: ConfirmOrderResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /adminCreateOrder (For Admin)
 */
registry.registerPath({
  operationId: 'adminCreateOrder',
  path: `${basePath}/adminCreateOrder`,
  description: 'Create a new order.',
  method: 'post',
  request: {
    body: {
      content: {
        'application/json': {
          schema: CreateOrderBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Order created successfully.',
      content: {
        'application/json': {
          schema: CreateOrderResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /merchantCreateOrder (For Merchant)
 */
registry.registerPath({
  operationId: 'merchantCreateOrder',
  path: `${basePath}/merchantCreateOrder`,
  description: 'Create a new order.',
  method: 'post',
  request: {
    body: {
      content: {
        'application/json': {
          schema: CreateOrderBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Order created successfully.',
      content: {
        'application/json': {
          schema: CreateOrderResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * DELETE /adminDeleteOrder/{orderId}
 */
registry.registerPath({
  operationId: 'adminDeleteOrder',
  path: `${basePath}/adminDeleteOrder/{orderId}`,
  description: 'Delete an order.',
  method: 'delete',
  request: {
    params: z.object({
      orderId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Order deleted successfully.',
      content: {
        'application/json': {
          schema: DeleteOrderResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * DELETE /merchantDeleteOrder/{orderId}
 */
registry.registerPath({
  operationId: 'merchantDeleteOrder',
  path: `${basePath}/merchantDeleteOrder/{orderId}`,
  description: 'Delete an order.',
  method: 'delete',
  request: {
    params: z.object({
      orderId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Order deleted successfully.',
      content: {
        'application/json': {
          schema: DeleteOrderResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /downloadOrdersCsv (For Admin)
 */
registry.registerPath({
  operationId: 'downloadOrdersCsv',
  path: `${basePath}/downloadOrdersCsv`,
  description: 'Download orders as CSV.',
  method: 'get',
  request: {
    query: DownloadAdminOrdersCsvQuerySchema,
  },
  responses: {
    200: {
      description: 'Orders downloaded successfully.',
      content: {
        'application/csv': {
          schema: DownloadAdminOrdersCsvResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /downloadMerchantOrdersCsv/:merchantId (For Merchant)
 */
registry.registerPath({
  operationId: 'downloadMerchantOrdersCsv',
  path: `${basePath}/downloadMerchantOrdersCsv/{merchantId}`,
  description: 'Download orders as CSV.',
  method: 'get',
  request: {
    params: z.object({
      merchantId: z.string(),
    }),
    query: DownloadMerchantOrdersCsvQuerySchema,
  },
  responses: {
    200: {
      description: 'Orders downloaded successfully.',
      content: {
        'application/csv': {
          schema: DownloadMerchantOrdersCsvResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getAdminOrders
 */
registry.registerPath({
  operationId: 'getAdminOrders',
  path: `${basePath}/getAdminOrders`,
  description: 'Get admin orders.',
  method: 'get',
  request: {
    query: GetAdminOrdersQuerySchema,
  },
  responses: {
    200: {
      description: 'Orders fetched successfully.',
      content: {
        'application/json': {
          schema: GetAdminOrdersResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getRegions
 */
registry.registerPath({
  operationId: 'getRegions',
  path: `${basePath}/getRegions`,
  description: 'Get regions.',
  method: 'get',
  responses: {
    200: {
      description: 'Regions fetched successfully.',
      content: {
        'application/json': {
          schema: GetRegionsResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getOrderById/{orderId}
 */
registry.registerPath({
  operationId: 'getOrderById',
  path: `${basePath}/getOrderById/{orderId}`,
  description: 'Get order by ID.',
  method: 'get',
  request: {
    params: z.object({
      orderId: z.string(),
    }),
    query: GetOrderByIdQuerySchema,
  },
  responses: {
    200: {
      description: 'Order fetched successfully.',
      content: {
        'application/json': {
          schema: GetOrderByIdResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getOrdersByEndUserId/{endUserId}
 */
registry.registerPath({
  operationId: 'getOrdersByEndUserId',
  path: `${basePath}/getOrdersByEndUserId/{endUserId}`,
  description: 'Get orders by end user ID.',
  method: 'get',
  request: {
    params: z.object({
      endUserId: z.string(),
    }),
    query: GetOrdersByEndUserIdQuerySchema,
  },
  responses: {
    200: {
      description: 'Orders fetched successfully.',
      content: {
        'application/json': {
          schema: GetOrdersByEndUserIdResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getOrdersByMerchantId/{merchantId}
 */
registry.registerPath({
  operationId: 'getOrdersByMerchantId',
  path: `${basePath}/getOrdersByMerchantId/{merchantId}`,
  description: 'Get orders by merchant ID.',
  method: 'get',
  request: {
    params: z.object({
      merchantId: z.string(),
    }),
    query: GetOrdersByMerchantIdQuerySchema,
  },
  responses: {
    200: {
      description: 'Orders fetched successfully.',
      content: {
        'application/json': {
          schema: GetOrdersByMerchantIdResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getOrdersByRiderId/{riderId}
 */
registry.registerPath({
  operationId: 'getOrdersByRiderId',
  path: `${basePath}/getOrdersByRiderId/{riderId}`,
  description: 'Get orders by rider ID.',
  method: 'get',
  request: {
    params: z.object({
      riderId: z.string(),
    }),
    query: GetOrdersByRiderIdQuerySchema,
  },
  responses: {
    200: {
      description: 'Orders fetched successfully.',
      content: {
        'application/json': {
          schema: GetOrdersByRiderIdResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /rejectOrder/{orderId}
 */
registry.registerPath({
  operationId: 'rejectOrder',
  path: `${basePath}/rejectOrder/{orderId}`,
  description: 'Reject an order.',
  method: 'get',
  request: {
    params: z.object({
      orderId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Order rejected successfully.',
      content: {
        'application/json': {
          schema: RejectOrderResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /adminUpdateOrder/{orderId}
 */
registry.registerPath({
  operationId: 'adminUpdateOrder',
  path: `${basePath}/adminUpdateOrder/{orderId}`,
  description: 'Update an order.',
  method: 'post',
  request: {
    params: z.object({
      orderId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: UpdateOrderBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Order updated successfully.',
      content: {
        'application/json': {
          schema: UpdateOrderResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /merchantUpdateOrder/{orderId}
 */
registry.registerPath({
  operationId: 'merchantUpdateOrder',
  path: `${basePath}/merchantUpdateOrder/{orderId}`,
  description: 'Update an order.',
  method: 'post',
  request: {
    params: z.object({
      orderId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: UpdateOrderBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Order updated successfully.',
      content: {
        'application/json': {
          schema: UpdateOrderResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /man-van-request
 */
registry.registerPath({
  operationId: 'manVanRequest',
  path: `${basePath}/man-van-request`,
  description: 'Create a new Man and Van hire request',
  method: 'post',
  request: {
    body: {
      content: {
        'application/json': {
          schema: ManVanRequestBodySchema,
        },
      },
    },
  },
  responses: {
    201: {
      description: 'Man and Van hire request created successfully.',
      content: {
        'application/json': {
          schema: ManVanRequestResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /hiab-request
 */
registry.registerPath({
  operationId: 'hiabRequest',
  path: `${basePath}/hiab-request`,
  description: 'Create a new HIAB crane delivery request',
  method: 'post',
  request: {
    body: {
      content: {
        'application/json': {
          schema: HiabRequestBodySchema,
        },
      },
    },
  },
  responses: {
    201: {
      description: 'HIAB crane delivery request created successfully.',
      content: {
        'application/json': {
          schema: HiabRequestResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

export const orderApiRegistry = registry;
