import { z } from 'zod';
import { HydratedOrderSchema, HydratedDeliverySchema } from './hydrated-schema';
import { PublicDeliverySchema } from './public-access-schema';
import { extendZodWithOpenApi } from '@asteasolutions/zod-to-openapi';
import { DropoffDeliveryStopSchema } from './delivery-stop-schema';
import { DeliveryVehicleIdSchema } from './delivery-vehicle-schema';

extendZodWithOpenApi(z);

/**
 * Event Type Schema
 * @description
 * This is the schema for the event types sent to the SNS topic
 */
export const EventTypeSchema = z.enum([
  'ORDER_CREATED',
  'SCHEDULE_UPDATED',
  'ORDER_CONFIRMED',
  'ORDER_CANCELLED',
  'ORDER_REJECTED',
  'ORDER_COMPLETE',
  'ORDER_UPDATED',
  'PICKUP_ENROUTE',
  'PICKUP_ARRIVED',
  'PICKUP_COMPLETE',
  'DROPOFF_ENROUTE',
  'DROPOFF_ARRIVED',
  'DROPOFF_COMPLETE',
  'DELIVERY_ASSIGNED',
  'DELIVERY_UNASSIGNED',
  'DELIVERY_CANCELLED',
  'DELIVERY_CONFIRMED',
  'DELIVERY_REJECTED',
  'DELIVERY_COMPLETE',
  'FLEET_VEHICLE_REJECTED',
  'FLEET_VEHICLE_APPROVED',
  'RIDER_REJECTED',
  'RIDER_APPROVED',
  'GIG_JOB_CREATED',
  'EXTERNAL_JOB_CANCELLED_EXTERNALLY',
]);

export const WebhookEventTypeSchema = z
  .enum([
    EventTypeSchema.Values.ORDER_CREATED,
    EventTypeSchema.Values.ORDER_CONFIRMED,
    EventTypeSchema.Values.ORDER_CANCELLED,
    EventTypeSchema.Values.ORDER_REJECTED,
    EventTypeSchema.Values.ORDER_UPDATED,
    EventTypeSchema.Values.PICKUP_ENROUTE,
    EventTypeSchema.Values.PICKUP_ARRIVED,
    EventTypeSchema.Values.PICKUP_COMPLETE,
    EventTypeSchema.Values.DROPOFF_ENROUTE,
    EventTypeSchema.Values.DROPOFF_ARRIVED,
    EventTypeSchema.Values.DROPOFF_COMPLETE,
  ])
  .openapi('EventId');

export const WebhookConfigSchema = z.object({
  eventId: WebhookEventTypeSchema,
  name: z.string(),
  description: z.string().nullish(),
  isActive: z.boolean(),
  webhookUrl: z.string().url(),
});

/**
 * SNS Message
 * @description
 * This is the data that is sent to the SNS topics
 */

/**
 * Determines which subscriptions are triggered
 */
const CommonSnsSchema = z.object({
  email: z.boolean().optional(),
  webhook: z.boolean().optional(),
  push: z.boolean().optional(),
  create: z.boolean().optional(),
  sms: z.boolean().optional(),
  eta: z.boolean().optional(),
});

const OrderActionedSnsSchema = z.object({
  orderId: z.string(),
});
const GigJobCreatedActionedSnsSchema = z.object({
  gigJobId: z.string(),
  deliveryIds: z.string().array(),
  deliveryVehicleIds: DeliveryVehicleIdSchema.array(),
});

const DeliveryStopActionedSnsSchema = z.object({
  deliveryStopId: z.string(),
});

const DeliveryAssignedSnsDataSchema = z.object({
  deliveryId: z.string(),
  riderId: z.string(),
});

const DeliveryUnassignedSnsDataSchema = DeliveryAssignedSnsDataSchema;

const DeliveryActionedSnsSchema = z.object({
  deliveryId: z.string(),
});

const ScheduleUpdatedSnsDataSchema = z.object({
  deliveryScheduleId: z.string(),
});

const FleetVehicleActionedSnsDataSchema = z.object({
  ownerId: z.string(),
  regNumber: z.string(),
});

const RiderActionedSnsDataSchema = z.object({
  riderId: z.string(),
});

const DeliveryAssignedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.DELIVERY_ASSIGNED),
  data: DeliveryAssignedSnsDataSchema,
});

const DeliveryUnassignedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.DELIVERY_UNASSIGNED),
  data: DeliveryUnassignedSnsDataSchema,
});

const DeliveryCancelledSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.DELIVERY_CANCELLED),
  data: DeliveryActionedSnsSchema,
});

const DeliveryConfirmedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.DELIVERY_CONFIRMED),
  data: DeliveryActionedSnsSchema,
});

const DeliveryRejectedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.DELIVERY_REJECTED),
  data: DeliveryActionedSnsSchema,
});

const DeliveryCompleteSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.DELIVERY_COMPLETE),
  data: DeliveryActionedSnsSchema,
});

const OrderCreatedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.ORDER_CREATED),
  data: OrderActionedSnsSchema,
});

const OrderConfirmedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.ORDER_CONFIRMED),
  data: OrderActionedSnsSchema,
});

const OrderCancelledSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.ORDER_CANCELLED),
  data: OrderActionedSnsSchema,
});
const DeliveryCancelledExternallySnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.EXTERNAL_JOB_CANCELLED_EXTERNALLY),
  data: DeliveryActionedSnsSchema,
});

const OrderRejectedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.ORDER_REJECTED),
  data: OrderActionedSnsSchema,
});

const OrderUpdatedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.ORDER_UPDATED),
  data: OrderActionedSnsSchema,
});

const OrderCompleteSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.ORDER_COMPLETE),
  data: OrderActionedSnsSchema,
});

const PickupEnrouteSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.PICKUP_ENROUTE),
  data: DeliveryStopActionedSnsSchema,
});

const PickupArrivedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.PICKUP_ARRIVED),
  data: DeliveryStopActionedSnsSchema,
});

const PickupCompleteSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.PICKUP_COMPLETE),
  data: DeliveryStopActionedSnsSchema,
});

const DropoffEnrouteSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.DROPOFF_ENROUTE),
  data: DeliveryStopActionedSnsSchema,
});

const DropoffArrivedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.DROPOFF_ARRIVED),
  data: DeliveryStopActionedSnsSchema,
});

const DropoffCompleteSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.DROPOFF_COMPLETE),
  data: DeliveryStopActionedSnsSchema,
});

const ScheduleUpdatedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.SCHEDULE_UPDATED),
  data: ScheduleUpdatedSnsDataSchema,
});

const FleetVehicleRejectedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.FLEET_VEHICLE_REJECTED),
  data: FleetVehicleActionedSnsDataSchema,
});

const FleetVehicleApprovedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.FLEET_VEHICLE_APPROVED),
  data: FleetVehicleActionedSnsDataSchema,
});

const RiderRejectedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.RIDER_REJECTED),
  data: RiderActionedSnsDataSchema,
});

const RiderApprovedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.RIDER_APPROVED),
  data: RiderActionedSnsDataSchema,
});

const GigJobCreatedSnsMessageSchema = CommonSnsSchema.extend({
  type: z.literal(EventTypeSchema.Values.GIG_JOB_CREATED),
  data: GigJobCreatedActionedSnsSchema,
});

export const SnsEventMessageSchema = z.discriminatedUnion('type', [
  OrderCreatedSnsMessageSchema,
  ScheduleUpdatedSnsMessageSchema,
  OrderConfirmedSnsMessageSchema,
  OrderCancelledSnsMessageSchema,
  OrderRejectedSnsMessageSchema,
  OrderUpdatedSnsMessageSchema,
  OrderCompleteSnsMessageSchema,
  PickupEnrouteSnsMessageSchema,
  PickupArrivedSnsMessageSchema,
  PickupCompleteSnsMessageSchema,
  DropoffEnrouteSnsMessageSchema,
  DropoffArrivedSnsMessageSchema,
  DropoffCompleteSnsMessageSchema,
  DeliveryAssignedSnsMessageSchema,
  DeliveryUnassignedSnsMessageSchema,
  DeliveryCancelledSnsMessageSchema,
  DeliveryConfirmedSnsMessageSchema,
  DeliveryRejectedSnsMessageSchema,
  DeliveryCompleteSnsMessageSchema,
  FleetVehicleApprovedSnsMessageSchema,
  FleetVehicleRejectedSnsMessageSchema,
  RiderApprovedSnsMessageSchema,
  RiderRejectedSnsMessageSchema,
  GigJobCreatedSnsMessageSchema,
  DeliveryCancelledExternallySnsMessageSchema
]);

export const WebhookSnsEventMessageSchema = z.discriminatedUnion('type', [
  OrderCreatedSnsMessageSchema.extend({
    webhook: z.literal(true),
  }),
  OrderConfirmedSnsMessageSchema.extend({
    webhook: z.literal(true),
  }),
  OrderCancelledSnsMessageSchema.extend({
    webhook: z.literal(true),
  }),
  OrderRejectedSnsMessageSchema.extend({
    webhook: z.literal(true),
  }),
  OrderUpdatedSnsMessageSchema.extend({
    webhook: z.literal(true),
  }),
  PickupEnrouteSnsMessageSchema.extend({
    webhook: z.literal(true),
  }),
  PickupArrivedSnsMessageSchema.extend({
    webhook: z.literal(true),
  }),
  PickupCompleteSnsMessageSchema.extend({
    webhook: z.literal(true),
  }),
  DropoffEnrouteSnsMessageSchema.extend({
    webhook: z.literal(true),
  }),
  DropoffArrivedSnsMessageSchema.extend({
    webhook: z.literal(true),
  }),
  DropoffCompleteSnsMessageSchema.extend({
    webhook: z.literal(true),
  }),
]);

export const EtaSnsEventMessageSchema = z.discriminatedUnion('type', [
  OrderCreatedSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  PickupArrivedSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  PickupCompleteSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  DropoffArrivedSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  DropoffCompleteSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  ScheduleUpdatedSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  DeliveryAssignedSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  DeliveryUnassignedSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  DeliveryCancelledSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  DeliveryRejectedSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  DeliveryCompleteSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  OrderCancelledSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  OrderRejectedSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  OrderCompleteSnsMessageSchema.extend({
    eta: z.literal(true),
  }),
  DeliveryCancelledExternallySnsMessageSchema.extend({
    eta: z.literal(true),
  }),
]);

/**
 * Email Data
 * @description
 * This is the data that is used to generate emails
 */

/**
 * Shared Data Schemas
 */
const OrderActionEmailDataSchema = z.object({
  order: HydratedOrderSchema,
  adminEmail: z.string(),
  merchantEmails: z.array(z.string()).optional(),
});

const DeliveryActionEmailDataSchema = z.object({
  delivery: HydratedDeliverySchema,
  adminEmail: z.string(),
  merchantEmails: z.array(z.string()).optional(),
});

/**
 * Email Type Schemas
 */
const OrderCreatedEmailSchema = z.object({
  type: z.literal(EventTypeSchema.Values.ORDER_CREATED),
  data: OrderActionEmailDataSchema,
});

const OrderConfirmedEmailSchema = z.object({
  type: z.literal(EventTypeSchema.Values.ORDER_CONFIRMED),
  data: OrderActionEmailDataSchema,
});

const OrderCancelledEmailSchema = z.object({
  type: z.literal(EventTypeSchema.Values.ORDER_CANCELLED),
  data: OrderActionEmailDataSchema,
});

const DeliveryCancelledExternallyEmailSchema = z.object({
  type: z.literal(EventTypeSchema.Values.EXTERNAL_JOB_CANCELLED_EXTERNALLY),
  data: DeliveryActionEmailDataSchema,
});

const OrderRejectedEmailSchema = z.object({
  type: z.literal(EventTypeSchema.Values.ORDER_REJECTED),
  data: OrderActionEmailDataSchema,
});

const OrderUpdatedEmailSchema = z.object({
  type: z.literal(EventTypeSchema.Values.ORDER_UPDATED),
  data: OrderActionEmailDataSchema,
});

const DeliveryConfirmedEmailSchema = z.object({
  type: z.literal(EventTypeSchema.Values.DELIVERY_CONFIRMED),
  data: DeliveryActionEmailDataSchema,
});

const DeliveryCancelledEmailSchema = z.object({
  type: z.literal(EventTypeSchema.Values.DELIVERY_CANCELLED),
  data: DeliveryActionEmailDataSchema,
});

const DeliveryRejectedEmailSchema = z.object({
  type: z.literal(EventTypeSchema.Values.DELIVERY_REJECTED),
  data: DeliveryActionEmailDataSchema,
});

/**
 * Without this type defined explicity, TypeScript will complain about:
 * "The inferred type of this node exceeds the maximum length the compiler will serialize. An explicit type annotation is needed."
 */
type EmailInputSchemaType = z.ZodDiscriminatedUnion<'type', [
  typeof OrderCreatedEmailSchema,
  typeof OrderConfirmedEmailSchema,
  typeof OrderCancelledEmailSchema,
  typeof DeliveryCancelledExternallyEmailSchema,
  typeof OrderRejectedEmailSchema,
  typeof OrderUpdatedEmailSchema,
  typeof DeliveryConfirmedEmailSchema,
  typeof DeliveryCancelledEmailSchema,
  typeof DeliveryRejectedEmailSchema,
]>;
    
export const EmailInputSchema: EmailInputSchemaType = z.discriminatedUnion('type', [
  OrderCreatedEmailSchema,
  OrderConfirmedEmailSchema,
  OrderCancelledEmailSchema,
  DeliveryCancelledExternallyEmailSchema,
  OrderRejectedEmailSchema,
  OrderUpdatedEmailSchema,
  DeliveryConfirmedEmailSchema,
  DeliveryCancelledEmailSchema,
  DeliveryRejectedEmailSchema,
]);

/**
 * Push Notification Data
 * @description
 * This is the data that is used to generate push notifications
 */
const OrderCancelledNotificationDataSchema = z.object({
  riderId: z.string(),
  riderPushToken: z.string(),
  pickUpPostcode: z.string(),
});

const DeliveryCancelledNotificationDataSchema = z.object({
  orderId: z.string(),
  deliveryId: z.string(),
  riderId: z.string(),
  riderPushToken: z.string(),
  pickUpPostcode: z.string(),
});

const DeliveryAssignedNotificationDataSchema = z.object({
  deliveryId: z.string(),
  pickUpPostcode: z.string(),
  deliveryPostcode: z.string(),
  riderId: z.string(),
  riderPushToken: z.string(),
});

const DeliveryUnassignedNotificationDataSchema =
  DeliveryAssignedNotificationDataSchema;

const ScheduleUpdatedNotificationDataSchema = z.object({
  riderId: z.string(),
  nextStopPostcode: z.string(),
  riderPushToken: z.string(),
});

const FleetVehicleActionedNotificationDataSchema = z.object({
  riderId: z.string(),
  regNumber: z.string(),
  riderPushToken: z.string(),
});

const RiderActionedNotificationDataSchema = z.object({
  riderId: z.string(),
  riderPushToken: z.string(),
});
const GigJobCreatedActionedNotificationDataSchema = z.object({
  gigJobId: z.string(),
  riderPushToken: z.string(),
  riderId: z.string(),
  postCodes: z.string(),
});

export const PushNotificationInputSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(EventTypeSchema.Values.SCHEDULE_UPDATED),
    data: ScheduleUpdatedNotificationDataSchema,
  }),
  z.object({
    type: z.literal(EventTypeSchema.Values.ORDER_CANCELLED),
    data: z.array(OrderCancelledNotificationDataSchema),
  }),
  z.object({
    type: z.literal(EventTypeSchema.Values.ORDER_REJECTED),
    data: z.array(OrderCancelledNotificationDataSchema),
  }),
  z.object({
    type: z.literal(EventTypeSchema.Values.DELIVERY_REJECTED),
    data: DeliveryCancelledNotificationDataSchema,
  }),
  z.object({
    type: z.literal(EventTypeSchema.Values.DELIVERY_CANCELLED),
    data: DeliveryCancelledNotificationDataSchema,
  }),
  z.object({
    type: z.literal(EventTypeSchema.Values.DELIVERY_ASSIGNED),
    data: DeliveryAssignedNotificationDataSchema,
  }),
  z.object({
    type: z.literal(EventTypeSchema.Values.DELIVERY_UNASSIGNED),
    data: DeliveryUnassignedNotificationDataSchema,
  }),
  z.object({
    type: z.literal(EventTypeSchema.Values.FLEET_VEHICLE_APPROVED),
    data: FleetVehicleActionedNotificationDataSchema,
  }),
  z.object({
    type: z.literal(EventTypeSchema.Values.FLEET_VEHICLE_REJECTED),
    data: FleetVehicleActionedNotificationDataSchema,
  }),
  z.object({
    type: z.literal(EventTypeSchema.Values.RIDER_APPROVED),
    data: RiderActionedNotificationDataSchema,
  }),
  z.object({
    type: z.literal(EventTypeSchema.Values.RIDER_REJECTED),
    data: RiderActionedNotificationDataSchema,
  }),
  z.object({
    type: z.literal(EventTypeSchema.Values.GIG_JOB_CREATED),
    data: z.array(GigJobCreatedActionedNotificationDataSchema),
  }),
]);

/**
 * SMS Notification Data
 * @description
 * This is the data that is used to generate SMS notifications
 */

const SendTrackingLinkNotificationDataSchema = z.object({
  deliveryId: z.string(),
  dropOff: DropoffDeliveryStopSchema,
});

export const SmsNotificationInputSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(EventTypeSchema.Values.PICKUP_COMPLETE),
    data: SendTrackingLinkNotificationDataSchema,
  }),
]);

/**
 * Webhook Data
 * @description
 * This is the data that is sent to the webhook url
 */
const OrderActionedWebhookDataSchema = z.object({
  apiClientId: z.string(),
  publicDelivery: PublicDeliverySchema,
});

const DeliveryStopActionedWebhookDataSchema = z.object({
  apiClientId: z.string(),
  publicDelivery: PublicDeliverySchema,
});

const OrderActionedBodyDataSchema = PublicDeliverySchema;

const DeliveryStopActionedBodyDataSchema = PublicDeliverySchema;

export const OrderCreatedWebhookPostBodySchema = z.object({
  type: z.literal(WebhookEventTypeSchema.Values.ORDER_CREATED),
  data: OrderActionedBodyDataSchema,
});

export const OrderConfirmedWebhookPostBodySchema = z.object({
  type: z.literal(WebhookEventTypeSchema.Values.ORDER_CONFIRMED),
  data: OrderActionedBodyDataSchema,
});

export const OrderCancelledPostBodySchema = z.object({
  type: z.literal(WebhookEventTypeSchema.Values.ORDER_CANCELLED),
  data: OrderActionedBodyDataSchema,
});

export const OrderRejectedPostBodySchema = z.object({
  type: z.literal(WebhookEventTypeSchema.Values.ORDER_REJECTED),
  data: OrderActionedBodyDataSchema,
});

export const OrderUpdatedPostBodySchema = z.object({
  type: z.literal(WebhookEventTypeSchema.Values.ORDER_UPDATED),
  data: OrderActionedBodyDataSchema,
});

export const PickupEnroutePostBodySchema = z.object({
  type: z.literal(WebhookEventTypeSchema.Values.PICKUP_ENROUTE),
  data: DeliveryStopActionedBodyDataSchema,
});

export const PickupArrivedPostBodySchema = z.object({
  type: z.literal(WebhookEventTypeSchema.Values.PICKUP_ARRIVED),
  data: DeliveryStopActionedBodyDataSchema,
});

export const PickupCompletePostBodySchema = z.object({
  type: z.literal(WebhookEventTypeSchema.Values.PICKUP_COMPLETE),
  data: DeliveryStopActionedBodyDataSchema,
});

export const DropoffEnroutePostBodySchema = z.object({
  type: z.literal(WebhookEventTypeSchema.Values.DROPOFF_ENROUTE),
  data: DeliveryStopActionedBodyDataSchema,
});

export const DropoffArrivedPostBodySchema = z.object({
  type: z.literal(WebhookEventTypeSchema.Values.DROPOFF_ARRIVED),
  data: DeliveryStopActionedBodyDataSchema,
});

export const DropoffCompletePostBodySchema = z.object({
  type: z.literal(WebhookEventTypeSchema.Values.DROPOFF_COMPLETE),
  data: DeliveryStopActionedBodyDataSchema,
});

export const WebhookPostBodySchema = z.discriminatedUnion('type', [
  OrderCreatedWebhookPostBodySchema,
  OrderConfirmedWebhookPostBodySchema,
  OrderCancelledPostBodySchema,
  OrderRejectedPostBodySchema,
  OrderUpdatedPostBodySchema,
  PickupEnroutePostBodySchema,
  PickupArrivedPostBodySchema,
  PickupCompletePostBodySchema,
  DropoffEnroutePostBodySchema,
  DropoffArrivedPostBodySchema,
  DropoffCompletePostBodySchema,
]);

/**
 * SNS Types
 */
export type EventType = z.infer<typeof EventTypeSchema>;
export type WebhookEventType = z.infer<typeof WebhookEventTypeSchema>;
export type WebhookConfig = z.infer<typeof WebhookConfigSchema>;

export type OrderActionedSns = z.infer<typeof OrderActionedSnsSchema>;
export type GigJobCreatedActionedSns = z.infer<typeof GigJobCreatedActionedSnsSchema>;
export type DeliveryActionedSns = z.infer<typeof DeliveryActionedSnsSchema>;
export type DeliveryAssignedSns = z.infer<typeof DeliveryAssignedSnsDataSchema>;

export type ScheduleUpdatedSns = z.infer<typeof ScheduleUpdatedSnsDataSchema>;

export type SnsEventMessage = z.infer<typeof SnsEventMessageSchema>;
export type WebhookSnsEventMessage = z.infer<
  typeof WebhookSnsEventMessageSchema
>;
export type EtaSnsEventMessage = z.infer<typeof EtaSnsEventMessageSchema>;

export type FleetVehicleActionedSns = z.infer<
  typeof FleetVehicleActionedSnsDataSchema
>;
export type RiderActionedSns = z.infer<typeof RiderActionedSnsDataSchema>;

/**
 * Email Types
 */
export type OrderActionedEmailData = z.infer<typeof OrderActionEmailDataSchema>;

export type DeliveryActionedEmailData = z.infer<
  typeof DeliveryActionEmailDataSchema
>;

// collection of all email types
export type EmailInput = z.infer<typeof EmailInputSchema>;

/**
 * Push Notification Types
 */
export type ScheduleUpdatedNotificationData = z.infer<
  typeof ScheduleUpdatedNotificationDataSchema
>;
export type OrderCancelledNotificationData = z.infer<
  typeof OrderCancelledNotificationDataSchema
>;
export type GigJobCreatedNotificationData = z.infer<
  typeof GigJobCreatedActionedNotificationDataSchema
>;

export type DeliveryCancelledNotificationData = z.infer<
  typeof DeliveryCancelledNotificationDataSchema
>;
export type DeliveryAssignedNotificationData = z.infer<
  typeof DeliveryAssignedNotificationDataSchema
>;

export type FleetVehicleActionedNotificationData = z.infer<
  typeof FleetVehicleActionedNotificationDataSchema
>;

export type RiderActionedNotificationData = z.infer<
  typeof RiderActionedNotificationDataSchema
>;

// collection of all notification types
export type PushNotificationInput = z.infer<typeof PushNotificationInputSchema>;

/**
 * SMS Notification Types
 * @description
 * This is the data that is used to generate SMS notifications
 */

export type SendTrackingLinkNotificationData = z.infer<
  typeof SendTrackingLinkNotificationDataSchema
>;

// collection of all sms types
export type SmsNotificationInput = z.infer<typeof SmsNotificationInputSchema>;

/**
 * Webhook Types
 */
export type OrderActionedWebhookData = z.infer<
  typeof OrderActionedWebhookDataSchema
>;
export type DeliveryStopActionedWebhookData = z.infer<
  typeof DeliveryStopActionedWebhookDataSchema
>;

export type WebhookPostBody = z.infer<typeof WebhookPostBodySchema>;

export type DeliveryStopActionedSns = z.infer<
  typeof DeliveryStopActionedSnsSchema
>;
