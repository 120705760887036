import { Box, Flex, Text, VStack, Stack } from '@chakra-ui/react';
import { SiteContact } from '@tradeaze-packages/schemas';
import { brandSecondaryColor } from '../theme/colors';
import { ButtonWithConfirmation } from '../shared';

export const SiteContactCard = ({
  siteContact,
  onClick,
  onDelete,
  isDeleting,
}: {
  siteContact: SiteContact;
  onClick?: (siteContact: SiteContact) => void;
  onDelete?: () => void;
  isDeleting?: boolean;
}) => {
  const handleCardClick = () => {
    onClick && onClick(siteContact);
  };

  return (
    <Box
      w="100%"
      h="100%"
      p={4}
      borderWidth="1px"
      borderRadius="lg"
      borderColor={brandSecondaryColor}
      onClick={handleCardClick}
      marginBottom={2}
    >
      <Flex direction="column" h="100%">
        <Stack direction={['column', 'row']} justifyContent="space-between">
          <VStack spacing={1} align="flex-start">
            <Text fontSize="m" color="black.500" data-cy="site-contact-name">
              {siteContact.contactName}
            </Text>
            <Text fontSize="m" color="black.500" data-cy="site-contact-number">
              {siteContact.contactNumber}
            </Text>
          </VStack>
          {onDelete && (
            <ButtonWithConfirmation
              alertTitle="Delete Site Contact"
              alertDescription="Are you sure you want to delete this site contact?"
              onConfirm={onDelete}
              colorScheme="red"
              size="xs"
              variant="outline"
              onClick={onDelete}
              isLoading={isDeleting}
              marginLeft={2}
              data-cy="delete-site-contact"
            >
              Delete
            </ButtonWithConfirmation>
          )}
        </Stack>
      </Flex>
    </Box>
  );
};
