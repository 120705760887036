import { z } from 'zod';
import {
  HydratedRiderDocumentSchema,
  RiderDocumentTypeIdSchema,
} from '../../../models';

export const GetRiderDocumentsQuerySchema = z.object({
  documentTypeId: RiderDocumentTypeIdSchema.optional(),
  includeUrls: z.preprocess((val) => val === 'true', z.boolean().optional()),
});

export const GetRiderDocumentsResponseSchema = z.object({
  documents: HydratedRiderDocumentSchema.array(),
  isComplete: z.boolean(),
  missingDocumentIds: RiderDocumentTypeIdSchema.array(),
});

export type GetRiderDocumentsQuery = z.infer<typeof GetRiderDocumentsQuerySchema>;

export type GetRiderDocumentsResponse = z.infer<
  typeof GetRiderDocumentsResponseSchema
>;
