import { useForm } from 'react-hook-form';
import {
  Stack,
  FormControl,
  Input,
  Button,
  FormErrorMessage,
} from '@chakra-ui/react';
import {
  Company,
  MerchantDefinedCompany,
  MerchantDefinedCompanySchema,
} from '@tradeaze-packages/schemas';

import { zodResolver } from '@hookform/resolvers/zod';
import { FormSection } from '../FormSection';
import { useCreateMerchantCompany } from '@tradeaze/frontend/hooks';
import { GrUserWorker } from 'react-icons/gr';
import { toast } from 'react-hot-toast';

export interface MerchantCustomerFormProps {
  company?: Company;
  merchantId: string;
  onSubmit: () => void;
}

export const MerchantCustomerForm = ({
  company,
  merchantId,
  onSubmit,
}: MerchantCustomerFormProps) => {
  const methods = useForm<MerchantDefinedCompany>({
    defaultValues: company ?? {
      merchantId,
    },
    mode: 'all',
    resolver: zodResolver(MerchantDefinedCompanySchema),
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = methods;

  const { mutate: createMerchantCompany, isLoading } = useCreateMerchantCompany(
    {
      onSuccess: () => {
        onSubmit();
      },
      onError: () => {
        toast.error('Error creating company');
      },
    }
  );

  const handleFormSubmit = handleSubmit(
    (data) => {
      createMerchantCompany(data);
    },
    () => {
      toast.error('Invalid form');
    }
  );

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleFormSubmit();
      }}
    >
      <Stack spacing={4}>
        <FormSection name="New Company" icon={<GrUserWorker />}>
          <Stack spacing={4}>
            <FormControl isInvalid={Boolean(errors.companyName)}>
              <Input
                id="companyName"
                placeholder="Name"
                {...register('companyName')}
              />
              <FormErrorMessage data-cy="company-name-error">
                {errors.companyName?.message}
              </FormErrorMessage>
            </FormControl>
          </Stack>
        </FormSection>
        <Button
          isLoading={isLoading}
          onClick={handleFormSubmit}
          isDisabled={!isValid}
          data-cy="save-customer"
        >
          Save
        </Button>
      </Stack>
    </form>
  );
};
