import { Position } from '@tradeaze-packages/schemas';

import React, { useCallback, useState } from 'react';
import { PickupMarker } from '../PickupMarker';
import { MapMarkerProps } from '../Map';
import { DropOffMarker } from '../DropOffMarker';
import { MerchantMarker } from '../MerchantMarker';

export type AddMarkerFn = (args: {
  id: string;
  position: Position;
  stopSequence?: number;
}) => void;

export type RemoveMarkerFn = (id: string) => void;

export const useFormMapMarkers = (defaultMarkers?: MapMarkerProps[] | null) => {
  const [mapMarkers, setMapMarkers] = useState<MapMarkerProps[]>(
    defaultMarkers || [],
  );

  const addPickupMarker = useCallback<AddMarkerFn>(
    ({ id, position, stopSequence }) => {
      setMapMarkers((prev) => [
        ...prev.filter((marker) => marker.id !== id),
        {
          id,
          type: 'PICK_UP',
          position,
          children: <PickupMarker />,
          updateZoom: true,
        },
      ]);
    },
    [],
  );

  const addDeliveryMarker = useCallback<AddMarkerFn>(
    ({ id, position, stopSequence }) => {
      setMapMarkers((prev) => [
        ...prev.filter((marker) => marker.id !== id),
        {
          id,
          type: 'DROP_OFF',
          position,
          children: <DropOffMarker />,
          updateZoom: true,
        },
      ]);
    },
    [],
  );

  const addMerchantMarker = useCallback<AddMarkerFn>(({ id, position }) => {
    setMapMarkers((prev) => [
      ...prev.filter((marker) => marker.id !== id),
      {
        id,
        type: 'MERCHANT',
        position,
        children: <MerchantMarker name="Your location" />,
        anchor: 'center',
        updateZoom: true,
      },
    ]);
  }, []);

  const removeMarkerByType = useCallback(
    (id: string, type: 'PICK_UP' | 'DROP_OFF') => {
      setMapMarkers((prev) => {
        const markerExists = prev.some(
          (marker) => marker.id === id && marker.type === type,
        );

        if (!markerExists) {
          return prev;
        }

        return prev
          .filter((marker) => !(marker.id === id && marker.type === type))
          .map((marker) => marker);
      });
    },
    [],
  );

  const removePickupMarker = useCallback<RemoveMarkerFn>(
    (id) => removeMarkerByType(id, 'PICK_UP'),
    [removeMarkerByType],
  );

  const removeDeliveryMarker = useCallback<RemoveMarkerFn>(
    (id) => removeMarkerByType(id, 'DROP_OFF'),
    [removeMarkerByType],
  );

  const removeMerchantMarker = useCallback<RemoveMarkerFn>((id) => {
    setMapMarkers((prev) => prev.filter((marker) => marker.id !== id));
  }, []);

  const resetMapMarkers = useCallback(() => {
    setMapMarkers([]);
  }, []);

  return {
    mapMarkers,
    addPickupMarker,
    addDeliveryMarker,
    addMerchantMarker,
    removePickupMarker,
    removeDeliveryMarker,
    removeMerchantMarker,
    resetMapMarkers,
  };
};
