import { z } from 'zod';

export const GigJobDeliverySchema = z.object({
  gigJobId: z.string(),
  deliveryId: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const CreateGigJobDeliverySchema = GigJobDeliverySchema.extend({
  createdAt: z.string().optional(),
  updatedAt: z.string().optional(),
});

/**
 * Types
 */
export type GigJobDelivery = z.infer<typeof GigJobDeliverySchema>;
export type CreateGigJobDelivery = z.infer<typeof CreateGigJobDeliverySchema>;
