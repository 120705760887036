import React from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  HStack,
  Tag,
  TagLabel,
  Text,
} from '@chakra-ui/react';
import { CreateDeliveryItem } from '@tradeaze-packages/schemas';

type OrderStopCardProps = {
  title: string;
  pickupReference: string;
  address: string;
  companyName?: string | null;
  itemSize: CreateDeliveryItem[];
  onClick?: () => void;
  onRemove?: () => void;
  isCancelled?: boolean;
  isDelivered?: boolean;
  isAdmin?: boolean;
  restrictedEditing?: boolean;
};

export const OrderStopCard = React.forwardRef<
  HTMLDivElement,
  OrderStopCardProps
>(
  (
    {
      title,
      pickupReference,
      address,
      companyName,
      itemSize,
      onClick,
      onRemove,
      isCancelled,
      isDelivered,
      isAdmin,
      restrictedEditing,
    },
    ref,
  ) => {
    const itemDescription =
      itemSize.length > 0
        ? `${itemSize[0].weight}kg L ${itemSize[0].length}${itemSize[0].lengthUnit} x W ${itemSize[0].width}${itemSize[0].widthUnit} x H ${itemSize[0].height}${itemSize[0].heightUnit}`
        : 'No item size available';

    const isCancelledOrDelivered = isCancelled || isDelivered;

    return (
      <Box
        ref={ref}
        bg={isCancelledOrDelivered ? '#F9F9F9' : 'white'}
        borderRadius="8px"
        border={
          isCancelledOrDelivered ? '1px solid #EEEEEF' : '1px solid #D5D5D5'
        }
        py="16px"
        px="16px"
        w="100%"
        h="100%"
        data-cy={`order-stop-card-${title}`}
        tabIndex={0}
        position={'relative'}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={4}
          onClick={isCancelledOrDelivered ? undefined : onClick}
          cursor={isCancelledOrDelivered ? 'default' : 'pointer'}
        >
          <HStack justifyContent={'space-between'}>
            <Box display={'flex'} flexDirection={'row'} gap={6}>
              <Text fontSize="lg" fontWeight="600" data-cy={'stop-title'}>
                {title}
              </Text>
              {isCancelledOrDelivered && (
                <Tag
                  size="sm"
                  colorScheme={isCancelled ? 'red' : 'green'}
                  alignItems="center"
                  opacity={1}
                >
                  <TagLabel>{isCancelled ? 'Cancelled' : 'Delivered'}</TagLabel>
                </Tag>
              )}
            </Box>
            {!isCancelledOrDelivered && (
              <ChevronRightIcon boxSize={6} cursor="pointer" />
            )}
          </HStack>
          <Text fontSize="md">
            <span style={{ fontWeight: 'bold' }} data-cy={'stop-address'}>
              Address:
            </span>{' '}
            {address}
          </Text>
          <Box>
            <Text fontSize="md">
              <span style={{ fontWeight: 'bold' }} data-cy={'company-name'}>
                Company name:
              </span>{' '}
              {companyName}
            </Text>
            <Text fontSize="md">
              <span style={{ fontWeight: 'bold' }} data-cy={'stop-reference'}>
                Pickup reference:
              </span>{' '}
              {pickupReference}
            </Text>
          </Box>
          <Divider />
          <Text fontSize="md">
            <span style={{ fontWeight: 'bold' }} data-cy={'stop-items'}>
              Items:
            </span>{' '}
            {itemDescription}
          </Text>
        </Box>
        {(isAdmin || (!restrictedEditing && !isCancelledOrDelivered)) && (
          <Button
            position={'absolute'}
            right={12}
            top={-4}
            variant={'ghost'}
            colorScheme="red"
            size={'sm'}
            bg={'white'}
            onClick={onRemove}
            data-cy={'remove-stop-card-button'}
          >
            Remove
          </Button>
        )}
      </Box>
    );
  },
);
