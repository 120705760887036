import { DeliveryVehicle } from '@tradeaze-packages/schemas';
import {
  Box,
  Button,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ModalVehicleIcon } from './ModalVehicleIcon';
import { NoVehicleIcon } from '../../assets/itemSizingIcons';
import React from 'react';
import { unitConverter } from '../utils';
import { MeasurementUnit } from '../orderConstants';
import { NAVBAR_WIDTH } from '../../navbar';

type VehicleModalProps = {
  isVehicleModalOpen: boolean;
  setIsVehicleModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedVehicle: DeliveryVehicle | undefined;
  loadDimensions: (sideView: boolean) => {
    xArea: number;
    yArea: number;
    xPosition: string;
    yPosition: string;
  } | null;
  isPrimaryView: boolean;
  setIsPrimaryView: React.Dispatch<React.SetStateAction<boolean>>;
  isVehicleAvailable: boolean;
  measurementUnit: MeasurementUnit;
};

export const VehicleModal = ({
  isVehicleModalOpen,
  setIsVehicleModalOpen,
  selectedVehicle,
  loadDimensions,
  isPrimaryView,
  setIsPrimaryView,
  isVehicleAvailable,
  measurementUnit,
}: VehicleModalProps) => {
  const height = selectedVehicle?.heightCapacity
    ? `H ${unitConverter(
        selectedVehicle.heightCapacity,
        'm',
        measurementUnit,
      )}${measurementUnit}`
    : 'N/A';

  const length = selectedVehicle?.lengthCapacity
    ? `L ${unitConverter(
        selectedVehicle.lengthCapacity,
        'm',
        measurementUnit,
      )}${measurementUnit}`
    : 'N/A';

  const width = selectedVehicle?.widthCapacity
    ? `W ${unitConverter(
        selectedVehicle.widthCapacity,
        'm',
        measurementUnit,
      )}${measurementUnit}`
    : 'N/A';

  return (
    <Modal
      isOpen={isVehicleModalOpen}
      onClose={() => setIsVehicleModalOpen(false)}
      blockScrollOnMount={false}
      closeOnOverlayClick={false}
      useInert={false}
      trapFocus={false}
      variant="customVariant"
    >
      <ModalOverlay
        position="fixed"
        top={0}
        left={`calc(50% + ${NAVBAR_WIDTH / 2}px)`}
        width={`calc(50% - ${NAVBAR_WIDTH / 2}px)`}
      />
      <ModalContent
        borderRadius={16}
        marginRight={0}
        ml="auto"
        mr="auto"
        w="36vw"
        maxWidth="none"
      >
        <ModalCloseButton />
        {selectedVehicle ? (
          <>
            <ModalHeader>
              <Button
                mr={3}
                variant={isPrimaryView ? 'solid' : 'ghost'}
                onClick={() => setIsPrimaryView(!isPrimaryView)}
                colorScheme={'brandSecondary'}
              >
                Side view
              </Button>
              <Button
                mr={3}
                variant={isPrimaryView ? 'ghost' : 'solid'}
                onClick={() => setIsPrimaryView(!isPrimaryView)}
                colorScheme={'brandSecondary'}
              >
                Rear view
              </Button>
            </ModalHeader>
            <ModalBody display={'flex'} justifyContent={'center'} padding={18}>
              <ModalVehicleIcon
                isPrimary={isPrimaryView}
                vehicleID={selectedVehicle.deliveryVehicleId}
                loadDimensions={loadDimensions}
              />
            </ModalBody>
            <ModalFooter
              backgroundColor="#FEFAED"
              borderRadius="0 0 16px 16px"
              justifyContent={'center'}
            >
              <Box>
                <Text fontWeight={'bold'}>{selectedVehicle.name}</Text>
                <HStack gap={4}>
                  <Text>Max. Weight {selectedVehicle.weightCapacity}kg</Text>
                  <Text>
                    Max. {length} x {width} x {height}
                  </Text>
                </HStack>
                <Divider my={2} color={'blackAlpha.500'} />
                <Text>{selectedVehicle.description}</Text>
              </Box>
            </ModalFooter>
          </>
        ) : !isVehicleAvailable ? (
          <ModalBody padding={16}>
            <VStack spacing={4}>
              <NoVehicleIcon width={300} height={300} />
              <Text fontSize={18}>
                No vehicles can take this combination of dimensions and weight.
              </Text>
            </VStack>
          </ModalBody>
        ) : null}
      </ModalContent>
    </Modal>
  );
};
