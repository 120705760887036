import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/react';
import { OrderStatusTag } from '../order';
import {
  DeliveryOptionId,
  DeliveryStatus,
  OrderStatus,
} from '@tradeaze-packages/schemas';
import { DeliveryStatusTag } from '../delivery';
import { formatDate, formatDeliveryOption } from '@tradeaze/shared/utils';

export const Attribute: React.FC<
  React.ComponentProps<typeof Box> & {
    name: string;
    description?: string;
    children: React.ReactNode;
  }
> = ({ name, description, children, ...props }) => {
  return (
    <Box mb={6} {...props}>
      <Flex align={'center'}>
        <Heading size={'sm'}>{name}</Heading>
        {description && (
          <Text fontSize={14} color={'grey'} ml={2}>
            {description}
          </Text>
        )}
      </Flex>
      <Box mt={2}>{children}</Box>
    </Box>
  );
};

export const PriceAttribute: React.FC<{
  name: string;
  excludingVat: boolean;
  children: React.ReactNode;
}> = ({ name, excludingVat, children }) => {
  return (
    <Stack justify={'space-between'} direction={['column', 'row']}>
      <Text fontWeight={'bold'}>
        {name}{' '}
        <Text fontSize={14} color={'grey'}>
          {excludingVat ? '(exc. VAT)' : '(incl. VAT)'}
        </Text>
      </Text>
      <Box>{children}</Box>
    </Stack>
  );
};

export const TitleValue: React.FC<{
  text: string;
}> = ({ text }) => (
  <Text as="span" fontWeight="bold" mb={6} mt={4}>
    {text}
  </Text>
);

export const TextValue: React.FC<{
  text?: string | null;
}> = ({ text }) => (
  <Text opacity={text ? 1 : 0.4} whiteSpace={'pre-wrap'}>
    {text || 'None'}
  </Text>
);

export const PriceValue: React.FC<{
  price?: number | null;
}> = ({ price }) => {
  return (
    <Text opacity={price ? 1 : 0.4}>
      {price ? `£${Number(price).toFixed(2)}` : 'None'}
    </Text>
  );
};

export const DeliveryOptionValue: React.FC<{
  deliveryOption?: DeliveryOptionId | null;
}> = ({ deliveryOption }) => {
  return (
    <Text opacity={deliveryOption ? 1 : 0.4}>
      {deliveryOption ? formatDeliveryOption(deliveryOption) : 'None'}
    </Text>
  );
};

export const DateTimeValue: React.FC<{
  dateTime?: string | null;
}> = ({ dateTime }) => {
  return (
    <Text opacity={dateTime ? 1 : 0.4}>
      {dateTime ? formatDate(new Date(dateTime)) : 'None'}
    </Text>
  );
};

export const OrderStatusValue: React.FC<{
  orderStatus: OrderStatus;
}> = ({ orderStatus }) => {
  return <OrderStatusTag status={orderStatus} />;
};

export const DeliveryStatusValue: React.FC<{
  deliveryStatus: DeliveryStatus;
}> = ({ deliveryStatus }) => {
  return <DeliveryStatusTag status={deliveryStatus} />;
};
