import {
  Box,
  Grid,
  GridItem,
  Heading,
  Text,
  Image,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { brandPrimaryColor, brandSecondaryColor, Layout } from '@tradeaze/frontend/ui-web';
import ConstructionTruck from './Construction-truck-pana.svg';
import BookingsTruck from './Construction-truck-rafiki.svg';
import LogisticsVan from './Logistics-rafiki.svg';
import ConstructionCrane from './Construction-crane-rafiki.svg';
import { useGetMerchantAccount } from '@tradeaze/frontend/hooks';
import { useAuthContext } from '../components/auth/AuthProvider';

interface DashboardTileProps {
  title: string | React.ReactNode;
  description: string;
  to: string;
  children?: React.ReactNode;
}

const DashboardTile = ({
  title,
  description,
  to,
  children,
}: DashboardTileProps) => {
  return (
    <Box
      as={NavLink}
      to={to}
      bg="white"
      p={8}
      borderRadius="xl"
      borderWidth="2px"
      borderColor="gray.200"
      cursor="pointer"
      transition="all 0.2s cubic-bezier(0.4, 0, 0.2, 1)"
      position="relative"
      role="group"
      display="block"
      _hover={{
        transform: 'translateY(-2px)',
        boxShadow: 'lg',
        textDecoration: 'none',
      }}
      _before={{
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        borderRadius: 'xl',
        background: 'linear-gradient(145deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%)',
        opacity: 0,
        transition: 'opacity 0.2s ease-in-out',
      }}
      _groupHover={{
        _before: {
          opacity: 1,
        },
      }}
      height="100%"
    >
      <Heading size="lg" mb={3} color="gray.800">
        {title}
      </Heading>
      <Text fontSize="md" color="gray.500" mb={6}>
        {description}
      </Text>
      {children}
    </Box>
  );
};

const getTimeBasedGreeting = () => {
  const hour = new Date().getHours();

  if (hour < 12) {
    return 'Good Morning';
  } else if (hour < 17) {
    return 'Good Afternoon';
  } else {
    return 'Good Evening';
  }
};

export const HomePage = () => {
  const auth = useAuthContext();
  const merchantQuery = useGetMerchantAccount(auth.cognitoUserId);
  const greeting = getTimeBasedGreeting();

  return (
    <Layout
      isAuthScreen={false}
      lowPadding
    >
      <Box
        display="flex"
        alignItems="center"
        py={{ base: 4, md: 8 }}
        px={{ base: 4, md: 8 }}
        minH="calc(100vh - 64px)"
      >
        <Box maxW="1400px" mx="auto" w="100%">
          <Box textAlign={{ base: 'center', md: 'left' }} mb={8}>
            <Heading size="2xl" color={brandSecondaryColor} data-cy="greeting">
              {greeting}!
            </Heading>
            <Heading size="lg" color={brandPrimaryColor} mt={2} data-cy="merchant-name">
              {merchantQuery?.data?.merchantName}
            </Heading>
          </Box>

          <Grid
            templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
            gap={{ base: 4, md: 6 }}
            w="100%"
          >
            {/* Top Row */}
            <GridItem h="100%">
              <DashboardTile
                title="Orders"
                description="View and manage all your past and current delivery bookings"
                to="/orders"
                data-cy="home-page-orders-button"
              >
                <Box position="relative" height="200px" mt={4}>
                  <Image
                    src={BookingsTruck}
                    alt="Bookings illustration"
                    position="absolute"
                    bottom="0"
                    right="0"
                    height="100%"
                    maxWidth="70%"
                    objectFit="contain"
                  />
                </Box>
              </DashboardTile>
            </GridItem>

            <GridItem h="100%">
              <DashboardTile
                title="Create an Order"
                description="Create a new delivery order for your customers. A to B, Multi-Collection, or Multi-Drop."
                to="/create-order"
                data-cy="home-page-create-order-button"
              >
                <Box position="relative" height="160px" mt={4}>
                  <Image
                    src={ConstructionTruck}
                    alt="Construction truck illustration"
                    position="absolute"
                    bottom="0"
                    right="0"
                    height="100%"
                    maxWidth="60%"
                    objectFit="contain"
                  />
                </Box>
              </DashboardTile>
            </GridItem>

            {/* Bottom Row */}
            <GridItem h="100%">
              <DashboardTile
                title={
                  <Box display="flex" alignItems="center" gap={2}>
                    ManVan Enquiry
                    <Text
                      as="span"
                      fontSize="xs"
                      bg="blue.100"
                      color="blue.700"
                      px={2}
                      py={1}
                      borderRadius="full"
                      fontWeight="medium"
                    >
                      BETA
                    </Text>
                  </Box>
                }
                description="Rent a van and driver out on a day rate"
                to="/man-van-hire"
                data-cy="home-page-man-van-enquiry-button"
              >
                <Box position="relative" height="160px" mt={4}>
                  <Image
                    src={LogisticsVan}
                    alt="ManVan hire illustration"
                    position="absolute"
                    bottom="0"
                    right="0"
                    height="100%"
                    maxWidth="60%"
                    objectFit="contain"
                  />
                </Box>
              </DashboardTile>
            </GridItem>

            <GridItem h="100%">
              <DashboardTile
                title={
                  <Box display="flex" alignItems="center" gap={2}>
                    HIAB Enquiry
                    <Text
                      as="span"
                      fontSize="xs"
                      bg="blue.100"
                      color="blue.700"
                      px={2}
                      py={1}
                      borderRadius="full"
                      fontWeight="medium"
                    >
                      BETA
                    </Text>
                  </Box>
                }
                description="Request a truck with a mounted crane for heavy lifting"
                to="/hiab-request"
                data-cy="home-page-hiab-request-button"
              >
                <Box position="relative" height="160px" mt={4}>
                  <Image
                    src={ConstructionCrane}
                    alt="HIAB crane illustration"
                    position="absolute"
                    bottom="0"
                    right="0"
                    height="100%"
                    maxWidth="60%"
                    objectFit="contain"
                  />
                </Box>
              </DashboardTile>
            </GridItem>
          </Grid>
        </Box>
      </Box>
    </Layout>
  );
};
