import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Switch,
  SwitchProps,
} from '@chakra-ui/react';
import React from 'react';

interface FormSwitchProps extends Omit<SwitchProps, 'form'> {
  id: string;
  label?: string;
  error?: {
    message?: string;
  };
  description?: string;
}

export const FormSwitch = React.forwardRef<HTMLInputElement, FormSwitchProps>(
  ({ id, label, error, description, ...props }, ref) => {
    return (
      <FormControl isInvalid={Boolean(error)}>
        {label && (
          <FormLabel htmlFor={id} mb={2}>
            {label}
          </FormLabel>
        )}
        <Switch id={id} ref={ref} {...props} />
        {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
      </FormControl>
    );
  },
);

FormSwitch.displayName = 'FormSwitch';
