import {
  useGetHoveredDeliveryRiderLocation,
  useGetMerchantAccount,
  useGetMerchantOrders,
  useSelectedDeliveryState,
  useSelectedOrderState,
} from '@tradeaze/frontend/hooks';
import { useAuthContext } from '../components/auth/AuthProvider';
import {
  Layout,
  MobileMapWithToggle,
  TwoColumnLayout,
  useDashboardMapMarkers,
  useMarkerHover,
  useOrderQueryStore,
  Map,
  OrderDeliveriesContainer,
  DownloadMerchantCsv,
  OrderQueryOptionsDrawer,
  OrderQueryPage,
  MerchantOrderQueryOptionsBar,
} from '@tradeaze/frontend/ui-web';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Show, Stack } from '@chakra-ui/react';

export const OrderDashboardPage = () => {
  const navigate = useNavigate();
  const { cognitoUserId } = useAuthContext();

  const orderQueryStore = useOrderQueryStore();

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetMerchantOrders(cognitoUserId, {
    endDate: orderQueryStore.endDate,
    orderStatuses: orderQueryStore.orderStatuses,
    sortBy: orderQueryStore.sortBy,
    startDate: orderQueryStore.startDate,
    merchantOrderReference: orderQueryStore.merchantOrderReference,
    deliveryToday: orderQueryStore.deliveryToday,
    deliveryVehicles: orderQueryStore.deliveryVehicles,
    limit: 12,
  });

  const { data: merchantAccount } = useGetMerchantAccount(cognitoUserId);

  const { selectedDelivery, onSelectDelivery } = useSelectedDeliveryState();

  const { selectedOrder, onSelectOrder } = useSelectedOrderState();

  const orders = useMemo(
    () => data?.pages.flatMap((page) => page.orders) || [],
    [data],
  );

  const deliveries = useMemo(
    () =>
      orders.flatMap((order) =>
        order.deliveries.map((delivery) => ({ ...delivery, order })),
      ),
    [orders],
  );

  const markerHover = useMarkerHover();

  const hoveredRiderData = useGetHoveredDeliveryRiderLocation(
    deliveries,
    markerHover.hoveredDeliveryId,
  );

  const onPressGoToOrder = (orderId: string) => {
    navigate(`/orders/${orderId}`);
  };

  const { mapMarkers } = useDashboardMapMarkers({
    initialFilters: {
      MERCHANT: true,
      RIDER: true,
      DROP_OFF: true,
      PICK_UP: true,
      ADDRESS: false,
      DELIVERY: true,
    },
    isAdmin: false,
    merchants: merchantAccount ? [merchantAccount] : [],
    riders: hoveredRiderData.rider ? [hoveredRiderData.rider] : [],
    riderLocations: hoveredRiderData.riderLocations,
    deliveries,
    onClickDelivery: ({ orderId }) => navigate(`/orders/${orderId}`),
    ...markerHover,
  });

  const OrderGrid = (
    <Box paddingTop={[0, 0, 8]}>
      <OrderQueryPage
        groupByOrder={true}
        onPressGoTo={onPressGoToOrder}
        fetchNextPage={fetchNextPage}
        isFiltered={orderQueryStore.getActiveFilters() > 0}
        hasNextPage={hasNextPage}
        isAdmin={false}
        isError={isError}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
        orders={orders}
        deliveries={deliveries}
        columns={[1, 1, 1, 1, 2]}
        selectedDelivery={selectedDelivery}
        selectedOrder={selectedOrder}
        onSelectDelivery={onSelectDelivery}
        onSelectOrder={onSelectOrder}
        {...markerHover}
      />
    </Box>
  );

  return (
    <>
      {selectedOrder && (
        <OrderDeliveriesContainer
          initialDate={new Date()}
          selectedOrder={selectedOrder}
          merchants={merchantAccount ? [merchantAccount] : []}
          clockedInTodayRiders={
            hoveredRiderData.rider ? [hoveredRiderData.rider] : []
          }
          riderLocations={hoveredRiderData.riderLocations}
          isAdmin={false}
          onPressGoTo={onPressGoToOrder}
          selectedDelivery={selectedDelivery}
          onSelectDelivery={onSelectDelivery}
          onSelectOrder={onSelectOrder}
          onClickDelivery={({ orderId }) => navigate(`/orders/${orderId}`)}
        />
      )}
      <Show above="md">
        <TwoColumnLayout
          left={OrderGrid}
          right={<Map markers={mapMarkers} />}
          leftContainerProps={{
            paddingX: 6,
          }}
        />
        {merchantAccount ? (
          <MerchantOrderQueryOptionsBar
            orderQueryStore={orderQueryStore}
            merchantAccount={merchantAccount}
          />
        ) : null}
      </Show>
      <Show below="md">
        <Layout>
          {OrderGrid}
          <MobileMapWithToggle markers={mapMarkers} extraTopPadding={0} />
          <Stack
            position={'fixed'}
            left={0}
            bottom={0}
            direction={'row'}
            maxWidth={'100vw'}
            width={'100vw'}
            flexWrap={'wrap'}
            justifyContent={'left'}
            pb={3}
            pl={4}
            zIndex={2}
          >
            <OrderQueryOptionsDrawer
              orderQueryStore={orderQueryStore}
              isAdmin={false}
              buttonProps={{
                size: 'md',
              }}
            />
            <DownloadMerchantCsv
              orderQueryStore={orderQueryStore}
              merchantId={merchantAccount?.merchantId || ''}
            />
          </Stack>
        </Layout>
      </Show>
    </>
  );
};
