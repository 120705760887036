import { CheckCircleIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { Box, Tag, TagLabel } from '@chakra-ui/react';
import { ExternalJobStatus } from '@tradeaze-packages/schemas';
import { FaHourglassHalf, FaTruck, FaExclamationTriangle } from 'react-icons/fa';

interface Props {
  status?: ExternalJobStatus;
  inactive?: boolean;
}

export const ExternalJobStatusTag = ({ status, inactive }: Props) => {
  const tagProps = getTagProps(status);

  if (!tagProps) {
    return null;
  }

  const { colorScheme, bg, name, icon } = tagProps;

  return (
    <Tag
      size="md"
      colorScheme={colorScheme}
      bg={bg}
      alignItems="center"
      opacity={inactive ? 0.3 : 1}
    >
      <Box mr={1}>{icon}</Box>
      <TagLabel data-cy={name}>{name}</TagLabel>
    </Tag>
  );
};

const getTagProps = (status?: ExternalJobStatus) => {
  switch (status) {
    case 'PENDING':
      return {
        name: 'Pending',
        colorScheme: 'gray',
        icon: <FaHourglassHalf />,
      };
    case 'CONFIRMED':
      return {
        name: 'Confirmed',
        colorScheme: 'yellow',
        bg: 'yellow.400',
        icon: <FaTruck />,
      };
    case 'PICKUP_COMPLETE':
      return {
        name: 'Pickup Complete',
        colorScheme: 'blue',
        icon: <FaTruck />,
      };
    case 'PICKUP_FAILED':
      return {
        name: 'Pickup Failed',
        colorScheme: 'red',
        icon: <FaExclamationTriangle />,
      };
    case 'DROPOFF_FAILED':
      return {
        name: 'Dropoff Failed',
        colorScheme: 'red',
        icon: <FaExclamationTriangle />,
      };
    case 'DROPOFF_COMPLETE':
      return {
        name: 'Delivered',
        colorScheme: 'green',
        icon: <CheckCircleIcon />,
      };
    case 'CANCELLED_EXTERNAL':
      return {
        name: 'Cancelled by Provider',
        colorScheme: 'red',
        icon: <SmallCloseIcon />,
      };
    case 'CANCELLED_INTERNAL':
      return {
        name: 'Cancelled by Tradeaze',
        colorScheme: 'red',
        icon: <SmallCloseIcon />,
      };
    default:
      return {
        name: 'Unknown',
        colorScheme: 'gray',
        icon: null,
      };
  }
};
