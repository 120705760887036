import { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import toast from 'react-hot-toast';
import { extractFormValidationErrorMessages } from '../utils';
import {
  CreateDeliveryItem,
  CreateDeliveryStop,
  CreateOrder,
} from '@tradeaze-packages/schemas';
import { handleError } from '@tradeaze/frontend/utils';

type UseAtoBFormSubmission = {
  pickupForm: UseFormReturn<CreateDeliveryStop>;
  dropOffForm: UseFormReturn<CreateDeliveryStop>;
  itemForm: UseFormReturn<CreateDeliveryItem>;
  form: UseFormReturn<CreateOrder>;
  deliveryWindowStart: string;
  deliveryWindowEnd: string;
  onSubmit: (order: CreateOrder) => void;
};

export const useAtoBFormSubmission = ({
  pickupForm,
  dropOffForm,
  itemForm,
  form,
  deliveryWindowStart,
  deliveryWindowEnd,
  onSubmit,
}: UseAtoBFormSubmission) => {
  const validateForm = useCallback(async () => {
    const validationErrors: Array<Record<string, unknown>> = [];

    const pickupValid = await pickupForm.trigger();
    if (!pickupValid) {
      const pickupErrors = pickupForm.formState.errors;
      validationErrors.push({
        ...pickupErrors,
        companyName: pickupErrors.companyName
          ? { ...pickupErrors.companyName, isPickup: true }
          : undefined,
        addressLine1: pickupErrors.addressLine1
          ? { ...pickupErrors.addressLine1, isPickup: true }
          : undefined,
        postCode: pickupErrors.postCode
          ? { ...pickupErrors.postCode, isPickup: true }
          : undefined,
      });
    }

    const dropOffValid = await dropOffForm.trigger();
    if (!dropOffValid) {
      const dropOffErrors = dropOffForm.formState.errors;
      validationErrors.push({
        ...dropOffErrors,
        companyName: dropOffErrors.companyName
          ? { ...dropOffErrors.companyName, isDropOff: true }
          : undefined,
        addressLine1: dropOffErrors.addressLine1
          ? { ...dropOffErrors.addressLine1, isDropOff: true }
          : undefined,
        postCode: dropOffErrors.postCode
          ? { ...dropOffErrors.postCode, isDropOff: true }
          : undefined,
      });
    }

    const itemFormValid = await itemForm.trigger();
    if (!itemFormValid) {
      validationErrors.push(itemForm.formState.errors);
    }

    const formValid = await form.trigger();
    if (!formValid) {
      validationErrors.push(form.formState.errors);
    }

    const errorMessages = extractFormValidationErrorMessages(validationErrors);

    if (errorMessages.length > 0) {
      toast.error(
        `Please ensure all fields are valid: ${errorMessages.join(', ')}`,
      );
    }
  }, [dropOffForm, form, itemForm, pickupForm]);

  const handleSubmit = useCallback(async () => {
    await validateForm();

    const { id, ...pickupData } = pickupForm.getValues();
    const { id: dropOffId, ...dropOffData } = dropOffForm.getValues();
    const itemData = itemForm.getValues();

    const updatedPickupData = {
      ...pickupData,
      deliveryItems: [itemData],
      type: 'PICK_UP' as const,
      windowStart: deliveryWindowStart,
      windowEnd: deliveryWindowEnd,
      addressId: pickupData.addressId === '' ? null : pickupData.addressId,
    };

    const updatedDropOffData = {
      ...dropOffData,
      type: 'DROP_OFF' as const,
      windowStart: deliveryWindowStart,
      windowEnd: deliveryWindowEnd,
      addressId: dropOffData.addressId === '' ? null : dropOffData.addressId,
    };

    form.setValue('pickup', updatedPickupData);
    form.setValue('dropOff', updatedDropOffData);

    form.handleSubmit(
      (data) => {
        onSubmit(data);
      },
      (errors) => {
        handleError(errors, {
          sendToSentry: true,
          showNotification: false,
          consoleLog: true,
        });
      },
    )();
  }, [
    validateForm,
    pickupForm,
    dropOffForm,
    itemForm,
    deliveryWindowStart,
    deliveryWindowEnd,
    form,
    onSubmit,
  ]);

  return {
    handleSubmit,
  };
};