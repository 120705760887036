import {
  CreateDeliveryStop,
  CreateDeliveryItem,
  CreateOrder,
  CreateOrderMultiDrop,
} from '@tradeaze-packages/schemas';
import { handleError } from '@tradeaze/frontend/utils';
import { UseFormReturn } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { extractFormValidationErrorMessages } from '../utils';

type UseMultiDropFormSubmissionProps = {
  pickupForm: UseFormReturn<CreateDeliveryStop>;
  dropOffForm: UseFormReturn<CreateDeliveryStop>;
  itemForm: UseFormReturn<CreateDeliveryItem>;
  form: UseFormReturn<CreateOrder>;
  resetForms: () => void;
  setShowDropOffSection: (value: boolean) => void;
  setShowMainBackButton?: (value: boolean) => void;
  selectedDropOffIndex: number | null;
  setSelectedDropOffIndex: (value: number | null) => void;
  onSubmit: (order: CreateOrder) => void;
  multiDropDetails: CreateOrderMultiDrop;
};

export const useMultiDropFormSubmission = ({
  pickupForm,
  dropOffForm,
  itemForm,
  form,
  resetForms,
  setShowDropOffSection,
  setShowMainBackButton,
  selectedDropOffIndex,
  setSelectedDropOffIndex,
  onSubmit,
  multiDropDetails,
}: UseMultiDropFormSubmissionProps) => {
  const { deliveryWindowStart, deliveryWindowEnd, dropOffs } = multiDropDetails;
  const currentDropOffs = form.getValues('dropOffs') || [];

  const validateDropOffForm = async () => {
    const validationErrors = [];
    const isDropOffValid = await dropOffForm.trigger();
    const isDropOffCollectionReference = Boolean(
      dropOffForm.getValues('collectionReference'),
    );
    if (!isDropOffCollectionReference) {
      dropOffForm.setError('collectionReference', {
        message: 'Pickup reference is required',
      });
    }
    if (!isDropOffValid || !isDropOffCollectionReference) {
      validationErrors.push(dropOffForm.formState.errors);
    }
    const isItemValid = await itemForm.trigger();
    if (!isItemValid) {
      validationErrors.push(itemForm.formState.errors);
    }
    const errorMessages = extractFormValidationErrorMessages(validationErrors);
    if (errorMessages.length > 0) {
      toast.error(
        `Please ensure all fields are valid: ${errorMessages.join(', ')}`,
      );
      return false;
    }
    return true;
  };

  const validateForm = async () => {
    const validationErrors = [];
    const isPickupValid = await pickupForm.trigger();
    if (!isPickupValid) {
      validationErrors.push(pickupForm.formState.errors);
    }
    const isFormValid = await form.trigger();
    if (!isFormValid) {
      validationErrors.push(form.formState.errors);
    }
    const errorMessages = extractFormValidationErrorMessages(validationErrors);

    if (errorMessages.length > 0) {
      toast.error(
        `Please ensure all fields are valid: ${errorMessages.join(', ')}`,
      );
      return false;
    }
    return true;
  };

  const submitDeliveryAndItem = async (
    index: number,
    stopSequence?: number,
  ) => {
    const dropOffData = dropOffForm.getValues();
    const itemData = itemForm.getValues();

    const updatedDropOff = {
      ...currentDropOffs[index],
      ...dropOffData,
      type: 'DROP_OFF' as const,
      collectionReference: dropOffData.collectionReference ?? '',
      deliveryItems: [itemData],
      stopSequence: stopSequence ?? currentDropOffs[index].stopSequence,
    };

    currentDropOffs[index] = updatedDropOff;
    form.setValue('dropOffs', currentDropOffs);
  };

  const handleAddDelivery = async () => {
    try {
      const isValidated = await validateDropOffForm();
      if (!isValidated) return;

      const newDelivery = {
        type: 'DROP_OFF' as const,
        stopSequence: currentDropOffs.length + 2,
        addressLine1: '',
        postCode: '',
        windowStart: '',
        windowEnd: '',
        companyName: '',
        contacts: [],
        deliveryItems: [],
        collectionReference: '',
      };

      currentDropOffs.push(newDelivery);
      await submitDeliveryAndItem(
        currentDropOffs.length - 1,
        newDelivery.stopSequence,
      );
      resetForms();
      setShowDropOffSection(false);
      setShowMainBackButton?.(true);
    } catch (error) {
      console.error('Error in form submission:', error);
    }
  };

  const handleUpdateDelivery = async () => {
    if (selectedDropOffIndex === null) return;
    const isValidated = await validateDropOffForm();
    if (!isValidated) return;
    await submitDeliveryAndItem(selectedDropOffIndex);
    setShowDropOffSection(false);
    setShowMainBackButton?.(true);
    resetForms();
    setSelectedDropOffIndex(null);
  };

  const handleSubmit = async () => {
    const isValidated = await validateForm();
    if (!isValidated) return;

    // Execute pickup form submission first
    await pickupForm.handleSubmit((data) => {
      const { id, ...rest } = data;
      const modifiedPickupData = {
        ...rest,
        collectionReference: data.collectionReference || '',
        type: 'PICK_UP' as const,
        windowStart: deliveryWindowStart,
        windowEnd: deliveryWindowEnd,
        stopSequence: 1,
        deliveryItems: [],
      };
      form.setValue('pickup', modifiedPickupData);
    })();

    dropOffs?.forEach((dropOff, index) => {
      const { id, ...rest } = dropOff;
      const modifiedDropOffData = {
        ...rest,
        windowStart: deliveryWindowStart,
        windowEnd: deliveryWindowEnd,
        stopSequence: index + 2,
      };
      form.setValue(`dropOffs.${index}`, modifiedDropOffData);
    });

    form.handleSubmit(
      (data) => {
        onSubmit(data);
      },
      (errors) => {
        handleError(errors, {
          sendToSentry: true,
          showNotification: false,
          consoleLog: true,
        });
      },
    )();
  };

  return {
    handleAddDelivery,
    handleUpdateDelivery,
    handleSubmit,
  };
};
