import { useQuery } from '@tanstack/react-query';
import { getOrderById } from '@tradeaze-packages/api-client';
import { GetOrderByIdQuery } from '@tradeaze-packages/schemas';
import { CustomUseQueryOptions } from '../shared';

export const getOrderQueryKey = (
  orderId?: string,
  query?: GetOrderByIdQuery,
) => {
  const key: (string | object)[] = ['getOrder'];
  if (orderId) {
    key.push(orderId);
  }
  if (query) {
    key.push(query);
  }
  return key;
};

export const useGetOrder = (
  orderId: string | undefined,
  query?: GetOrderByIdQuery,
  options?: CustomUseQueryOptions<typeof getOrderById>,
) => {
  return useQuery({
    queryKey: getOrderQueryKey(orderId, query),
    queryFn: async () => {
      if (!orderId) {
        throw new Error('Order ID is required');
      }
      return getOrderById(orderId, query ?? { includeProofMedia: false });
    },
    enabled: !!orderId,
    ...options,
  });
};
