import { z } from 'zod';
import { RiderBankDetailsSchema } from '../../../models';

export const UpdateRiderBankDetailsBodySchema = RiderBankDetailsSchema.omit({
  id: true,
  riderId: true,
  createdAt: true,
  updatedAt: true,
}).partial();

export const UpdateRiderBankDetailsResponseSchema = RiderBankDetailsSchema.pick({
  riderId: true,
  updatedAt: true,
});

export type UpdateRiderBankDetailsBody = z.infer<typeof UpdateRiderBankDetailsBodySchema>;
export type UpdateRiderBankDetailsResponse = z.infer<typeof UpdateRiderBankDetailsResponseSchema>; 