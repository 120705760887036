import { Box } from '@chakra-ui/react';
import { Attribute, TextValue } from '../shared';

type MultiContactsSectionProps = {
  contacts: {
    isPrimary: boolean;
    contactNumber: string;
    contactName: string;
    trackingEnabled: boolean;
    companyId?: string | null | undefined;
    createdAt?: string | null | undefined;
    updatedAt?: string | null | undefined;
    siteContactId?: string | undefined;
  }[];
  testId: string;
};
export const MultiContactsSection = ({
  contacts,
  testId,
}: MultiContactsSectionProps) => {
  if (!contacts.length) {
    return null;
  }
  return (
    <Box display="flex" flexWrap="wrap" mt={6}>
      {contacts.map((contact, index) => (
        <Attribute
          key={index}
          name={index === 0 ? 'Contact 1 (Primary)' : `Contact ${index + 1}`}
          style={{ width: '50%' }}
          data-cy={`${testId}-contact-${index}`}
        >
          <TextValue text={contact.contactName} />
          <TextValue text={contact.contactNumber} />
        </Attribute>
      ))}
    </Box>
  );
};
