import { z } from 'zod';

const ACCOUNT_NUMBER_LENGTH = 8;
const SORT_CODE_LENGTH = 6;
const VAT_NUMBER_LENGTH = 9;

const removeHyphensAndSpaces = (value: string) => value.replace(/[-\s]/g, '');
const removeSpaces = (value: string) => value.replace(/\s/g, '');
const removeStartingGb = (value: string) => value.replace(/^GB/g, '');

const cleanVatNumber = (value: string) => removeStartingGb(removeHyphensAndSpaces(value));
const cleanAccountNumber = (value: string) => removeSpaces(value);
const cleanSortCode = (value: string) => removeHyphensAndSpaces(value);

const isExactLengthNumbers = (value: string, length: number): boolean => {
  return new RegExp(`^\\d{${length}}$`).test(value);
};

export const BankAccountTypeSchema = z.enum(['PERSONAL', 'BUSINESS']);

export const RiderBankDetailsSchema = z.object({
  createdAt: z.string(),
  updatedAt: z.string(),
  id: z.string(),
  riderId: z.string(),
  accountType: BankAccountTypeSchema,
  nameOnAccount: z.string()
    .trim()
    .min(1, { message: 'Name on account is required' })
    .max(100, { message: 'Name on account must be less than 100 characters' }),

  accountNumber: z.string()
    .trim()
    .transform(cleanAccountNumber)
    .refine(
      (value) => isExactLengthNumbers(value, ACCOUNT_NUMBER_LENGTH),
      {
        message: `Account number must be exactly ${ACCOUNT_NUMBER_LENGTH} digits`
      }
    ),

  sortCode: z.string()
    .trim()
    .transform(cleanSortCode)
    .refine(
      (value) => isExactLengthNumbers(value, SORT_CODE_LENGTH),
      {
        message: `Sort code must be exactly ${SORT_CODE_LENGTH} digits (formats like 12-34-56 are allowed)`
      }
    ),

  vatNumber: z.string()
    .trim()
    .nullish()
    .transform((value) => {
      if (!value) return value;
      return cleanVatNumber(value);
    })
    .refine(
      (value) => {
        if (!value) return true;
        return isExactLengthNumbers(value, VAT_NUMBER_LENGTH);
      },
      {
        message: `VAT number must be exactly ${VAT_NUMBER_LENGTH} digits, optionally starting with 'GB' (e.g., GB123456789 or 123456789)`
      }
    ),
});

export type BankAccountType = z.infer<typeof BankAccountTypeSchema>;
export type RiderBankDetails = z.infer<typeof RiderBankDetailsSchema>;
