import { MerchantAccount } from '@tradeaze-packages/schemas';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type SortBy = 'createdAt' | 'alphabetical';

const sortByNewest = (a: MerchantAccount, b: MerchantAccount) => {
  if (!a.createdAt || !b.createdAt) return 0;
  return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
};

const sortByAlphabetical = (a: MerchantAccount, b: MerchantAccount) => {
  return a.merchantName.localeCompare(b.merchantName);
};

const determineSort = (sortBy: SortBy) => {
  if (sortBy === 'createdAt') return sortByNewest;
  if (sortBy === 'alphabetical') return sortByAlphabetical;
  return sortByAlphabetical;
};

type MerchantDashboardFiltersStore = {
  search: string;
  sortBy: SortBy;
  handleChangeSearch: (search: string) => void;
  handleChangeSortBy: (sortBy: SortBy) => void;
  getFilteredData: (merchants?: MerchantAccount[]) => MerchantAccount[];
};

export const useMerchantDashboardFilters = create<MerchantDashboardFiltersStore>()(
  persist(
    (set, get) => ({
      search: '',
      sortBy: 'alphabetical',
      
      handleChangeSearch: (search: string) => 
        set({ search }),
      
      handleChangeSortBy: (sortBy: SortBy) => 
        set({ sortBy }),

      getFilteredData: (merchants?: MerchantAccount[]) => {
        if (!merchants) return [];
        const { search, sortBy } = get();
        const result = merchants.sort(determineSort(sortBy));
        if (!search) return result;
        return result.filter((merchant) => {
          return merchant.merchantName.toLowerCase().includes(search.toLowerCase());
        });
      },
    }),
    {
      name: 'merchant-dashboard-filters',
      getStorage: () => sessionStorage,
    }
  )
);
