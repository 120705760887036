import {
  HydratedOrder,
  ExistingOrder,
  HydratedDelivery,
  OrderType,
  DeliveryStopType,
} from '@tradeaze-packages/schemas';
import { isExternalJobAssigned } from '../isExternalJobAssigned';

const getExistingCollectionReference = ({
  deliveryStopId,
  stopType,
  orderType,
  stopToDeliveryMap,
}: {
  deliveryStopId: string;
  stopType: DeliveryStopType;
  orderType: OrderType;
  stopToDeliveryMap: Record<
    string,
    Pick<HydratedDelivery, 'pickup' | 'dropOff'>
  >;
}) => {
  /**
   * On multi drop order creation, collection reference is sent as part of the drop off stop, because each delivery (set of items) may have a different collection references.
   * The deliveryStops array just passes back unique pickups but in reality there would be duplicate pickups in the backend each with a different collection reference.
   * So we do not want to take the collection reference from the pickup stop (as it will be from only one delivery).
   */
  if (stopType === 'PICK_UP' && orderType === 'MULTI_DROP') {
    return null;
  }

  const deliveryForStop = stopToDeliveryMap[deliveryStopId];

  return (
    deliveryForStop?.pickup.collectionReference ||
    deliveryForStop?.dropOff.collectionReference ||
    null
  );
};

const buildStopToDeliveryMap = (order: HydratedOrder) => {
  return order.deliveries.reduce<
    Record<string, Pick<HydratedDelivery, 'pickup' | 'dropOff'>>
  >((acc, delivery) => {
    acc[delivery.pickup.deliveryStopId] = {
      pickup: delivery.pickup,
      dropOff: delivery.dropOff,
    };
    acc[delivery.dropOff.deliveryStopId] = {
      pickup: delivery.pickup,
      dropOff: delivery.dropOff,
    };
    return acc;
  }, {});
};

export const parseToExistingOrder = (order: HydratedOrder): ExistingOrder => {
  const stopToDeliveryMap = buildStopToDeliveryMap(order);

  const pickupDetails = order.deliveryStops
    ?.filter((stop) => stop.type === 'PICK_UP')
    .map((stop) => {
      return {
        collectionReference: getExistingCollectionReference({
          deliveryStopId: stop.deliveryStopId,
          stopType: stop.type,
          orderType: order.type,
          stopToDeliveryMap,
        }),
        deliveryStopId: stop.deliveryStopId,
        contacts: stop.contacts,
        companyName: stop.companyName,
        addressLine1: stop.addressLine1,
        postCode: stop.postCode,
        requiresImage: stop.requiresImage,
        requiresSignature: stop.requiresSignature,
        position: stop.position,
        deliveryItems:
          stop.delivery?.deliveryItems.map((item) => ({
            id: item.id ?? '',
            deliveryId: item.deliveryId ?? '',
            name: item.name ?? '',
            quantity: item.quantity ?? 0,
            heightUnit: item.heightUnit ?? 'cm',
            lengthUnit: item.lengthUnit ?? 'cm',
            widthUnit: item.widthUnit ?? 'cm',
            weightUnit: item.weightUnit ?? 'kg',
            height: item.height ?? 0,
            length: item.length ?? 0,
            width: item.width ?? 0,
            weight: item.weight ?? 0,
            price: item.price ?? 0,
            description: item.description,
            heavySideItems: stop.delivery?.heavySideItems ?? false,
          })) ?? [],
        windowStart: stop.windowStart,
        windowEnd: stop.windowEnd,
        stopSequence: stop.stopSequence,
        addressId: stop.addressId,
        status: stop.delivery?.status,
        type: 'PICK_UP' as const,
      };
    });

  const dropoffDetails = order.deliveryStops
    ?.filter((stop) => stop.type === 'DROP_OFF')
    .map((stop) => {
      return {
        collectionReference: getExistingCollectionReference({
          deliveryStopId: stop.deliveryStopId,
          stopType: stop.type,
          orderType: order.type,
          stopToDeliveryMap,
        }),
        contacts: stop.contacts,
        companyName: stop.companyName,
        addressLine1: stop.addressLine1,
        postCode: stop.postCode,
        position: stop.position,
        windowStart: stop.windowStart,
        windowEnd: stop.windowEnd,
        deliveryStopId: stop.deliveryStopId,
        companyId: stop.companyId,
        addressId: stop.addressId,
        stopSequence: stop.stopSequence,
        type: 'DROP_OFF' as const,
        deliveryItems:
          stop.delivery?.deliveryItems.map((item) => ({
            id: item.id ?? '',
            deliveryId: item.deliveryId ?? '',
            name: item.name ?? '',
            quantity: item.quantity ?? 0,
            heightUnit: item.heightUnit ?? 'cm',
            lengthUnit: item.lengthUnit ?? 'cm',
            widthUnit: item.widthUnit ?? 'cm',
            weightUnit: item.weightUnit ?? 'kg',
            height: item.height ?? 0,
            length: item.length ?? 0,
            width: item.width ?? 0,
            weight: item.weight ?? 0,
            price: item.price ?? 0,
            description: item.description,
            heavySideItems: stop.delivery?.heavySideItems ?? false,
          })) ?? [],
        status: stop.delivery?.status,
      };
    });

  return {
    merchantId: order.merchantId,
    pickupDetails,
    dropoffDetails,
    type: order.type,
    deliveryVehicle:
      order.deliveryStops?.[0].delivery?.deliveryVehicleId ?? 'CAR',
    deliveryOption:
      order.deliveryStops?.[0].delivery?.deliveryOptionId ?? 'DAY',
    bookedBy: order.bookedBy,
    orderReference: order.merchantOrderReference,
    orderDate: new Date(order.deliveryWindowStart),
    orderStatus: order.orderStatus,
    invoicingName: order.invoicingName,
    deliveryWindowStart: order.deliveryWindowStart,
    isExternalJobAssigned: isExternalJobAssigned(order.deliveries),
  };
};
