import { zodResolver } from '@hookform/resolvers/zod';
import { HydratedGigJob } from '@tradeaze-packages/schemas';
import { useCancelGigJob, useUpdateGigJob } from '@tradeaze/frontend/hooks';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { z } from 'zod';

const GigPaymentFormSchema = z.object({
  isPaid: z.boolean(),
  paymentAmount: z.number().min(0, 'Amount must be a positive number'),
});

export type GigPaymentFormSchemaType = z.infer<typeof GigPaymentFormSchema>;

type UseJobSummaryModal = {
  gigJob: HydratedGigJob;
  onClose: () => void;
};

export const useJobSummaryModal = ({ gigJob, onClose }: UseJobSummaryModal) => {
  const [isCancel, setIsCancel] = useState(false);

  const gigJobForm = useForm<GigPaymentFormSchemaType>({
    defaultValues: {
      isPaid: Boolean(gigJob.paidAt),
      paymentAmount: gigJob.paymentAmount,
    },
    resolver: zodResolver(GigPaymentFormSchema),
  });

  const updateGigJobMutation = useUpdateGigJob();

  const cancelGigJobMutation = useCancelGigJob();

  const isPaid = gigJobForm.watch('isPaid');
  const paymentAmount = gigJobForm.watch('paymentAmount');

  const valuesHaveChanged =
    isPaid !== Boolean(gigJob.paidAt) || paymentAmount !== gigJob.paymentAmount;

  const allowSave = valuesHaveChanged;

  const allowCancel = gigJob.status !== 'CANCELLED' && !gigJob.paidAt;

  const onSubmit = (data: GigPaymentFormSchemaType) => {
    updateGigJobMutation.mutate({
      gigJobId: gigJob.gigJobId,
      body: {
        paymentAmount: data.paymentAmount,
        paymentCurrency: 'GBP',
        ...(data.isPaid && { paidAt: new Date().toISOString() }),
      },
    });
  };

  const handleStartCancel = () => {
    setIsCancel(!isCancel);
  };

  const handleModalClose = () => {
    setIsCancel(false);
    gigJobForm.reset();
    onClose();
  };

  const handleSave = () => {
    const onInvalid = () => {
      toast.error('Failed to validate form');
    };

    gigJobForm.handleSubmit(onSubmit, onInvalid)();
  };

  const handleCancelJob = () => {
    cancelGigJobMutation.mutate(
      { gigJobId: gigJob.gigJobId },
      {
        onSuccess: () => {
          handleModalClose();
        },
      },
    );
  };

  const isLoading =
    updateGigJobMutation.isLoading || gigJobForm.formState.isSubmitting;

  return {
    isCancel,
    allowSave,
    allowCancel,
    isLoading,
    register: gigJobForm.register,
    handleSave,
    handleStartCancel,
    handleCancelJob,
    handleModalClose,
  };
};
