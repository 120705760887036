import { HStack } from '@chakra-ui/react';
import { MerchantAccount } from '@tradeaze-packages/schemas';
import { DownloadMerchantCsv } from '../../csv';
import { NAVBAR_WIDTH_PX } from '../../navbar';
import { brandSecondaryColor } from '../../theme';
import { OrderQueryOptionsDrawer } from './OrderQueryOptionsDrawer';
import { ShowCompletedButton } from './ShowCompletedButton';
import { OrderQueryStore } from './useOrderQueryStore';


export const MerchantOrderQueryOptionsBar: React.FC<{
  orderQueryStore: OrderQueryStore;
  merchantAccount: MerchantAccount;
}> = ({ merchantAccount, orderQueryStore }) => {
  return (
    <HStack
      position={'fixed'}
      top={0}
      right={0}
      zIndex={1}
      bg={brandSecondaryColor}
      minHeight={12}
      width={`calc(100vw - ${NAVBAR_WIDTH_PX})`}
      shadow={'md'}
      alignItems={'center'}
      justifyContent={'flex-end'}
      paddingX={4}
      spacing={2}
    >
      <ShowCompletedButton
        handleToggleShowCompleted={orderQueryStore.handleToggleShowCompleted}
        showCompleted={orderQueryStore.showCompleted}
      />
      <OrderQueryOptionsDrawer
        orderQueryStore={orderQueryStore}
        buttonProps={{
          size: 'sm',
          ml: 2,
          borderColor: 'white',
          borderWidth: 0.5,
        }}
        isAdmin={false}
      />
      <DownloadMerchantCsv
        orderQueryStore={orderQueryStore}
        merchantId={merchantAccount?.merchantId}
        size="sm"
        borderColor={'white'}
        borderWidth={0.5}
      />
    </HStack>
  );
};
