import {
  HStack,
  Show,
  Text,
  Tab,
  TabPanel,
  Tabs,
  TabList,
  TabPanels,
  VStack,
  Button,
  Divider,
  ModalCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  SimpleGrid,
  Box,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { MerchantSiteContact, Address } from '@tradeaze-packages/schemas';
import {
  Map,
  MapMarkerProps,
  PageError,
  TwoColumnLayout,
  AddressForm,
  brandSecondaryColor,
  SiteContactForm,
  addressesToMarkers,
  PageLoading,
  AddressCard,
  SiteContactCard,
  MobileMapWithToggle,
} from '@tradeaze/frontend/ui-web';
import { PhoneIcon } from '@chakra-ui/icons';
import { MdListAlt } from 'react-icons/md';
import React, { useMemo } from 'react';
import {
  useDeleteSiteContact,
  useGetCompanyById,
  useGetAddresses,
  useGetSiteContacts,
  useDeleteMerchantCompanyAddress,
  getAddressesQueryKey,
} from '@tradeaze/frontend/hooks';
import { useAuthContext } from '../components/auth/AuthProvider';

const AddressFormModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  merchantId: string;
  companyId: string;
}> = ({ companyId, isOpen, merchantId, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size="lg">
      <ModalOverlay />
      <ModalContent paddingX={6} paddingY={12} borderRadius={12}>
        <ModalCloseButton />
        <ModalBody>
          <AddressForm
            onSubmit={onClose}
            merchantId={merchantId}
            companyId={companyId}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const SiteContactFormModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  companyId: string;
}> = ({ companyId, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size="lg">
      <ModalOverlay />
      <ModalContent paddingX={4} paddingY={10} borderRadius={12}>
        <ModalCloseButton />
        <ModalBody>
          <SiteContactForm onSubmit={onClose} companyId={companyId} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const CompanyPage = () => {
  const { companyId } = useParams();
  const { cognitoUserId } = useAuthContext();

  const merchantId = cognitoUserId;

  const { data: company, isLoading } = useGetCompanyById(companyId);
  const [addressIdHovered, setAddressIdHovered] = React.useState<string | null>(
    null
  );

  const { data: paginatedSiteContacts, isLoading: isLoadingSiteContacts } =
    useGetSiteContacts(companyId);

  const addressDisclosure = useDisclosure();
  const siteContactDisclosure = useDisclosure();

  const {
    mutate: deleteSiteContact,
    isLoading: isDeletingSiteContact,
    variables,
  } = useDeleteSiteContact({
    invalidateQueryKeys: [['getSiteContacts', companyId]],
  });

  const siteContacts = useMemo(
    () =>
      paginatedSiteContacts?.pages[0].siteContacts
        .reduce((acc, el) => {
          // if contact already exists in list, don't add it again
          if (
            acc.some(
              (el2) =>
                el2.contactName === el.contactName &&
                el2.contactNumber === el.contactNumber
            )
          ) {
            return acc;
          }
          return [...acc, el];
        }, [] as MerchantSiteContact[])
        .sort((a, b) => a.contactName.localeCompare(b.contactName)),
    [paginatedSiteContacts]
  );

  const {
    data: paginatedMerchantCustomerAddresses,
    isLoading: isLoadingAddresses,
  } = useGetAddresses({ companyId, merchantId, addressType: 'DROP_OFF' });

  const { mutate: deleteMerchantCompanyAddress, isLoading: isDeletingAddress } =
    useDeleteMerchantCompanyAddress({
      invalidateQueryKeys: [
        getAddressesQueryKey({
          addressType: 'DROP_OFF',
          companyId,
          merchantId,
        }),
      ],
    });

  const merchantCustomerAddresses = useMemo(
    () =>
      paginatedMerchantCustomerAddresses?.pages[0].addresses
        .reduce((acc, el) => {
          // if address already exists in list, don't add it again
          if (
            acc.some(
              (el2) =>
                el2.addressLine1 === el.addressLine1 &&
                el2.postCode === el.postCode
            )
          ) {
            return acc;
          }
          return [...acc, el];
        }, [] as Address[])
        .sort((a, b) => a.addressLine1.localeCompare(b.addressLine1)),
    [paginatedMerchantCustomerAddresses]
  );

  const addressMarkers = useMemo<MapMarkerProps<'ADDRESS'>[]>(() => {
    if (!merchantCustomerAddresses) {
      return [];
    }
    return addressesToMarkers(merchantCustomerAddresses, addressIdHovered);
  }, [merchantCustomerAddresses, addressIdHovered]);

  const setAddressHoverValue = (addressId: string, hovered: boolean) => {
    if (hovered) {
      setAddressIdHovered(addressId);
    } else {
      setAddressIdHovered(null);
    }
  };

  if (!merchantId || !companyId) {
    return <PageError />;
  }

  const MainComponent = (
    <VStack align="start" spacing={6}>
      <Box>
        <Text fontSize="s" color={'blackAlpha.500'}>
          Company
        </Text>
        <Text
          fontSize="2xl"
          fontWeight="bold"
          color={brandSecondaryColor}
          data-cy="company-name"
        >
          {company?.companyName}
        </Text>
      </Box>
      <Tabs isFitted width={'100%'} variant="line">
        <TabList mb={4}>
          <Tab data-cy="addresses-tab">
            <HStack spacing={2}>
              <MdListAlt />
              <Text>Addresses</Text>
            </HStack>
          </Tab>
          <Tab data-cy="site-contacts-tab">
            <HStack spacing={2}>
              <PhoneIcon />
              <Text>Site Contacts</Text>
            </HStack>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Button
              onClick={addressDisclosure.onOpen}
              size="sm"
              data-cy="add-address"
            >
              Add Address
            </Button>
            <Divider my={4} />
            <AddressFormModal
              isOpen={addressDisclosure.isOpen}
              onClose={addressDisclosure.onClose}
              companyId={companyId}
              merchantId={merchantId}
            />
            {isLoadingAddresses ? (
              <PageLoading />
            ) : (
              <SimpleGrid columns={2} spacing={4}>
                {merchantCustomerAddresses?.map((address) => (
                  <AddressCard
                    key={address.addressId}
                    address={address}
                    isLoadingDelete={isDeletingAddress}
                    onDelete={() =>
                      deleteMerchantCompanyAddress({
                        addressId: address.addressId,
                      })
                    }
                    onHover={(value) => {
                      setAddressHoverValue(address.addressId, value);
                    }}
                  />
                ))}
              </SimpleGrid>
            )}
          </TabPanel>
          <TabPanel>
            <Button
              onClick={siteContactDisclosure.onOpen}
              size="sm"
              data-cy="add-site-contact"
            >
              Add Site Contact
            </Button>
            <Divider my={4} />
            <SiteContactFormModal
              isOpen={siteContactDisclosure.isOpen}
              onClose={siteContactDisclosure.onClose}
              companyId={companyId}
            />
            {isLoadingSiteContacts ? (
              <PageLoading />
            ) : (
              <SimpleGrid columns={2} spacing={4}>
                {siteContacts?.map((siteContact) => (
                  <SiteContactCard
                    key={siteContact.siteContactId}
                    siteContact={siteContact}
                    isDeleting={
                      isDeletingSiteContact &&
                      variables?.siteContactId === siteContact.siteContactId
                    }
                    onDelete={() =>
                      deleteSiteContact({
                        siteContactId: siteContact.siteContactId,
                      })
                    }
                  />
                ))}
              </SimpleGrid>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );

  return (
    <>
      <Show above="md">
        <TwoColumnLayout
          left={isLoading ? <PageLoading /> : MainComponent}
          right={<Map markers={addressMarkers} />}
          leftContainerProps={{
            paddingX: 4,
          }}
        />
      </Show>
      <Show below="md">
        <Box paddingTop={120} paddingX={4}>
          {MainComponent}
          <MobileMapWithToggle markers={addressMarkers} />
        </Box>
      </Show>
    </>
  );
};

export default CompanyPage;
