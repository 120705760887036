import { Box } from '@chakra-ui/react';
import ReactDatePicker from 'react-datepicker';

export type DatePickerSize = 'small' | 'medium' | 'large';

type Props = React.ComponentProps<typeof ReactDatePicker> & {
  date?: Date;
  size: DatePickerSize;
  shouldFilterDate?: (date: Date) => boolean;
  allowPastDates: boolean;
  allowSunday: boolean;
};

export const SmallDatePicker: React.FC<Props> = (props) => {
  // Define sizes
  const sizes = {
    small: { padding: '4px 12px', fontSize: '14px', width: '115px' },
    medium: { padding: '8px 16px', fontSize: '18px', width: '125px' },
    large: { padding: '8px 20px', fontSize: '22px', width: '180px' },
  };

  const sizeStyle = sizes[props.size];

  return (
    <Box
      sx={{
        '.react-datepicker__input-container': {
          input: {
            ...sizeStyle,
            borderRadius: '5px',
            borderWidth: '1px',
          },
        },
      }}
    >
      <ReactDatePicker
        placeholderText="Delivery Date"
        selected={props.date ? new Date(props.date) : undefined}
        dateFormat="dd/MM/yyyy"
        filterDate={
          props.allowSunday ? undefined : (date) => date.getDay() !== 0
        }
        minDate={props.allowPastDates ? undefined : new Date()}
        {...props}
      />
    </Box>
  );
};
