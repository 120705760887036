import {
  ApproveFleetVehicleResponse,
  ApproveRiderResponse,
  CreateFleetVehicleRequest,
  CreateFleetVehicleResponse,
  DeleteFleetVehicleResponse,
  DeleteRiderResponse,
  FleetVehicleStatsResponse,
  GetActiveFleetVehiclesResponse,
  GetClockInsQuery,
  GetClockInsResponse,
  GetFleetVehicleByIdResponse,
  GetFleetVehiclesQuery,
  GetFleetVehiclesResponse,
  GetRiderByIdQuery,
  GetRiderByIdResponse,
  GetRiderLocationsResponse,
  GetRidersQuery,
  GetRidersResponse,
  RejectFleetVehicleResponse,
  RejectRiderResponse,
  ResetFleetVehicleResponse,
  ResetRiderResponse,
  RiderStatsResponse,
  UpdateFleetVehicleRequest,
  UpdateFleetVehicleResponse,
  UpdateRiderBody,
  UpdateRiderResponse,
  GetRiderDocumentsResponse,
  GetRiderDocumentUploadUrlResponse,
  CompleteRiderDocumentUploadResponse,
  RiderDocumentTypeId,
  DocumentFileExtension,
} from '@tradeaze-packages/schemas';
import * as client from './client';
import { handleResponse } from './handle-response';

export const fetchRiderAccounts = async (
  query: GetRidersQuery,
): Promise<GetRidersResponse> => handleResponse(client.getRiders({ query }));

export const fetchRiderLocations = async (
  riderIds: string[],
): Promise<GetRiderLocationsResponse> =>
  handleResponse(
    client.getRiderLocations({
      body: { riderIds },
    }),
  );
export const fetchExternalRiderLocation = async (
  riderIds: string[],
): Promise<GetRiderLocationsResponse> =>
  handleResponse(
    client.getExternalRiderLocations({
      body: { riderIds },
    }),
  );

export const fetchClockIns = async (
  query: GetClockInsQuery,
): Promise<GetClockInsResponse> =>
  handleResponse(client.getClockIns({ query }));

export const fetchActiveFleetVehicles = async (
  date: string,
): Promise<GetActiveFleetVehiclesResponse> =>
  handleResponse(
    client.getActiveFleetVehicles({
      path: { date },
    }),
  );

export const fetchFleetVehicles = async (
  query: GetFleetVehiclesQuery,
): Promise<GetFleetVehiclesResponse> =>
  handleResponse(client.getFleetVehicles({ query }));

export const createFleetVehicle = async (
  fleetVehicle: CreateFleetVehicleRequest,
): Promise<CreateFleetVehicleResponse> =>
  handleResponse(
    client.adminCreateFleetVehicle({
      body: fleetVehicle,
    }),
  );

export const getFleetVehiclesStats =
  async (): Promise<FleetVehicleStatsResponse> =>
    handleResponse(client.fleetVehicleStats());

export const getFleetVehicleById = async (
  fleetVehicleId: string,
): Promise<GetFleetVehicleByIdResponse> =>
  handleResponse(
    client.getFleetVehicle({
      path: { fleetVehicleId },
    }),
  );

export const updateFleetVehicle = async ({
  fleetVehicle,
  fleetVehicleId,
}: {
  fleetVehicle: UpdateFleetVehicleRequest;
  fleetVehicleId: string;
}): Promise<UpdateFleetVehicleResponse> =>
  handleResponse(
    client.adminUpdateFleetVehicle({
      path: { fleetVehicleId },
      body: fleetVehicle,
    }),
  );

export const approveFleetVehicle = async ({
  fleetVehicleId,
}: {
  fleetVehicleId: string;
}): Promise<ApproveFleetVehicleResponse> =>
  handleResponse(
    client.approveFleetVehicle({
      path: { fleetVehicleId },
    }),
  );

export const rejectFleetVehicle = async ({
  rejectedReason,
  fleetVehicleId,
}: {
  rejectedReason?: string;
  fleetVehicleId: string;
}): Promise<RejectFleetVehicleResponse> =>
  handleResponse(
    client.rejectFleetVehicle({
      path: { fleetVehicleId },
      body: { rejectedReason },
    }),
  );

export const resetFleetVehicle = async ({
  fleetVehicleId,
}: {
  fleetVehicleId: string;
}): Promise<ResetFleetVehicleResponse> =>
  handleResponse(
    client.resetFleetVehicle({
      path: { fleetVehicleId },
    }),
  );

export const deleteFleetVehicle = async ({
  fleetVehicleId,
}: {
  fleetVehicleId: string;
}): Promise<DeleteFleetVehicleResponse> =>
  handleResponse(
    client.adminDeleteFleetVehicle({
      path: { fleetVehicleId },
    }),
  );

export const getRiderById = async (
  riderId: string,
  query: GetRiderByIdQuery,
): Promise<GetRiderByIdResponse> =>
  handleResponse(
    client.getRiderById({
      path: { riderId },
      query,
    }),
  );

export const approveRider = async (
  riderId: string,
): Promise<ApproveRiderResponse> =>
  handleResponse(
    client.approveRider({
      path: { riderId },
    }),
  );

export const rejectRider = async ({
  riderId,
  rejectedReason,
}: {
  riderId: string;
  rejectedReason?: string;
}): Promise<RejectRiderResponse> =>
  handleResponse(
    client.rejectRider({
      path: { riderId },
      body: { rejectedReason },
    }),
  );

export const deleteRider = async (
  riderId: string,
): Promise<DeleteRiderResponse> =>
  handleResponse(
    client.deleteRiderById({
      path: { riderId },
    }),
  );

export const undoDeletedRider = async (riderId: string) =>
  handleResponse(
    client.undoDeletedRider({
      path: { riderId },
    }),
  );

export const resetRider = async (
  riderId: string,
): Promise<ResetRiderResponse> =>
  handleResponse(
    client.resetRider({
      path: { riderId },
    }),
  );

export const updateRider = async ({
  rider,
  riderId,
}: {
  rider: UpdateRiderBody;
  riderId: string;
}): Promise<UpdateRiderResponse> =>
  handleResponse(
    client.adminUpdateRider({
      path: { riderId },
      body: rider,
    }),
  );

export const getRiderStats = async (): Promise<RiderStatsResponse> =>
  handleResponse(client.riderStats());

export const getRiderDocuments = async ({
  riderId,
  documentTypeId,
  includeUrls,
}: {
  riderId: string;
  documentTypeId?: RiderDocumentTypeId;
  includeUrls?: boolean;
}): Promise<GetRiderDocumentsResponse> =>
  handleResponse(
    client.getRiderDocuments({
      path: { riderId },
      query: { documentTypeId, includeUrls },
    }),
  );

export const getRiderDocumentUploadUrl = async ({
  riderId,
  documentTypeId,
  fileExtension,
}: {
  riderId: string;
  documentTypeId: RiderDocumentTypeId;
  fileExtension: DocumentFileExtension;
}): Promise<GetRiderDocumentUploadUrlResponse> =>
  handleResponse(
    client.getRiderDocumentUploadUrl({
      path: { riderId, documentTypeId, fileExtension },
    }),
  );

export const completeRiderDocumentUpload = async ({
  riderId,
  documentId,
  documentKey,
}: {
  riderId: string;
  documentId: string;
  documentKey: string;
}): Promise<CompleteRiderDocumentUploadResponse> =>
  handleResponse(
    client.completeRiderDocumentUpload({
      path: { riderId },
      body: { documentId, documentKey },
    }),
  );
