import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Box, Button, Heading, HStack, Stack, Text } from '@chakra-ui/react';
import { HydratedOrder } from '@tradeaze-packages/schemas';
import React from 'react';
import { DeliveryPostcodes } from '../delivery';

const orderTypeToTitle = {
  MULTI_COLLECTION: 'Multi-Collection',
  A_TO_B: 'A to B',
  MULTI_DROP: 'Multi-Drop',
} as const;

export const OrderDeliveriesHeading = ({
  order,
  pickups,
  dropOffs,
  onSelectOrder,
  onPressGoTo,
}: {
  order: HydratedOrder;
  pickups: { postCode: string }[];
  dropOffs: { postCode: string }[];
  onSelectOrder?: () => void;
  onPressGoTo: (orderId: string) => void;
}) => (
  <Box pb={8}>
    <Stack
      direction={['column', 'row']}
      spacing={4}
      pt={2}
      pb={4}
      justifyContent={'space-between'}
      alignItems={['flex-start', 'center']}
    >
      <HStack>
        <Button
          variant={'ghost'}
          colorScheme={'gray'}
          onClick={() => onSelectOrder?.()}
          mr="5px"
          px={0}
        >
          <ChevronLeftIcon fontSize={32} />
        </Button>
        <Heading size="md" fontSize={18}>
          {orderTypeToTitle[order.type]} Order
        </Heading>
      </HStack>
      <Button
        fontSize={14}
        borderRadius={4}
        w={['full', '150px']}
        h={8}
        onClick={() => onPressGoTo(order.orderId)}
        data-cy="view-order-details"
      >
        View Order Details
      </Button>
    </Stack>
    <Stack
      direction={['column', 'row']}
      justifyContent={'space-between'}
      alignItems={['flex-start', 'center']}
      spacing={4}
      pl={[0, 4]}
    >
      <DeliveryPostcodes pickups={pickups} dropOffs={dropOffs} />
      <Text fontSize="md" data-cy="merchant-order-reference">
        {order.merchantOrderReference}
      </Text>
    </Stack>
  </Box>
);
