import { useQuery } from '@tanstack/react-query';
import { fetchMerchantAccounts } from '@tradeaze-packages/api-client';
import { CustomUseQueryOptions } from '../shared';

export const getAllMerchantsQueryKey = () => ['getAllMerchants'];

const thirtyMinutes = 30 * 60 * 1000;

export const useGetAllMerchants = (
  options?: CustomUseQueryOptions<typeof fetchMerchantAccounts>,
) => {
  return useQuery({
    queryKey: getAllMerchantsQueryKey(),
    queryFn: async () => fetchMerchantAccounts(),
    staleTime: thirtyMinutes,
    refetchOnWindowFocus: false,
    ...options,
  });
};
