import { EditIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { Icon, Show, Stack } from '@chakra-ui/react';
import {
  useCancelOrder,
  useGetMerchantAccount,
  useGetOrder,
} from '@tradeaze/frontend/hooks';
import {
  CancelConfirmation,
  DuplicateOrder,
  Layout,
  Map,
  MobileMapWithToggle,
  OrderPageButton,
  OrderSummary,
  PageError,
  PageLoading,
  ReturnOrder,
  TwoColumnLayout,
  useOrderPageMarkers,
} from '@tradeaze/frontend/ui-web';
import { shouldChargeCancellationFee } from '@tradeaze/shared/utils';
import { PROOF_OF_DELIVERY_BUCKET } from '@tradeaze/frontend/utils';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../components/auth/AuthProvider';

const OrderPage = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();

  const { cognitoUserId } = useAuthContext();

  const merchantAccountQuery = useGetMerchantAccount(cognitoUserId);

  const {
    data: order,
    error,
    isLoading,
  } = useGetOrder(orderId, {
    includeProofMedia: true,
  });

  const cancelMutation = useCancelOrder();

  const showCancel = React.useMemo(() => {
    if (!order?.deliveryStops) {
      return false;
    }

    return order.deliveryStops.every(
      (stop) => !stop.completedAt && stop.delivery?.status !== 'DELIVERED',
    );
  }, [order]);

  const mapMarkers = useOrderPageMarkers({ order, isAdmin: false });

  const shouldShowEditOrderButton = React.useMemo(() => {
    if (!order) {
      return false;
    }
    return order.orderStatus === 'PENDING' || order.orderStatus === 'CONFIRMED';
  }, [order]);

  if (!orderId) {
    return <PageError text="No order ID provided" />;
  }

  if (error) {
    return <PageError />;
  }

  if (isLoading) {
    return <PageLoading />;
  }

  if (!order) {
    return <PageError text="Couldn't find order" />;
  }

  const MainComponent = (
    <OrderSummary
      order={order}
      proofOfDeliveryBucketname={PROOF_OF_DELIVERY_BUCKET}
      isTravisPerkins={merchantAccountQuery.data?.featureFlags?.travisPerkins}
      showBackButton
    />
  );

  return (
    <>
      <Show above="md">
        <TwoColumnLayout
          left={MainComponent}
          right={<Map markers={mapMarkers} />}
        />
      </Show>
      <Show below="md">
        <Layout>
          {MainComponent}
          <MobileMapWithToggle markers={mapMarkers} />
        </Layout>
      </Show>
      <Stack
        direction={['column', 'column', 'row']}
        position={'fixed'}
        right={0}
        paddingTop={[0, 0, 4]}
        paddingX={3}
        bottom={['80px', '80px', 'auto']} // Adjust '60px' to the height of the intercom icon
        overflowX={'auto'}
      >
        {shouldShowEditOrderButton && (
          <OrderPageButton
            data-cy="edit-order-button"
            icon={<EditIcon fontSize={'22'} />}
            label="Edit"
            onClick={() => navigate(`/edit-order/${order.orderId}`)}
          />
        )}
        <DuplicateOrder order={order} />
        <ReturnOrder order={order} />
        {showCancel ? (
          <CancelConfirmation
            cancelType="order"
            shouldChargeFee={shouldChargeCancellationFee(
              new Date(order.createdAt),
              new Date(order.deliveryWindowEnd),
            )}
            isLoading={cancelMutation.isLoading}
            onConfirm={(cancellationReason) =>
              cancelMutation.mutate({
                orderId: order.orderId,
                cancellationReason,
              })
            }
            renderButton={({ onClick, isLoading }) => (
              <OrderPageButton
                data-cy="cancel-order-button"
                onClick={onClick}
                icon={<Icon as={SmallCloseIcon} fontSize={'30'} />}
                label={'Cancel'}
                colorScheme="red"
                isLoading={isLoading}
              />
            )}
          />
        ) : null}
      </Stack>
    </>
  );
};

export default OrderPage;
