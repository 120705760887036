import { z } from "zod";

export const CheckRiderPriorityResponseSchema = z.object({
  hasActivePriorities: z.boolean(),
  /**
   * The ids of the gig jobs that are linked to the rider's priority deliveries (i.e. ones from the same multi-drop order)
   * When we can have multiple deliveries inside of a single gig job, this won't be necessary.
   */
  linkedGigJobIds: z.array(z.string()),
});

export type CheckRiderPriorityResponse = z.infer<
  typeof CheckRiderPriorityResponseSchema
>;
