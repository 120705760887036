import { useRef, useEffect, useState } from 'react';

export const useCardHeight = () => {
  const frontCardRef = useRef<HTMLDivElement>(null);
  const [frontCardHeight, setFrontCardHeight] = useState(0);
  const [isHeightCalculated, setIsHeightCalculated] = useState(false);

  useEffect(() => {
    const updateHeight = () => {
      if (frontCardRef.current) {
        setFrontCardHeight(frontCardRef.current.offsetHeight);
        setIsHeightCalculated(true);
      }
    };

    updateHeight();

    const resizeObserver = new ResizeObserver(updateHeight);
    
    if (frontCardRef.current) {
      resizeObserver.observe(frontCardRef.current);
    }

    return () => resizeObserver.disconnect();
  }, []);

  return {
    frontCardRef,
    frontCardHeight,
    isHeightCalculated
  };
}; 