import React from 'react';
import { Box, Text, Heading } from '@chakra-ui/react';

type OrderTypeCardProps = {
  icon: React.ReactNode;
  title: string;
  description: string;
  onClick: () => void;
  isBeta?: boolean;
};

export const OrderTypeCard = ({
  icon,
  title,
  description,
  onClick,
  isBeta,
}: OrderTypeCardProps) => {
  return (
    <Box
      bg="white"
      p={6}
      borderRadius="xl"
      borderWidth="2px"
      borderColor="gray.200"
      cursor="pointer"
      transition="all 0.2s cubic-bezier(0.4, 0, 0.2, 1)"
      position="relative"
      role="group"
      h="100%"
      _hover={{ 
        transform: 'translateY(-2px)',
        boxShadow: 'lg',
        borderColor: 'gray.300'
      }}
      onClick={onClick}
      data-cy={title.replace(/\s+/g, '-').toLowerCase()}
    >
      <Box mb={4}>
        {icon}
      </Box>
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <Heading size="sm" color="gray.800">
          {title}
        </Heading>
        {isBeta && (
          <Text
            as="span"
            fontSize="xs"
            bg="blue.100"
            color="blue.700"
            px={2}
            py={1}
            borderRadius="full"
            fontWeight="medium"
          >
            BETA
          </Text>
        )}
      </Box>
      <Text fontSize="sm" color="gray.600">
        {description}
      </Text>
    </Box>
  );
};
