import { format } from 'date-fns';
import { z } from 'zod';
import { validateUkPhoneNumber } from '../../../utils';

export const ManVanRequestBodySchema = z.object({
  vehicle: z.enum(['Van', 'Flatbed', 'Luton']),
  date: z
    .string()
    .min(1, 'Date is required')
    .refine(
      (val) => new Date(val) >= new Date(format(new Date(), 'yyyy-MM-dd')),
      'Date must be today or in the future',
    ),
  bookedBy: z
    .string()
    .min(1, 'Name is required')
    .max(50, 'Name must be less than 50 characters'),
  contactNumber: z
    .string()
    .min(1, 'Contact number is required')
    .refine(validateUkPhoneNumber, 'Please enter a valid UK phone number'),
  merchantName: z.string().min(1, 'Merchant name is required'),
});

export const ManVanRequestResponseSchema = ManVanRequestBodySchema;

export type ManVanRequestBody = z.infer<typeof ManVanRequestBodySchema>;
export type ManVanRequestResponse = z.infer<typeof ManVanRequestResponseSchema>;
