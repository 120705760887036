import { useCallback, useMemo, useState } from 'react';
import { useGetAddresses } from '@tradeaze/frontend/hooks';
import { formatAddressDisplayName } from '@tradeaze/shared/utils';
import {
  Address,
  CreateDeliveryStop,
  MerchantAccount,
} from '@tradeaze-packages/schemas';
import { AutocompleteAddress } from '@tradeaze/shared/services';
import { UseFormReturn } from 'react-hook-form';
import { resetAddressFields } from '../utils';

type UseDeliveryStop = {
  form: UseFormReturn<CreateDeliveryStop>;
  merchantAccount?: MerchantAccount;
  addressType: 'DROP_OFF' | 'PICK_UP';
  companyId?: string | null;
};

export const useDeliveryStop = ({
  form,
  merchantAccount,
  addressType,
  companyId,
}: UseDeliveryStop) => {
  const { setValue, trigger, watch } = form;

  const [showFullAddress, setShowFullAddress] = useState(false);
  const [isManualAddress, setIsManualAddress] = useState(false);
  const [instructionsVisibility, setInstructionsVisibility] = useState(false);

  const companyName = watch('companyName');

  const { data: paginatedSavedAddresses, isLoading: isLoadingSavedAddresses } =
    useGetAddresses({
      companyId: companyId ?? undefined,
      merchantId: merchantAccount?.merchantId,
      addressType: addressType,
    });

  const savedAddresses = useMemo(
    () =>
      paginatedSavedAddresses?.pages[0].addresses
        .map((address) => ({
          address,
          displayName: formatAddressDisplayName(address),
        }))
        .sort((a, b) => a.displayName.localeCompare(b.displayName)),
    [paginatedSavedAddresses],
  );

  const handleSelectExistingAddressCallback = useCallback(
    (address: Address) => {
      if (!address) {
        setValue('deliveryNotes', '');
        setValue('position', undefined);
        setValue('addressId', null);
        setValue('addressLine1', '');
        setValue('postCode', '');
      } else {
        const fields = {
          addressId: address.addressId,
          addressLine1: address.addressLine1,
          addressLine2: address.addressLine2,
          addressLine3: address.addressLine3,
          postCode: address.postCode,
          position: address.position,
        };

        Object.entries(fields).forEach(([key, value]) =>
          setValue(key as keyof CreateDeliveryStop, value),
        );

        trigger(['addressLine1', 'addressLine2', 'addressLine3', 'postCode']);

        setInstructionsVisibility(!!address.instructions);
      }
      setShowFullAddress(!!address);
      setIsManualAddress(false);
    },
    [setValue, trigger],
  );

  const handleSelectLocationCallback = useCallback(
    (addressResult: AutocompleteAddress) => {
      const position = {
        latitude: addressResult.latitude,
        longitude: addressResult.longitude,
      };

      const postcode = addressResult.postcode;
      const address = [addressResult.line_1, addressResult.line_2]
        .filter(Boolean)
        .join(', ');

      const fields = {
        position,
        addressId: null,
        postCode: postcode || '',
        addressLine1: address || '',
      };

      Object.entries(fields).forEach(([key, value]) =>
        setValue(key as keyof CreateDeliveryStop, value),
      );

      trigger(['addressLine1', 'postCode']);
      setIsManualAddress(false);
      setShowFullAddress(true);
    },
    [setValue, trigger],
  );

  const handleManualAddress = useCallback((value = true) => {
    setIsManualAddress(value);
    setShowFullAddress(value);
  }, []);

  const handleResetAddressFields = useCallback(() => {
    resetAddressFields(setValue);
  }, [setValue]);

  const savedAddressPlaceholder = useMemo(() => {
    if (isLoadingSavedAddresses) {
      return 'Loading addresses';
    }
    if (savedAddresses?.length) {
      if (companyName && addressType === 'DROP_OFF') {
        return `Select saved address for '${companyName}'`;
      } else {
        return 'Select saved address';
      }
    }
    if (companyName && addressType === 'DROP_OFF') {
      return `No saved addresses for '${companyName}'`;
    }
    return 'No saved addresses';
  }, [savedAddresses, isLoadingSavedAddresses, companyName, addressType]);

  return {
    showFullAddress,
    isManualAddress,
    instructionsVisibility,
    setInstructionsVisibility,
    isLoadingSavedAddresses,
    savedAddresses,
    savedAddressPlaceholder,
    handleSelectExistingAddress: handleSelectExistingAddressCallback,
    handleSelectLocation: handleSelectLocationCallback,
    handleManualAddress,
    handleResetAddressFields,
  };
};
