import { Box, Divider, Text, VStack, HStack, Fade } from '@chakra-ui/react';
import { useCardHeight } from './hooks';
import {
  CardDeliveryOption,
  CardDeliveryWindow,
  CardDriverInfo,
  CardItemsInfo,
  CardPostcodes,
  CardStatusCount,
  CardStopsTag,
  CardTimeRemaining,
  GridCardContainer,
  GridCardContent,
  GridCardOrderReference,
  GridCardTab,
} from '../delivery';
import { HydratedOrder } from '@tradeaze-packages/schemas';
import { useOrderCardData } from './hooks/useOrderCardData';

type GridOrderCardProps = {
  order: HydratedOrder;
  onHoverOrder?: (orderId: string) => void;
  onHoverLeaveOrder?: () => void;
  isAdmin: boolean;
  onSelectOrder?: (order?: HydratedOrder) => void;
};

export const GridOrderCard = ({
  order,
  onHoverOrder,
  onHoverLeaveOrder,
  isAdmin,
  onSelectOrder,
}: GridOrderCardProps) => {
  const { frontCardRef, frontCardHeight, isHeightCalculated } = useCardHeight();

  const {
    orderDetails,
    deliveryDetails,
    status,
    drivers,
    backgroundColor,
    handleCardClick,
    showControls,
  } = useOrderCardData(order, isAdmin, onSelectOrder);

  return (
    <Box
      cursor="pointer"
      onClick={handleCardClick}
      onMouseEnter={() => onHoverOrder?.(orderDetails.orderId)}
      onMouseLeave={() => onHoverLeaveOrder?.()}
      position="relative"
    >
      <Fade in={isHeightCalculated}>
        <Box
          position="absolute"
          top="0"
          right="10px"
          width="100%"
          height={`${frontCardHeight * 0.95}px`}
          borderColor={'border'}
          borderWidth={1}
          borderRadius={'14px'}
          overflow="hidden"
          opacity={isHeightCalculated ? 1 : 0}
          bg={backgroundColor}
        >
          <Box position={'relative'} height={'100%'} width={'100%'}>
            <Box
              position={'absolute'}
              bottom={0}
              left={0}
              width="100%"
              height="calc(100% - 30px)"
              bg={'white'}
              zIndex={1}
              borderTopRadius={'14px'}
            />
          </Box>
        </Box>
      </Fade>
      <Box ref={frontCardRef} position="relative" top="10px" zIndex={1}>
        <GridCardContainer>
          <GridCardTab
            createdAt={orderDetails.createdAt}
            deliveryStatus={orderDetails.orderStatus}
            vehicleId={deliveryDetails.deliveryVehicleId}
          />
          <GridCardContent>
            <CardDeliveryOption
              deliveryOptionId={deliveryDetails.deliveryOptionId}
            />
            <CardPostcodes
              collectionPostcodes={deliveryDetails.pickupPostCodes}
              dropOffPostcodes={deliveryDetails.dropOffPostCodes}
              orderType={orderDetails.type}
            />
            {deliveryDetails.companyName && !isAdmin ? (
              <Text fontSize={'0.9em'} color={'blackAlpha.700'}>
                For {deliveryDetails.companyName}
              </Text>
            ) : null}
            <Divider my={2} />
            {showControls.showItemSizing &&
            deliveryDetails.deliveryItems?.length ? (
              <>
                <CardItemsInfo
                  deliveryItems={deliveryDetails.deliveryItems}
                  gap={4}
                />
                <Divider my={2} />
              </>
            ) : null}
            <VStack spacing={2} alignItems={'start'}>
              <CardDeliveryWindow
                deliveryWindowStart={orderDetails.deliveryWindowStart}
                deliveryWindowEnd={orderDetails.deliveryWindowEnd}
              />
            </VStack>
            <HStack py={2}>
              <CardStopsTag
                status={orderDetails.orderStatus}
                deliveryCount={deliveryDetails.deliveryCount}
                orderType={orderDetails.type}
              />
              <CardTimeRemaining
                deliveryWindowEnd={orderDetails.deliveryWindowEnd}
                showLate={isAdmin}
                deliveryStatus={orderDetails.orderStatus}
                updatedAt={orderDetails.updatedAt}
                deliveredAt={orderDetails.deliveredAt}
                isToday={status.isToday}
                cancelledAt={orderDetails.cancelledAt}
              />
            </HStack>
            <CardStatusCount order={order} />
            <Divider my={3} />
            <HStack>
              <CardDriverInfo
                drivers={drivers}
                showVehicle={false}
                showWorkType={isAdmin}
                isGigJob={status.isGigJob}
                isGigJobBoard={status.isGigJobBoard}
                hasShiftRider={status.hasShiftRider}
                externalProviders={orderDetails.externalProviders}
                isAdmin={isAdmin}
                externalJobCancelledStatuses={orderDetails.externalJobCancelledStatuses}
              />
            </HStack>
            <Divider my={3} />
            <GridCardOrderReference
              orderReference={orderDetails.merchantOrderReference || ''}
              invoicingName={orderDetails.invoicingName || ''}
              merchantName={orderDetails.merchantName || ''}
              orderType={orderDetails.type}
              hasNotes={status.hasNotes}
              showMerchantName={showControls.showMerchantName}
              isHeavyside={status.isHeavyside}
              notesPreview={status.notesPreview}
              showNotesPreview={showControls.showNotesPreview}
            />
          </GridCardContent>
        </GridCardContainer>
      </Box>
    </Box>
  );
};
