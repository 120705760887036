import { useQuery } from '@tanstack/react-query';
import { getMerchantAccount } from '@tradeaze-packages/api-client';
import { useNavigate } from 'react-router-dom';

export const getMerchantQueryKey = (id?: string | null) => [
  'getMerchantAccount',
  { id },
];

export const useGetMerchantAccount = (cognitoUserId?: string | null) => {
  const navigate = useNavigate();

  return useQuery({
    queryKey: getMerchantQueryKey(cognitoUserId),
    queryFn: () => getMerchantAccount(cognitoUserId as string),
    enabled: !!cognitoUserId,
    retry: false,
    onError: (error: { status: number }) => {
      // Only redirect to create account if we get a 404
      if (error?.status === 404) {
        navigate('/create-account');
      }
    },
  });
};
