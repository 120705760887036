import {
  Box,
  Alert,
  AlertIcon,
  Heading,
  Text,
  VStack,
  Button,
  FormControl,
  FormLabel,
  Card,
  CardBody,
  Stack,
  Input,
  Grid,
  GridItem,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useGetMerchantAccount } from '@tradeaze/frontend/hooks';
import { FeatureCard, Layout } from '@tradeaze/frontend/ui-web';
import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useAuthContext } from '../components/auth/AuthProvider';
import toast from 'react-hot-toast';
import { ManVanRequestBodySchema } from '@tradeaze-packages/schemas';
import { useCreateManVanRequest } from '@tradeaze/frontend/hooks';
import { useState, useEffect } from 'react';

const vehicleOptions = [
  { type: 'Van' as const, price: 350, description: 'Standard van suitable for most deliveries' },
  { type: 'Flatbed' as const, price: 400, description: 'Open bed truck ideal for construction materials' },
  { type: 'Luton' as const, price: 400, description: 'Large box van perfect for furniture and bulky items' },
] as const;

type VehicleType = typeof vehicleOptions[number]['type'];

const ManVanFormSchema = ManVanRequestBodySchema.omit({ merchantName: true });
type ManVanFormData = z.infer<typeof ManVanFormSchema>;

export const ManVanHirePage = () => {
  const [selectedVehicle, setSelectedVehicle] = useState<VehicleType | null>(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<ManVanFormData>({
    resolver: zodResolver(ManVanFormSchema),
    defaultValues: {
      date: format(new Date(), 'yyyy-MM-dd'),
    },
    mode: 'onChange',
  });

  const auth = useAuthContext();
  const merchantQuery = useGetMerchantAccount(auth.cognitoUserId);

  useEffect(() => {
    if (merchantQuery.data?.contactNumber) {
      setValue('contactNumber', merchantQuery.data.contactNumber);
    }
  }, [merchantQuery.data?.contactNumber, setValue]);

  const createManVanMutation = useCreateManVanRequest();

  const onSubmit = async (data: ManVanFormData) => {
    try {
      await createManVanMutation.mutateAsync({
        ...data,
        merchantName: merchantQuery.data?.merchantName || '',
      });

      toast.success('ManVan Hire Enquiry Submitted!', { duration: 5000 });

      setSelectedVehicle(null);
      setValue('vehicle', null as any);
      setValue('date', format(new Date(), 'yyyy-MM-dd'));
      setValue('bookedBy', '');
      // Don't reset contact number as it's prefilled from merchant account
    } catch (error) {
      toast.error(
        'There was an error submitting your enquiry. Contact support if the issue persists.',
      );
    }
  };

  const handleVehicleSelect = (vehicleType: VehicleType) => {
    setSelectedVehicle(vehicleType);
    setValue('vehicle', vehicleType);
  };

  return (
    <Layout isAuthScreen={false}>
      <Box p={{ base: 4, md: 8 }}>
        <VStack spacing={8} align="stretch" maxW="800px" mx="auto">
          <Box mb={3}>
            <Heading size="lg" mb={4}>
              ManVan Hire Enquiry
            </Heading>
            <Text>Hire out a man and van on a day rate basis.</Text>
          </Box>

          <Alert
            status="info"
            variant="left-accent"
            borderRadius="lg"
            flexDirection="row"
            py={3}
            bg="blue.50"
            color="blue.600"
          >
            <AlertIcon color="blue.500" />
            <Text>
              This feature is in preview and you will be contacted outside of
              the app.
              <br />
              Please note that we cannot guarantee vehicle availability.
            </Text>
          </Alert>

          <Card variant="outline" borderColor={'gray.200'}>
            <CardBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={6}>
                  <Box>
                    <Heading size="md" mb={4}>
                      Select Vehicle Type
                    </Heading>
                    <FormControl isInvalid={!!errors.vehicle}>
                      <Grid 
                        templateColumns={{ 
                          base: '1fr', 
                          sm: 'repeat(2, 1fr)', 
                          md: 'repeat(3, 1fr)' 
                        }} 
                        gap={{ base: 4, md: 6 }}
                      >
                        {vehicleOptions.map((vehicle) => (
                          <GridItem key={vehicle.type}>
                            <FeatureCard
                              title={`${vehicle.type} - £${vehicle.price}/day`}
                              description={vehicle.description}
                              icon={null}
                              onClick={() => handleVehicleSelect(vehicle.type)}
                              selected={selectedVehicle === vehicle.type}
                            />
                          </GridItem>
                        ))}
                      </Grid>
                      <FormErrorMessage>
                        {errors.vehicle?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>

                  <FormControl isRequired isInvalid={!!errors.date}>
                    <FormLabel>Hire Date</FormLabel>
                    <Input
                      type="date"
                      {...register('date')}
                      min={format(new Date(), 'yyyy-MM-dd')}
                    />
                    <FormErrorMessage>{errors.date?.message}</FormErrorMessage>
                  </FormControl>

                  <Stack 
                    direction={{ base: 'column', md: 'row' }} 
                    spacing={{ base: 4, md: 6 }}
                  >
                    <FormControl isRequired isInvalid={!!errors.bookedBy}>
                      <FormLabel>Booked By</FormLabel>
                      <Input
                        {...register('bookedBy')}
                        placeholder="Enter your name"
                      />
                      <FormErrorMessage>
                        {errors.bookedBy?.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={!!errors.contactNumber}>
                      <FormLabel>Contact Number</FormLabel>
                      <Input
                        {...register('contactNumber')}
                        type="tel"
                        placeholder="Enter your phone number"
                      />
                      <FormErrorMessage>
                        {errors.contactNumber?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>

                  <Button
                    type="submit"
                    size="lg"
                    isLoading={isSubmitting}
                    loadingText="Submitting"
                  >
                    Request Booking
                  </Button>

                  {createManVanMutation.isSuccess && (
                    <Alert 
                      status="success" 
                      variant="subtle" 
                      borderRadius="md"
                      animation="none"
                    >
                      ManVan Hire Enquiry Submitted! We will be in touch shortly.
                    </Alert>
                  )}
                </Stack>
              </form>
            </CardBody>
          </Card>
        </VStack>
      </Box>
    </Layout>
  );
};
