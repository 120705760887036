import { HStack, SimpleGrid } from '@chakra-ui/react';
import {
  DeliveryOptionId,
  DeliveryVehicleId,
} from '@tradeaze-packages/schemas';
import {
  formatMetresToKm,
  formatTimeRange,
  secondsToDurationText,
} from '@tradeaze/shared/utils';
import React from 'react';
import { GrLocation } from 'react-icons/gr';
import { DeliveryVehicleIcon } from '../../delivery';
import { Attribute, DeliveryOptionValue, TextValue } from '../../shared';
import { OrderSection } from '../order-section';
import { sectionColumns } from './common';

export const RouteSummary: React.FunctionComponent<{
  deliveryWindowStart?: string;
  deliveryWindowEnd?: string;
  deliveryLength: number;
  deliveryDuration?: number | null;
  deliveryDistance?: number | null;
  deliveryVehicle?: DeliveryVehicleId;
  deliveryOption?: DeliveryOptionId;
  stopName: 'Pickups' | 'Drops';
}> = ({
  deliveryWindowStart,
  deliveryWindowEnd,
  deliveryLength,
  deliveryDuration,
  deliveryDistance,
  deliveryVehicle,
  deliveryOption,
  stopName,
}) => {
  const deliveryWindow =
    deliveryWindowStart && deliveryWindowEnd
      ? formatTimeRange({
          from: deliveryWindowStart,
          to: deliveryWindowEnd,
        })
      : null;
  return (
    <OrderSection name="Route Details" icon={<GrLocation />}>
      <SimpleGrid columns={sectionColumns} spacing={4}>
        {deliveryVehicle ? (
          <Attribute name="Vehicle Type">
            <HStack>
              <DeliveryVehicleIcon
                fontSize={18}
                deliveryVehicle={deliveryVehicle}
              />
              <DeliveryOptionValue deliveryOption={deliveryOption} />
            </HStack>
          </Attribute>
        ) : null}
        {deliveryLength > 1 ? (
          <Attribute name={`Total ${stopName}`}>{deliveryLength}</Attribute>
        ) : null}
        {deliveryWindow ? (
          <Attribute name="Delivery Window">
            <TextValue text={deliveryWindow} />
          </Attribute>
        ) : null}
        <Attribute name="Travel Duration">
          <TextValue text={`${secondsToDurationText(deliveryDuration || 0)}`} />
        </Attribute>
        <Attribute name="Travel Distance">
          <TextValue text={`${formatMetresToKm(deliveryDistance || 0)}km`} />
        </Attribute>
      </SimpleGrid>
    </OrderSection>
  );
};
