import { z } from 'zod';
import { GetGigJobPaymentSummaryQuerySchema, GetGigJobPaymentSummaryResponseSchema } from './get-gig-job-payment-summary-schema';

export const DownloadGigJobPaymentSummaryQuerySchema = GetGigJobPaymentSummaryQuerySchema

export const DownloadGigJobPaymentSummaryResponseSchema = z.object({
  url: z.string(),
  data: GetGigJobPaymentSummaryResponseSchema,
});

export type DownloadGigJobPaymentSummaryQuery = z.infer<
  typeof DownloadGigJobPaymentSummaryQuerySchema
>;
export type DownloadGigJobPaymentSummaryResponse = z.infer<
  typeof DownloadGigJobPaymentSummaryResponseSchema
>;
