import { Box, HStack, Icon, Tag, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { DeliveryVehicleId } from '@tradeaze-packages/schemas';
import { FaUserCircle } from 'react-icons/fa';
import { RiderAvatar } from '../../../../rider';
import { DotIcon } from '../../../../shared';
import { DeliveryVehicleIcon } from '../../../delivery-vehicle';
import { CheckCircleIcon, SmallCloseIcon } from '@chakra-ui/icons';

export const CardDriverInfo: React.FC<{
  drivers:
    | {
        firstName: string;
        lastName?: string | null;
        vehicleId?: DeliveryVehicleId | null;
      }[]
    | null;
  showVehicle?: boolean;
  isGigJob?: boolean;
  showWorkType: boolean;
  isGigJobBoard: boolean;
  isListView?: boolean;
  hasShiftRider?: boolean;
  externalProviders?: string[];
  isAdmin?: boolean;
  externalJobCancelledStatuses: boolean[];
}> = ({
  drivers,
  showVehicle,
  isGigJob,
  showWorkType,
  isGigJobBoard,
  isListView,
  externalProviders,
  hasShiftRider,
  isAdmin,
  externalJobCancelledStatuses,
}) => {
  const direction = isListView ? 'column' : 'row';
  const align = isListView ? 'flex-start' : 'center';

  const workTypeTag = showWorkType ? (
    <HStack alignItems={align} flexDirection={direction}>
      {hasShiftRider && (
        <Tag
          colorScheme="yellow"
          size="sm"
          fontWeight={'bold'}
          mx={1}
          border="1px solid #FBE7AA"
        >
          SHIFT
        </Tag>
      )}
      {isGigJob && (
        <Tag
          colorScheme="yellow"
          size="sm"
          fontWeight={'bold'}
          mx={1}
          border="1px solid #FBE7AA"
        >
          GIG
        </Tag>
      )}
      {isGigJobBoard && (
        <Tag
          colorScheme="gray"
          size="md"
          fontWeight={'bold'}
          mx={1}
          border="1px solid #D5D5D5"
          data-cy="job-board-tag"
        >
          <CheckCircleIcon w={3} h={3} color="#579F6E" mr={1} />
          Job Board
        </Tag>
      )}
    </HStack>
  ) : null;
  const externalJobTag = isAdmin && (externalProviders || [])?.length > 0 ? (
    <HStack alignItems={align} flexDirection={direction}>
        {
          externalProviders?.length === 1 ? 
            (<Tag
                colorScheme={externalJobCancelledStatuses[0] ? "red" : 'gray'}
                size="sm"
                fontWeight={'bold'}
                mx={1}
                border="1px solid #D5D5D5"
              >
                {externalJobCancelledStatuses[0] && <SmallCloseIcon w={3} h={3} color="currentColor" mr={1} />}
                <Text data-cy={'external-provider-name'}>{externalProviders[0]}</Text>
            </Tag>)
          :
            <Wrap spacing={2}>
              <Box
                display={'flex'}
                flexDirection={'row-reverse'}
                position="relative"
              > 
                {
                  [...new Set([...externalProviders || []])]?.map((provider, index) => (
                    <WrapItem
                        key={index}
                        position="relative"
                        marginRight={index !== 0 ? '-6px' : '0'}
                      >
                        <RiderAvatar
                          size={'xs'}
                          firstName={provider[0]}
                          lastName={provider[1]}
                        />
                      </WrapItem>
                  ))}
              </Box>
              <WrapItem>
                <Text data-cy={'card-providers-assigned'}>{externalProviders?.length} providers assigned</Text>
              </WrapItem>
            </Wrap>
        }
    </HStack>
  ) : null;

  if (!drivers || !drivers.length) {
    return (
      <HStack flexDirection={direction} alignItems={align} flexWrap={'wrap'}>
        <HStack>
          <Icon as={FaUserCircle} fontSize={24} color={'blackAlpha.500'}></Icon>
          <Text data-cy={'card-unassigned'}>Unassigned</Text>
        </HStack>
        {externalJobTag}
        {workTypeTag}
      </HStack>
    );
  }

  return (
    <Wrap spacing={2} flexDirection={direction} color={'blackAlpha.800'}>
      <WrapItem>
        <Wrap spacing={2}>
          <Box
            display={'flex'}
            flexDirection={'row-reverse'}
            position="relative"
          >
            {drivers.map((driver, index) => (
              <WrapItem
                key={index}
                position="relative"
                marginRight={index !== 0 ? '-6px' : '0'} // Adjust this value as needed
                // zIndex={drivers.length - index} // Ensures rightmost avatar is on top
              >
                <RiderAvatar
                  size={'xs'}
                  firstName={driver?.firstName}
                  lastName={driver?.lastName}
                />
              </WrapItem>
            ))}
          </Box>
          {drivers.length > 1 ? (
            <WrapItem>
              <Text data-cy={'card-drivers-assigned'}>{drivers.length} drivers assigned</Text>
            </WrapItem>
          ) : null}
          {drivers.length === 1 ? (
            <>
              <WrapItem>
                <Text data-cy={'card-driver-name'}>{drivers[0].firstName}</Text>
              </WrapItem>
              {showVehicle && drivers[0].vehicleId ? (
                <>
                  <WrapItem display="flex" alignItems="center">
                    <DotIcon />
                  </WrapItem>
                  <WrapItem>
                    <DeliveryVehicleIcon
                      fontSize={'1em'}
                      deliveryVehicle={drivers[0].vehicleId}
                    />
                  </WrapItem>
                </>
              ) : null}
            </>
          ) : null}
        </Wrap>
        {externalJobTag}
      </WrapItem>
      <WrapItem>{workTypeTag}</WrapItem>
    </Wrap>
  );
};
