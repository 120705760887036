import { Box, Button, Center, Icon, Text } from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';
import { TradeazeLogoNoText } from '../brand/TradeazeLogoNoText';

export const PageErrorWithRetry: React.FunctionComponent<{ 
  text?: string;
  onRetry: () => void;
  isLoading?: boolean;
}> = ({
  text = 'Something went wrong',
  onRetry,
  isLoading = false,
}) => {
  return (
    <Center h="70vh" flexDirection={'column'}>
      <Box>
        <Icon
          as={TradeazeLogoNoText}
          fontSize={200}
          h="120"
        />
      </Box>
      <Box textAlign="center">
        <Text mb={4}>{text}</Text>
        <Button 
          leftIcon={<RepeatIcon />} 
          onClick={onRetry}
          isLoading={isLoading}
          variant="outline"
        >
          Retry
        </Button>
      </Box>
    </Center>
  );
}; 