import { Box, Button, Center, Show, VStack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Logo from '../assets/Logo';
import { HiChevronDoubleRight } from 'react-icons/hi2';
import { HalfLogo } from '../assets';
import { brandPrimaryColor, brandSecondaryColor } from '../theme/colors';

export const NAVBAR_WIDTH = 52;
export const NAVBAR_WIDTH_PX = `${NAVBAR_WIDTH}px`;
export const NAVBAR_HEIGHT = 52;
export const NAVBAR_HEIGHT_PX = `${NAVBAR_HEIGHT}px`;
export const NAVBAR_BACKGROUND_COLOR = brandSecondaryColor;

const MotionBox = motion(Box);

const ExpandButton: React.FunctionComponent<{
  isExpanded: boolean;
  handleToggleExpanded: () => void;
}> = ({ isExpanded, handleToggleExpanded }) => {
  return (
    <Button
      onClick={handleToggleExpanded}
      variant="unstyled"
      transform={isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'}
      color={brandPrimaryColor}
      width={12}
      data-cy="navbar-expand-button"
    >
      <Center>
        <HiChevronDoubleRight size={18} />
      </Center>
    </Button>
  );
};

const SideNavbar: React.FunctionComponent<{
  children: React.ReactNode;
  hideFullNavbar: boolean;
  onHomeClick?: () => void;
  handleToggleExpanded: () => void;
  isExpanded: boolean;
}> = ({
  children,
  hideFullNavbar,
  onHomeClick,
  handleToggleExpanded,
  isExpanded,
}) => {
  return (
    <MotionBox
      zIndex={1000}
      backgroundColor={brandSecondaryColor}
      position="fixed"
      w={NAVBAR_WIDTH_PX}
      h="100vh"
      overflow={'visible'}
      justifyContent="space-between"
      pt={2}
      initial={{ width: NAVBAR_WIDTH_PX }}
      animate={{ width: isExpanded ? '200px' : NAVBAR_WIDTH_PX }}
      transition={{ bounce: 0 }}
    >
      <ExpandButton
        isExpanded={isExpanded}
        handleToggleExpanded={handleToggleExpanded}
      />
      <VStack spacing={14} height={'100%'}>
        {!hideFullNavbar && children}
      </VStack>
      <Box
        position="absolute"
        bottom={1}
        left={1.5}
        w={`calc(${NAVBAR_WIDTH_PX} - 12px)`}
        mx="auto"
        data-cy="navbar-logo"
      >
        <NavLink to="/" onClick={onHomeClick}>
          <Logo color={brandPrimaryColor} />
        </NavLink>
      </Box>
    </MotionBox>
  );
};

const HorizontalNavbar: React.FunctionComponent<{
  children: React.ReactNode;
  onHomeClick?: () => void;
}> = ({ children, onHomeClick }) => {
  return (
    <Box
      zIndex={1000}
      backgroundColor={NAVBAR_BACKGROUND_COLOR}
      position="fixed"
      w="100%"
      h={NAVBAR_HEIGHT_PX}
      overflow={'visible'}
      display="flex"
      alignItems="center"
      justifyContent={children ? 'space-between' : 'center'}
      px={4}
    >
      <Box width={28}>
        <NavLink to="/" onClick={onHomeClick}>
          <HalfLogo
            textColor={brandPrimaryColor}
            containerColor={NAVBAR_BACKGROUND_COLOR}
          />
        </NavLink>
      </Box>
      {children}
    </Box>
  );
};

export const NavbarCommon: React.FunctionComponent<{
  hideFullNavbar: boolean;
  children: React.ReactNode;
  handleToggleExpanded: () => void;
  isExpanded: boolean;
  onHomeClick?: () => void;
}> = ({
  hideFullNavbar,
  children,
  onHomeClick,
  handleToggleExpanded,
  isExpanded,
}) => {
  const location = useLocation();

  const authPaths = [
    '/signin',
    '/signup',
    '/confirm-signup',
    '/create-account',
  ];

  const isAuthPage = authPaths.some((path) =>
    location.pathname.startsWith(path),
  );

  if (isAuthPage) {
    return (
      <HorizontalNavbar onHomeClick={onHomeClick}>
        {!hideFullNavbar && children}
      </HorizontalNavbar>
    );
  }

  return (
    <>
      <Show above="md">
        <SideNavbar
          hideFullNavbar={hideFullNavbar}
          onHomeClick={onHomeClick}
          handleToggleExpanded={handleToggleExpanded}
          isExpanded={isExpanded}
        >
          {!hideFullNavbar && children}
        </SideNavbar>
      </Show>
      <Show below="md">
        <HorizontalNavbar onHomeClick={onHomeClick}>
          {!hideFullNavbar && children}
        </HorizontalNavbar>
      </Show>
    </>
  );
};
