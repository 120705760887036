import { Box, Heading, Text } from '@chakra-ui/react';
import { HydratedDelivery } from '@tradeaze-packages/schemas';
import React from 'react';

interface ExternalJobDeliveryLabelProps {
  delivery: Pick<HydratedDelivery, 'pickup' | 'dropOff'>;
  index: number;
}

export const ExternalJobDeliveryLabel: React.FunctionComponent<
  ExternalJobDeliveryLabelProps
> = ({ delivery, index }) => {
  const pickupPostcode = delivery.pickup?.postCode;
  const dropoffPostcode = delivery.dropOff?.postCode;
  const deliveryLabel =
    pickupPostcode && dropoffPostcode
      ? `${pickupPostcode} → ${dropoffPostcode}`
      : `Delivery ${index + 1}`;

  return (
    <Box mb={6}>
      <Heading size="sm">
        Delivery {index + 1}:{' '}
        <Text as="span" fontWeight="normal">
          {deliveryLabel}
        </Text>
      </Heading>
    </Box>
  );
};
