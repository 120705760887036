import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import debounce from 'lodash.debounce';
import React from 'react';

type CompleteOrderButtonProps = {
  isLoading: boolean;
  handleSubmit: () => Promise<void>;
  isUpdatingOrder?: boolean;
  label: string;
};

export const CompleteOrderButton = ({
  isLoading,
  handleSubmit,
  isUpdatingOrder = false,
  label,
}: CompleteOrderButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const onConfirm = () => {
    onClose();
    // prevent double submission
    debounce(() => {
      handleSubmit();
    }, 200)();
  };

  return (
    <>
      <Button
        fontSize={20}
        borderRadius={16}
        w="100%"
        h={16}
        isLoading={isLoading}
        onClick={() => onOpen()}
        data-cy={
          isUpdatingOrder ? 'update-order-button' : 'create-order-button'
        }
      >
        {label}
      </Button>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        autoFocus={false}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>{label}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to {isUpdatingOrder ? 'update' : 'create'}{' '}
            this order?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} data-cy="no-create-order">
              No
            </Button>
            <Button
              onClick={onConfirm}
              isLoading={isLoading}
              isDisabled={isLoading}
              ml={3}
              data-cy="yes-create-order"
            >
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
