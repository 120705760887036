import { GrLocation } from 'react-icons/gr';
import React from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react';
import {
  CreateDeliveryStop,
  MerchantAccount,
} from '@tradeaze-packages/schemas';
import { SiteContacts } from '../site-contacts';
import {
  DriverInstructionSection,
  ManualAddressSection,
  PodSection,
  PostCodeSection,
  SaveAddressButton,
  SavedAddressSection,
} from '../common-sections';
import { OrderSection } from '../../order-section';
import {
  Attribute,
  OrDivider,
  SelectDropdown,
  TextValue,
} from '../../../shared';
import { useDropOffSection } from '../../hooks';
import { AddMarkerFn, RemoveMarkerFn } from '../../../map';
import { FormInputNote } from '../../../form';

type DropOffSectionProps = {
  sectionName: string;
  merchantAccount?: MerchantAccount;
  stopSequence?: number;
  form: UseFormReturn<CreateDeliveryStop>;
  isSectionComplete: boolean;
  addMarker: AddMarkerFn;
  removeMarker: RemoveMarkerFn;
  showExpanded: boolean;
  restrictedEditing?: boolean;
  cypressTestId: string;
  stopId: number;
};

export const DropOffSection = ({
  sectionName,
  merchantAccount,
  stopSequence,
  form,
  isSectionComplete,
  addMarker,
  removeMarker,
  showExpanded,
  restrictedEditing,
  cypressTestId,
  stopId,
}: DropOffSectionProps) => {
  const {
    showFullAddress,
    isManualAddress,
    instructionsVisibility,
    setInstructionsVisibility,
    savedAddresses,
    savedAddressPlaceholder,
    isLoadingSavedAddresses,
    handleSelectExistingAddress,
    handleSelectLocation,
    handleManualAddress,
    merchantCustomers,
    canSaveMerchantCustomer,
    handleSaveMerchantCustomer,
    hasSelectedMerchantCustomer,
    handleSelectMerchantCustomer,
    isLoadingCreateMerchantCompany,
    isLoadingMerchantCustomers,
    companyId,
    canSaveAddress,
    handleSaveAddress,
    isLoadingCreateAddress,
    canUpdateAddress,
    handleUpdateAddress,
    isLoadingUpdateAddress,
    geocodeData,
    geocodeError,
  } = useDropOffSection({
    merchantAccount,
    stopSequence,
    form,
    addMarker,
    removeMarker,
    stopId,
  });

  const customerPlaceholder = (() => {
    if (merchantCustomers?.length) return 'Select existing customer';
    if (isLoadingMerchantCustomers) return 'Loading saved customers';
    return 'No saved customers';
  })();

  return (
    <FormProvider {...form}>
      <OrderSection
        name={sectionName}
        icon={<GrLocation />}
        isComplete={isSectionComplete}
      >
        {restrictedEditing ? (
          <Box>
            {form.getValues('companyName') ? (
              <Attribute name={'Company Name'}>
                <TextValue text={form.getValues('companyName')} />
              </Attribute>
            ) : null}
            <Attribute name={'Address'}>
              <TextValue text={form.getValues('addressLine1')} />
            </Attribute>
            <Attribute name={'Postcode'}>
              <TextValue text={form.getValues('postCode')} />
            </Attribute>
          </Box>
        ) : null}

        {!restrictedEditing && merchantAccount ? (
          <>
            <SelectDropdown
              isLoading={isLoadingMerchantCustomers}
              onSelect={handleSelectMerchantCustomer}
              showClear={hasSelectedMerchantCustomer}
              results={merchantCustomers?.map((customer) => ({
                label: customer.companyName,
                value: customer,
              }))}
              placeholder={customerPlaceholder}
              isMultiSelect={false}
              label={'Existing customer'}
            />
            <Divider my={6} opacity={0.3} />
          </>
        ) : null}
        {!restrictedEditing && (
          <FormControl
            isRequired
            borderRadius={10}
            mb={4}
            isInvalid={Boolean(form.formState.errors.companyName)}
            isDisabled={hasSelectedMerchantCustomer}
          >
            <FormLabel htmlFor="contactName">Company Name</FormLabel>
            <Input
              id="companyName"
              placeholder="Company Name"
              data-cy={`${cypressTestId}-companyName-input`}
              {...form.register('companyName', {
                required: 'This is required',
              })}
            />
            <FormInputNote>
              The name of the company that will be receiving the order
            </FormInputNote>
            <FormErrorMessage>
              <Text data-cy={`${cypressTestId}-company-name-error`}>
                {form.formState.errors.companyName?.message}
              </Text>
            </FormErrorMessage>
          </FormControl>
        )}
        {!restrictedEditing && canSaveMerchantCustomer && (
          <Flex justifyContent={'end'}>
            <Button
              size={'sm'}
              alignSelf="end"
              onClick={handleSaveMerchantCustomer}
              isLoading={isLoadingCreateMerchantCompany}
            >
              Save Customer
            </Button>
          </Flex>
        )}
        {!restrictedEditing ? (
          <>
            {merchantAccount ? (
              <>
                <Divider my={8} />
                <Box my={6}>
                  <SavedAddressSection
                    savedAddressPlaceholder={savedAddressPlaceholder}
                    savedAddresses={savedAddresses}
                    handleSelectExistingAddress={handleSelectExistingAddress}
                    isLoadingSavedAddresses={isLoadingSavedAddresses}
                    disabled={!hasSelectedMerchantCustomer}
                  />
                </Box>
                <OrDivider my={6} />
              </>
            ) : null}
            <Box my={6}>
              <PostCodeSection
                handleSelectLocation={handleSelectLocation}
                form={form}
                cypressTestId={cypressTestId}
                manualAddressFieldsVisible={showFullAddress || showExpanded}
              />
              <ManualAddressSection
                handleManualAddress={handleManualAddress}
                showFullAddress={showFullAddress || showExpanded}
                isManualAddress={isManualAddress}
                form={form}
                isDropOff={true}
                cypressTestId={cypressTestId}
                geocodeData={geocodeData ?? null}
                geocodeError={geocodeError ?? null}
              />
            </Box>
            <SaveAddressButton
              canSaveAddress={canSaveAddress}
              isLoadingCreateAddress={isLoadingCreateAddress}
              handleSaveAddress={handleSaveAddress}
              canUpdateAddress={canUpdateAddress}
              handleUpdateAddress={handleUpdateAddress}
              isLoadingUpdateAddress={isLoadingUpdateAddress}
            />
          </>
        ) : null}
        <Box my={6}>
          <DriverInstructionSection
            instructionsVisibility={instructionsVisibility}
            setInstructionsVisibility={setInstructionsVisibility}
            form={form}
            cypressTestId={cypressTestId}
          />
        </Box>
        <Divider my={8} />
        <SiteContacts companyId={companyId} />
        <PodSection form={form} disabled />
      </OrderSection>
    </FormProvider>
  );
};
