import { Box, HStack, Show } from '@chakra-ui/react';
import { Layout, TwoColumnLayout } from '../layout';
import {
  Map,
  MapPointSelectMenu,
  MobileMapToggleButton,
  useDashboardMapMarkers,
  useMarkerHover,
} from '../map';
import { useState } from 'react';
import { OrderQueryPage } from '../order';
import {
  HydratedDelivery,
  HydratedOrder,
  MerchantAccount,
  RiderAccount,
  RiderIdentity,
  RiderLocationMap,
} from '@tradeaze-packages/schemas';
import { useGetOrder } from '@tradeaze/frontend/hooks';
import { OrderDeliveriesHeading } from './OrderDeliveriesHeading';

export const OrderDeliveriesContainer = ({
  selectedOrder: initialSelectedOrder,
  merchants,
  clockedInTodayRiders,
  riderLocations,
  isAdmin,
  onPressGoTo,
  onSelectDelivery,
  selectedDelivery,
  onSelectOrder,
  onClickDelivery,
}: {
  initialDate: Date;
  selectedOrder: HydratedOrder;
  merchants?: MerchantAccount[];
  clockedInTodayRiders?: (RiderIdentity &
    Pick<RiderAccount, 'lastClockedInAt' | 'onDuty'>)[];
  riderLocations?: RiderLocationMap;
  isAdmin: boolean;
  onPressGoTo: (orderId: string) => void;
  onSelectDelivery?: (delivery?: HydratedDelivery) => void;
  onSelectOrder?: () => void;
  selectedDelivery?: HydratedDelivery;
  onClickDelivery: (order: HydratedOrder) => void;
}) => {
  const showSelectPoints = isAdmin;

  const [showMobileMap, setShowMobileMap] = useState(false);

  const markerHover = useMarkerHover();

  const orderQuery = useGetOrder(
    initialSelectedOrder.orderId,
    {
      includeProofMedia: false,
    },
    {
      initialData: initialSelectedOrder,
    },
  );

  const order = orderQuery.data ?? initialSelectedOrder;

  const deliveries = order.deliveries.map((delivery) => ({
    ...delivery,
    order,
  }));

  const pickups = order.deliveries.map((delivery) => delivery.pickup);

  const dropOffs = order.deliveries.map((delivery) => delivery.dropOff);

  const { mapMarkers, mapPoints } = useDashboardMapMarkers({
    deliveries,
    merchants,
    riders: clockedInTodayRiders,
    riderLocations,
    isAdmin,
    selectedDelivery,
    onClickDelivery: onClickDelivery,
    initialFilters: {
      MERCHANT: false,
      DROP_OFF: true,
      PICK_UP: true,
      RIDER: true,
      ADDRESS: true,
      DELIVERY: true,
    },
    ...markerHover,
  });

  const enableGridHover = !isAdmin;

  const DeliveryGrid = (
    <Box>
      <OrderDeliveriesHeading
        order={order}
        pickups={pickups}
        dropOffs={dropOffs}
        onSelectOrder={onSelectOrder}
        onPressGoTo={onPressGoTo}
      />
      <OrderQueryPage
        onPressGoTo={onPressGoTo}
        fetchNextPage={() => void 0}
        hasNextPage={false}
        isFiltered={false}
        isAdmin={isAdmin}
        isError={orderQuery.isError}
        isFetchingNextPage={false}
        isLoading={orderQuery.isLoading}
        orders={[order]}
        deliveries={deliveries}
        columns={[1, 1, 1, 1, 2]}
        selectedDelivery={selectedDelivery}
        onSelectDelivery={onSelectDelivery}
        groupByOrder={false}
        {...(enableGridHover ? markerHover : {})}
      />
    </Box>
  );

  return (
    <Box
      position={'fixed'}
      left={0}
      top={0}
      height={'100%'}
      width={'100%'}
      zIndex={2}
      overflowY={'auto'}
    >
      <Show above="md">
        <TwoColumnLayout
          left={DeliveryGrid}
          leftContainerProps={{ backgroundColor: 'white' }}
          right={
            <>
              <Map
                markers={mapMarkers}
                cursor={mapPoints.enabled ? 'crosshair' : 'grab'}
                onClick={mapPoints.handleClick}
              />
              {showSelectPoints ? (
                <HStack
                  position={'fixed'}
                  bottom={12}
                  right={3}
                  maxWidth="100vw"
                  flexWrap={'wrap'}
                  justifyContent="flex-end"
              >
                  <MapPointSelectMenu {...mapPoints} />
                </HStack>
              ) : null}
            </>
          }
        />
      </Show>
      <Show below="md">
        <Layout backgroundColor={'white'}>
          {DeliveryGrid}
          <MobileMapToggleButton
            showMobileMap={showMobileMap}
            setShowMobileMap={setShowMobileMap}
          />
          {showMobileMap && (
            <Box
              position="fixed"
              top={0}
              left={0}
              height={'100vh'}
              width={'100vw'}
            >
              <Map markers={mapMarkers} onClick={mapPoints.handleClick} />
            </Box>
          )}
        </Layout>
        <HStack position={'fixed'} bottom={4} right={3} zIndex={1}>
          {showMobileMap && showSelectPoints ? (
            <MapPointSelectMenu {...mapPoints} />
          ) : null}
        </HStack>
      </Show>
    </Box>
  );
};
