import { Flex, HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { formatDate } from '@tradeaze/shared/utils';
import { DeliveryVehicleId, DeliveryStatus } from '@tradeaze-packages/schemas';
import startCase from 'lodash.startcase';
import { DotIcon } from '../../../../shared';
import { DeliveryVehicleIcon } from '../../../delivery-vehicle';

export const GridCardTab: React.FC<{
  vehicleId: DeliveryVehicleId;
  deliveryStatus: DeliveryStatus;
  createdAt: string;
}> = ({ vehicleId, deliveryStatus, createdAt }) => {
  const statusText = startCase(deliveryStatus.toLocaleLowerCase());

  const backgroundColor = {
    PENDING: 'gray.300',
    CONFIRMED: 'yellow.300',
    DELIVERED: 'green.300',
    CANCELLED: 'red.300',
    REJECTED: 'red.300',
  }[deliveryStatus];

  return (
    <Flex
      height={'48px'}
      justifyContent={'space-between'}
      alignItems={'start'}
      paddingTop={1.5}
      paddingX={3}
      fontWeight={'medium'}
      fontSize={'0.9em'}
      backgroundColor={backgroundColor}
      color={'blackAlpha.800'}
      zIndex={-1}
    >
      <HStack gap={1}>
        <DeliveryVehicleIcon deliveryVehicle={vehicleId} />
        <DotIcon fontSize={8} color={'blackAlpha.500'} />
        <Text data-cy={'card-status'}>{statusText}</Text>
      </HStack>
      <HStack>
        <Text data-cy={'card-created-at'}>{formatDate(new Date(createdAt))}</Text>
      </HStack>
    </Flex>
  );
};
