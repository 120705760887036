import { CreateDeliveryStop } from '@tradeaze-packages/schemas';
import { UseFormReturn } from 'react-hook-form';
import { FormAttribute } from '../../../form';
import { OrderSection } from '../../order-section';
import { GrLocation } from 'react-icons/gr';
import { Attribute, TextValue } from '../../../shared';

interface MultiDropPickupReferenceProps {
  defaultPickupAddress: string;
  defaultPickupPostcode: string;
  isSectionComplete: boolean;
  sectionName: string;
  form: UseFormReturn<CreateDeliveryStop>;
  isDisableDropOffCollectionReference: boolean;
  pickupReference?: string | null;
  formattedPickupAddress: string;
}

export const MultiDropPickupReference = ({
  defaultPickupAddress,
  defaultPickupPostcode,
  isSectionComplete,
  sectionName,
  form,
  isDisableDropOffCollectionReference,
  pickupReference,
  formattedPickupAddress,
}: MultiDropPickupReferenceProps) => {
  return (
    <OrderSection
      name={sectionName}
      icon={<GrLocation />}
      isComplete={isSectionComplete}
    >
      {formattedPickupAddress}
      {isDisableDropOffCollectionReference && (
        <Attribute
          name={'Pickup Reference'}
          description={'(Set for all deliveries)'}
          mt={8}
          mb={0}
        >
          <TextValue text={pickupReference} />
        </Attribute>
      )}
      {!isDisableDropOffCollectionReference && (
        <FormAttribute
          id={'collectionReference'}
          label="Pickup Reference"
          isRequired
          isInvalid={Boolean(form.formState.errors.collectionReference)}
          placeholder={'e.g. John Smith / Ticket #123456'}
          mt={'6'}
          error={form.formState.errors.collectionReference}
          description={'Someone to find or a code to reference on arrival'}
          data-cy={'pickup-reference'}
          inputCypressId={'pickup-reference-input'}
          {...form.register('collectionReference')}
        />
      )}
    </OrderSection>
  );
};
