import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';
import { brandPrimaryColor } from '../theme';

interface FeatureCardProps {
  title: string;
  description: string;
  icon: ReactNode;
  selected?: boolean;
  onClick?: () => void;
}

const MotionBox = motion(Box);

export function FeatureCard({ title, description, icon, selected, onClick }: FeatureCardProps) {
  // Split title into name and price
  const [name, price] = title.split(' - ');
  
  return (
    <MotionBox
      role="button"
      position="relative"
      p={8}
      bg="white"
      borderRadius="xl"
      borderWidth="2px"
      borderColor={selected ? brandPrimaryColor : 'gray.200'}
      cursor="pointer"
      onClick={onClick}
      transition="all 0.2s cubic-bezier(0.4, 0, 0.2, 1)"
      _hover={{
        transform: 'translateY(-2px)',
        boxShadow: 'lg',
        borderColor: selected ? brandPrimaryColor : 'gray.300',
      }}
      _before={{
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        borderRadius: 'xl',
        background: selected 
          ? `linear-gradient(145deg, ${brandPrimaryColor}20 0%, ${brandPrimaryColor}05 100%)`
          : 'linear-gradient(145deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%)',
        opacity: selected ? 1 : 0,
        transition: 'opacity 0.2s ease-in-out',
      }}
      _groupHover={{
        _before: {
          opacity: 1,
        },
      }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ 
        opacity: 1, 
        y: 0,
        boxShadow: selected ? 'lg' : 'sm',
      }}
      exit={{ opacity: 0, y: 20 }}
      height="100%"
    >
      <Flex direction="column" gap={6}>
        {/* Icon */}
        <Flex 
          justify="center" 
          color={selected ? brandPrimaryColor : 'gray.500'}
          fontSize="2xl"
          transition="color 0.2s"
        >
          {icon}
        </Flex>

        {/* Content */}
        <Flex direction="column" align="center" gap={3}>
          <Heading
            size="lg"
            color="gray.800"
            textAlign="center"
            fontWeight="600"
          >
            {name}
          </Heading>
          <Text
            fontSize="2xl"
            fontWeight="600"
            color={selected ? brandPrimaryColor : 'gray.600'}
            transition="color 0.2s"
          >
            {price}
          </Text>
          <Text
            color="gray.500"
            fontSize="md"
            textAlign="center"
            maxW="90%"
          >
            {description}
          </Text>
        </Flex>
      </Flex>

      {/* Selection indicator */}
      <Box
        position="absolute"
        top={4}
        right={4}
        width="20px"
        height="20px"
        borderWidth="2px"
        borderColor={selected ? brandPrimaryColor : 'gray.200'}
        borderRadius="full"
        bg="white"
        transition="all 0.2s ease-in-out"
      >
        {selected && (
          <motion.div
            style={{
              position: 'absolute',
              top: '3px',
              left: '3px',
              width: '10px',
              height: '10px',
              borderRadius: '9999px',
              backgroundColor: brandPrimaryColor
            }}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            transition={{
              type: "spring",
              stiffness: 500,
              damping: 30
            }}
          />
        )}
      </Box>
    </MotionBox>
  );
} 