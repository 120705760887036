import { Center, Container } from '@chakra-ui/react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import SignInForm from '../components/auth/SignInForm';
import {
  createAuthErrToast,
  isUnconfirmedUserError,
  useAuthStore,
} from '@tradeaze/frontend/ui-web';
import { AuthPageAlert, RouterStateAlert } from './AuthPageAlert';
import { useLocalStorage } from 'react-use';

const SignInPage = () => {
  const navigate = useNavigate();

  const location: {
    state?: {
      from?: { pathname: string };
      alert?: RouterStateAlert;
    };
  } = useLocation();

  const { signIn, loading } = useAuthStore();

  const [savedUsername, setSavedUsername] = useLocalStorage('username', '');

  const from = location.state?.from?.pathname || '/';
  const alert = location.state?.alert;

  const handleSignIn = async ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => {
    if (!username || !password) {
      console.error('Missing username or password');
      toast.error('Missing username or password');
      return;
    }

    const onSuccess = () => {
      setSavedUsername(username);
      navigate(from, { replace: true });
    };

    const onError = (error: unknown) => {
      if (isUnconfirmedUserError(error)) {
        navigate('/confirm-signup', {
          state: {
            username,
            alert: {
              status: 'info',
              message:
                'This account still requires confirmation. Check your email for a confirmation code',
            },
          },
        });
      } else {
        createAuthErrToast(error);
      }
    };

    signIn(username, password, onSuccess, onError);
  };

  return (
    <Center h="80vh">
      <Container maxW="72">
        <SignInForm
          handleSignIn={handleSignIn}
          isLoading={loading}
          initialUsername={savedUsername}
        />
      </Container>
      {alert ? (
        <AuthPageAlert message={alert.message} status={alert.status} />
      ) : null}
    </Center>
  );
};

export default SignInPage;
