import { z } from 'zod';
import { RiderLocationMapSchema } from '../../../models';

export const GetExternalRiderLocationsBodySchema = z.object({
  riderIds: z.array(z.string()),
});

export const GetExternalRiderLocationsResponseSchema = RiderLocationMapSchema;

export type GetExternalRiderLocationsBody = z.infer<typeof GetExternalRiderLocationsBodySchema>;
export type GetExternalRiderLocationsResponse = z.infer<
  typeof GetExternalRiderLocationsResponseSchema
>;
