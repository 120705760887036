import { z } from 'zod';
import { PositionSchema } from './shared-schema';

export const ExternalCourierSchema = z.object({
  id: z.string(),
  externalJobId: z.string(),
  deliveryId: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  contactNumber: z.string(),
  position: z.nullable(PositionSchema),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export type ExternalCourier = z.infer<typeof ExternalCourierSchema>;
