import { z } from 'zod';
import {
  HydratedGigJobSchema,
  GigJobsQueryParamsSchema,
} from '../../../models';

export const JobBoardVehicleFilterSchema = z.enum(['LAST_USED', 'ALL']);

export const GetGigJobsForJobBoardQuerySchema = GigJobsQueryParamsSchema.pick({
  limit: true,
  startIndex: true,
  autoAccept: true,
  sortBy: true,
  fromDate: true,
  toDate: true,
}).extend({
  vehicleFilter: JobBoardVehicleFilterSchema.optional(),
});

export const GetGigJobsForJobBoardResponseSchema = z.object({
  gigJobs: z.array(HydratedGigJobSchema),
  nextPageIndex: z.number().nullable(),
});

export type JobBoardVehicleFilter = z.infer<typeof JobBoardVehicleFilterSchema>;

export type GetGigJobsForJobBoardQuery = z.infer<
  typeof GetGigJobsForJobBoardQuerySchema
>;

export type GetGigJobsForJobBoardResponse = z.infer<
  typeof GetGigJobsForJobBoardResponseSchema
>;
