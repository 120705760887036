import { z } from 'zod';

export const MarkGigJobsAsPaidBodySchema = z.object({
  gigJobIds: z.array(z.string()).min(1, 'At least one gig job ID is required'),
});

export const MarkGigJobsAsPaidResponseSchema = z.object({
  successfulUpdates: z.array(z.string()),
  failedUpdates: z.array(z.object({
    gigJobId: z.string(),
    reason: z.string()
  }))
});

export type MarkGigJobsAsPaidBody = z.infer<typeof MarkGigJobsAsPaidBodySchema>;
export type MarkGigJobsAsPaidResponse = z.infer<typeof MarkGigJobsAsPaidResponseSchema>;
