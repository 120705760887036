import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { OrderType } from '@tradeaze-packages/schemas';

export const CardPostcodes: React.FC<{
  collectionPostcodes: string[];
  dropOffPostcodes: string[];
  orderType: OrderType;
}> = ({ collectionPostcodes, dropOffPostcodes, orderType }) => {
  const isMultiDrop = orderType === 'MULTI_DROP';

  const collections = isMultiDrop
    ? collectionPostcodes[0]?.toLocaleUpperCase()
    : collectionPostcodes
        .slice(0, 2)
        .map((pc) => pc?.toLocaleUpperCase())
        .join(' · ');

  const dropoffs = isMultiDrop
    ? dropOffPostcodes
        .slice(0, 2)
        .map((pc) => pc?.toLocaleUpperCase())
        .join(' · ')
    : dropOffPostcodes[0]?.toLocaleUpperCase();

  const remaining = isMultiDrop
    ? dropOffPostcodes.length - 2
    : collectionPostcodes.length - 2;

  return (
    <Flex flexWrap={'wrap'} fontWeight={600}>
      <Text fontSize={'0.9em'} noOfLines={1}>
        {collections}
        {!isMultiDrop && remaining > 0 && ` · +${remaining}`}
        {' → '}
        {dropoffs}
        {isMultiDrop && remaining > 0 && ` · +${remaining}`}
      </Text>
    </Flex>
  );
};
