import { Alert, AlertIcon } from '@chakra-ui/react';

export const OptimisedDropOffsAlert: React.FC = () => (
  <Alert
    status="info"
    mb={4}
    borderRadius="md"
  >
    <AlertIcon />
    Price is based on the most efficient route. Enter drops in any order.
  </Alert>
);
