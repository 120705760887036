import { Box, IconButton } from '@chakra-ui/react';
import { GrMapLocation } from 'react-icons/gr';
import { IoClose } from 'react-icons/io5';
import { NAVBAR_WIDTH } from '../navbar';

export const MobileMapToggleButton: React.FC<{
  extraTopPadding?: number;
  showMobileMap: boolean;
  setShowMobileMap: (show: boolean) => void;
}> = ({
  showMobileMap: mobileShowMap,
  setShowMobileMap,
  extraTopPadding = 0,
}) => {
  return (
    <Box
      position={'fixed'}
      top={`${NAVBAR_WIDTH + extraTopPadding}px`}
      zIndex={1400}
      right={0}
      padding={3}
      paddingTop={4}
    >
      {mobileShowMap ? (
        <IconButton
          aria-label="close-map"
          colorScheme="red"
          icon={<IoClose fontSize={24} />}
          onClick={() => setShowMobileMap(false)}
        />
      ) : (
        <IconButton
          aria-label="show-map"
          icon={<GrMapLocation />}
          onClick={() => setShowMobileMap(true)}
        />
      )}
    </Box>
  );
};
