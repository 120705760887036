import { MerchantDefinedCompany } from '@tradeaze-packages/schemas';
import { client } from '.';
import { handleResponse } from './handle-response';

export const fetchMerchantAccounts = async () =>
  handleResponse(client.getAllMerchants());

export const getMerchantAccount = async (cognitoUserId: string) =>
  handleResponse(
    client.getMerchantById({
      path: {
        merchantId: cognitoUserId,
      },
    }),
  );

export const createMerchantCompany = async (
  merchantCompany: MerchantDefinedCompany,
) =>
  handleResponse(
    client.createMerchantCompany({
      body: merchantCompany,
    }),
  );
