import { UseFormReturn } from 'react-hook-form';
import { unitConverter } from '../unitConverter';
import { CreateOrder, DeliveryItem } from '@tradeaze-packages/schemas';

type RequiredProps = Pick<
  DeliveryItem,
  'length' | 'width' | 'height' | 'weight' | 'widthUnit'
>;

type OptionalProps = Partial<
  Omit<DeliveryItem, 'length' | 'width' | 'height' | 'weight' | 'widthUnit'>
>;

type CustomDeliveryItem = RequiredProps &
  OptionalProps & {
    totalVolume: number;
    totalWeight: number;
  };

export const createMaxDeliveryItem = (
  form: UseFormReturn<CreateOrder>,
  isMultiDrop: boolean,
): CustomDeliveryItem => {
  const stops = isMultiDrop ? form.watch('dropOffs') : form.watch('pickups');
  const formattedItems = stops.flatMap((stop) =>
    (
      stop?.deliveryItems?.map((item) => ({
        height: unitConverter(item.height, item.heightUnit, 'm'),
        width: unitConverter(item.width, item.widthUnit, 'm'),
        length: unitConverter(item.length, item.lengthUnit, 'm'),
        weight: item.weight,
        widthUnit: 'm',
      })) || []
    ).filter(Boolean),
  );

  const maxLength = Math.max(...formattedItems.map((item) => item.length ?? 0));
  const maxWidth = Math.max(...formattedItems.map((item) => item.width ?? 0));
  const maxHeight = Math.max(...formattedItems.map((item) => item.height ?? 0));
  const maxWeight = Math.max(...formattedItems.map((item) => item.weight ?? 0));

  let totalVolume = 0;
  let totalWeight = 0;

  formattedItems.forEach((item) => {
    if (item.height && item.width && item.length) {
      const volume = item.width * item.height * item.length;
      totalVolume += volume;
      totalWeight += item.weight ?? 0;
    }
  });

  totalVolume = Number(totalVolume.toFixed(4));

  return {
    length: maxLength,
    width: maxWidth,
    height: maxHeight,
    weight: maxWeight,
    widthUnit: 'm',
    totalVolume,
    totalWeight,
  };
};
