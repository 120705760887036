import { GigJobSchema, RiderAccountSchema, RiderBankDetailsSchema } from '../../../models';

import { z } from 'zod';

export const GetGigJobPaymentSummaryQuerySchema = z.object({
  fromDate: z.string().optional(),
  toDate: z.string().optional(),
});

export const GigJobPaymentSummarySchema = z.object({
  ...RiderAccountSchema.pick({
    riderId: true,
    firstName: true,
    lastName: true,
    contactEmail: true,
    contactNumber: true,
  }).shape,
  jobCount: z.number(),
  totalPaymentAmount: z.number(),
  paymentCurrency: z.string(),
  nameOnAccount: RiderBankDetailsSchema.shape.nameOnAccount.nullish(),
  accountNumber: RiderBankDetailsSchema.shape.accountNumber.nullish(),
  sortCode: RiderBankDetailsSchema.shape.sortCode.nullish(),
  vatNumber: RiderBankDetailsSchema.shape.vatNumber.nullish(),
  gigJobs: GigJobSchema.pick({
    gigJobId: true,
    paymentAmount: true,
    paymentCurrency: true,
    completedAt: true,
  }).array(),
});

export const GetGigJobPaymentSummaryResponseSchema = z.array(
  GigJobPaymentSummarySchema,
);

export type GigJobPaymentSummary = z.infer<
  typeof GigJobPaymentSummarySchema
>;

export type GetGigJobPaymentSummaryQuery = z.infer<
  typeof GetGigJobPaymentSummaryQuerySchema
>;
export type GetGigJobPaymentSummaryResponse = z.infer<
  typeof GetGigJobPaymentSummaryResponseSchema
>;
