import { format } from 'date-fns';
import toast from 'react-hot-toast';
import {
  Box,
  Alert,
  AlertIcon,
  Heading,
  Text,
  VStack,
  Button,
  FormControl,
  FormLabel,
  Input,
  Card,
  CardBody,
  Stack,
  FormErrorMessage,
} from '@chakra-ui/react';
import { Layout } from '@tradeaze/frontend/ui-web';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { HiabRequestBodySchema } from '@tradeaze-packages/schemas';
import {
  useCreateHiabRequest,
  useGetMerchantAccount,
} from '@tradeaze/frontend/hooks';
import { useAuthContext } from '../components/auth/AuthProvider';
import { useEffect } from 'react';

const HiabFormSchema = HiabRequestBodySchema.omit({ merchantName: true });

type HIABFormData = z.infer<typeof HiabFormSchema>;

export const HIABRequestPage = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<HIABFormData>({
    resolver: zodResolver(HiabFormSchema),
    defaultValues: {
      date: format(new Date(), 'yyyy-MM-dd'),
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const auth = useAuthContext();
  const merchantQuery = useGetMerchantAccount(auth.cognitoUserId);
  const createHiabMutation = useCreateHiabRequest();

  const showSuccessAlert = createHiabMutation.isSuccess;

  useEffect(() => {
    if (merchantQuery.data) {
      setValue('contactNumber', merchantQuery.data.contactNumber || '');
      setValue('pickupPostcode', merchantQuery.data.postCode || '');
    }
  }, [merchantQuery.data, setValue]);

  const onSubmit = async (data: HIABFormData) => {
    try {
      await createHiabMutation.mutateAsync({
        ...data,
        merchantName: merchantQuery.data?.merchantName || '',
      });

      // Show success toast with form data
      toast.success('HIAB Enquiry Submitted!', { duration: 5000 });

      /**
       * Casting to any to allow resetting the form
       * values away from their expected types.
       */
      reset({
        pickupPostcode: null as any,
        dropoffPostcode: null as any,
        weight: null as any,
        date: format(new Date(), 'yyyy-MM-dd'),
        bookedBy: null as any,
        contactNumber: null as any,
      });
    } catch (error) {
      toast.error(
        'There was an error submitting your enquiry. Contact support if the issue persists.',
      );
    }
  };

  return (
    <Layout isAuthScreen={false}>
      <Box p={{ base: 4, md: 8 }}>
        <VStack spacing={8} align="stretch" maxW="800px" mx="auto">
          <Box mb={3}>
            <Heading size="lg" mb={4}>
              HIAB Delivery Enquiry
            </Heading>
            <Text>
              A HIAB is a lorry-mounted crane, perfect for lifting and
              delivering heavy items. The crane can be used for loading,
              unloading, and precise positioning of your materials.
            </Text>
          </Box>

          <Alert
            status="info"
            variant="left-accent"
            borderRadius="lg"
            flexDirection="row"
            py={3}
            bg="blue.50"
            color="blue.600"
          >
            <AlertIcon color="blue.500" />
            <Text>
              This feature is in preview and you will be contacted outside of
              the app.
              <br />
              Please note that we cannot guarantee vehicle availability.
            </Text>
          </Alert>

          <Card variant="outline" borderColor={'gray.200'}>
            <CardBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={6}>
                  <Stack 
                    direction={{ base: 'column', md: 'row' }} 
                    spacing={4}
                  >
                    <FormControl isRequired isInvalid={!!errors.pickupPostcode}>
                      <FormLabel>Pickup Postcode</FormLabel>
                      <Input
                        {...register('pickupPostcode', {
                          onChange: (e) => {
                            e.target.value = e.target.value.toUpperCase();
                          }
                        })}
                        placeholder="e.g. SW1A 1AA"
                      />
                      <FormErrorMessage>
                        {errors.pickupPostcode?.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={!!errors.dropoffPostcode}>
                      <FormLabel>Delivery Postcode</FormLabel>
                      <Input
                        {...register('dropoffPostcode', {
                          onChange: (e) => {
                            e.target.value = e.target.value.toUpperCase();
                          }
                        })}
                        placeholder="e.g. EC1A 1BB"
                      />
                      <FormErrorMessage>
                        {errors.dropoffPostcode?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>

                  <Stack 
                    direction={{ base: 'column', md: 'row' }} 
                    spacing={4}
                  >
                    <FormControl isRequired isInvalid={!!errors.weight}>
                      <FormLabel>Approximate Weight (kg)</FormLabel>
                      <Input
                        {...register('weight')}
                        placeholder="e.g., 1000"
                        type="number"
                      />
                      <FormErrorMessage>
                        {errors.weight?.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={!!errors.date}>
                      <FormLabel>Delivery Date</FormLabel>
                      <Input
                        {...register('date')}
                        type="date"
                        min={format(new Date(), 'yyyy-MM-dd')}
                      />
                      <FormErrorMessage>{errors.date?.message}</FormErrorMessage>
                    </FormControl>
                  </Stack>

                  <Stack 
                    direction={{ base: 'column', md: 'row' }} 
                    spacing={4}
                  >
                    <FormControl isRequired isInvalid={!!errors.bookedBy}>
                      <FormLabel>Booked By</FormLabel>
                      <Input
                        {...register('bookedBy')}
                        placeholder="Enter your name"
                      />
                      <FormErrorMessage>
                        {errors.bookedBy?.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={!!errors.contactNumber}>
                      <FormLabel>Contact Number</FormLabel>
                      <Input
                        {...register('contactNumber')}
                        placeholder="Enter your contact number"
                        type="tel"
                      />
                      <FormErrorMessage>
                        {errors.contactNumber?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>

                  <Button
                    type="submit"
                    size="lg"
                    isLoading={isSubmitting}
                    loadingText="Submitting"
                  >
                    Submit Enquiry
                  </Button>
                  {showSuccessAlert && (
                    <Alert
                      status="success"
                      variant="subtle"
                      borderRadius="md"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                    >
                      HIAB Enquiry Submitted! We will be in touch shortly.
                    </Alert>
                  )}
                </Stack>
              </form>
            </CardBody>
          </Card>
        </VStack>
      </Box>
    </Layout>
  );
};
