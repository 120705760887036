import { useEffect, useMemo, useState } from 'react';
import { ExternalCourier } from '@tradeaze-packages/schemas';
import { SupabaseClient } from '@supabase/supabase-js';
import { useGetExternalRiderLocations } from './useGetExternalRiderLocation';

const supabase = new SupabaseClient(
  import.meta.env['VITE_SUPABASE_URL'],
  import.meta.env['VITE_SUPABASE_KEY']
);

export const useSubscribeToExternalRiderLocations = (
  riderIds: string[],
  options: {
    enabled: boolean;
  } = { enabled: true }
) => {
  const riderLocationsQuery = useGetExternalRiderLocations(riderIds, {
    enabled: riderIds.length > 0 && options.enabled,
  });

  const [riderLocationMap, setRiderLocationMap] = useState(
    riderLocationsQuery.data
  );

  useEffect(() => {
    setRiderLocationMap(riderLocationsQuery.data);
  }, [riderLocationsQuery.data]);

  useEffect(() => {
    if (!options.enabled) return;
    const channel = supabase
      .channel('external-rider-location-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'ExternalCourier',
        },
        (payload) => {
          const updatedLocation = payload.new as ExternalCourier & {
            riderId: string;
          };
          const position = updatedLocation.position;
          if (!position) return;
          const locationObj = {
            latitude: position.latitude,
            longitude: position.longitude,
            updatedAt: updatedLocation.updatedAt,
          }
          setRiderLocationMap((prev) => ({
            ...prev,
            [updatedLocation.id]: locationObj,
          }));
        }
      )
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  }, [options.enabled]);

  return useMemo(() => riderLocationMap, [riderLocationMap]);
};

export const useSubscribeToExternalRiderLocation = (
  riderId: string,
  options: {
    enabled: boolean;
  } = { enabled: true }
) => {
  return useSubscribeToExternalRiderLocations([riderId], options)?.[riderId];
};
