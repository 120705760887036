import { Box, Flex, Text, VStack, Stack } from '@chakra-ui/react';
import { Address } from '@tradeaze-packages/schemas';
import { brandSecondaryColor } from '../../theme';
import { ButtonWithConfirmation } from '../../shared';

export const AddressCard = ({
  address,
  onClick,
  onDelete,
  isLoadingDelete,
  onHover,
}: {
  address: Address;
  onClick?: (address: Address) => void;
  onDelete?: (addressId: string) => void;
  isLoadingDelete?: boolean;
  onHover?: (isHovering: boolean) => void;
}) => {
  const handleCardClick = () => {
    onClick && onClick(address);
  };

  return (
    <Box
      w="100%"
      h="100%"
      p={2}
      borderWidth="1px"
      borderRadius="lg"
      borderColor={brandSecondaryColor}
      onClick={handleCardClick}
      marginBottom={2}
      onMouseEnter={(event) => {
        onHover && onHover(true);
      }}
      onMouseLeave={(event) => {
        onHover && onHover(false);
      }}
    >
      <Flex direction="column" h="100%" padding={2}>
        <Stack direction={['column', 'row']} justifyContent="space-between">
          <VStack spacing={1} align="flex-start">
            <Text fontSize="m" color="black.500" data-cy="address-line-1">
              {address.addressLine1}
            </Text>
            <Text fontSize="m" color="black.500" data-cy="address-city">
              {address.city}
            </Text>
            <Text fontSize="m" color="black.500" data-cy="address-postcode">
              {address.postCode}
            </Text>
          </VStack>
          {onDelete && (
            <ButtonWithConfirmation
              alertTitle="Delete Address"
              alertDescription="Are you sure you want to delete this address?"
              onConfirm={() => onDelete(address.addressId)}
              colorScheme="red"
              size="xs"
              variant="outline"
              isLoading={isLoadingDelete}
              data-cy="delete-address"
            >
              Delete
            </ButtonWithConfirmation>
          )}
        </Stack>
      </Flex>
    </Box>
  );
};
