import { z } from 'zod';
import {
  DeliveryStopContactSchema,
  DeliveryStopTypeSchema,
} from './delivery-stop-schema';
import { PositionSchema } from './shared-schema';
import { UpdateDeliveryItemSchema } from './delivery-item-schema';
import { OrderStatusSchema, OrderTypeSchema } from './order-schema';
import { DeliveryVehicleIdSchema } from './delivery-vehicle-schema';
import { DeliveryOptionIdSchema } from './delivery-option-schema';
import { PartialRecord } from '../utils/PartialRecord';

const ExistingOrderCommonStopSchema = z.object({
  contacts: z.array(DeliveryStopContactSchema),
  companyName: z.string().nullish(),
  addressLine1: z.string().nullish(),
  postCode: z.string().nullish(),
  position: PositionSchema.nullish(),
  windowStart: z.string().nullish(),
  windowEnd: z.string().nullish(),
  deliveryStopId: z.string(),
  addressId: z.string().nullish(),
  stopSequence: z.number(),
  requiresImage: z.boolean().nullish(),
  requiresSignature: z.boolean().nullish(),
  collectionReference: z.string().nullable(),
  type: DeliveryStopTypeSchema,
});

export const ExistingOrderPickupStopSchema =
  ExistingOrderCommonStopSchema.extend({
    type: z.literal('PICK_UP'),
    deliveryItems: z.array(UpdateDeliveryItemSchema),
    status: OrderStatusSchema.nullish(),
  });

export const ExistingOrderDropoffStopSchema = ExistingOrderCommonStopSchema.extend({
  type: z.literal('DROP_OFF'),
  companyId: z.string().nullish(),
  status: OrderStatusSchema.nullish(),
});

export const ExistingOrderSchema = z.object({
  merchantId: z.string().nullish(),
  invoicingName: z.string().nullish(),
  pickupDetails: z.array(ExistingOrderPickupStopSchema).nullish(),
  dropoffDetails: z.array(ExistingOrderDropoffStopSchema).nullish(),
  type: OrderTypeSchema,
  deliveryVehicle: DeliveryVehicleIdSchema,
  deliveryOption: DeliveryOptionIdSchema,
  bookedBy: z.string().nullish(),
  orderReference: z.string().nullish(),
  orderDate: z.date(),
  orderStatus: OrderStatusSchema,
  deliveryWindowStart: z.string().nullish(),
  isExternalJobAssigned: z.boolean().nullish(),
});

const DuplicateContactsSchema = z.array(DeliveryStopContactSchema.partial());

export const DuplicatePickupStopSchema = ExistingOrderPickupStopSchema.omit({
  deliveryStopId: true,
  status: true,
}).extend({
  contacts: DuplicateContactsSchema.nullish(),
});

export const DuplicateDropoffStopSchema = ExistingOrderDropoffStopSchema.omit({
  deliveryStopId: true,
}).extend({
  contacts: DuplicateContactsSchema,
});

export const DuplicateDeliveryStopSchema = z.discriminatedUnion('type', [
  DuplicatePickupStopSchema,
  DuplicateDropoffStopSchema,
]);

export const DuplicateOrderSchema = z.object({
  merchantId: z.string().nullish(),
  invoicingName: z.string().nullish(),
  type: OrderTypeSchema,
  deliveryVehicle: DeliveryVehicleIdSchema,
  deliveryOption: DeliveryOptionIdSchema,
  pickupDetails: z.array(DuplicatePickupStopSchema),
  dropoffDetails: z.array(DuplicateDropoffStopSchema),
});

export const buildDuplicateDeliveryStopSchema = (
  additionalOmits: PartialRecord<
    keyof Omit<DuplicateDeliveryStop, 'type'>,
    true
  >,
) =>
  z.discriminatedUnion('type', [
    DuplicatePickupStopSchema.omit({
      ...additionalOmits,
    }),
    DuplicateDropoffStopSchema.omit({
      ...additionalOmits,
    }),
  ]);

export type DuplicatePickupStop = z.infer<typeof DuplicatePickupStopSchema>;

export type DuplicateDropoffStop = z.infer<typeof DuplicateDropoffStopSchema>;

export type DuplicateDeliveryStop = z.infer<typeof DuplicateDeliveryStopSchema>;

export type DuplicateOrder = z.infer<typeof DuplicateOrderSchema>;

export type ExistingOrder = z.infer<typeof ExistingOrderSchema>;

export type ExistingOrderPickupStop = z.infer<
  typeof ExistingOrderPickupStopSchema
>;
export type ExistingOrderDropoffStop = z.infer<
  typeof ExistingOrderDropoffStopSchema
>;
